/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocation, faLocationDot, faPhone, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { fetchData, toasterrormsg, toastsuccessmsg, } from '../../../../reusable'
import 'react-multi-carousel/lib/styles.css'
import '../../../../_metronic/assets/style/style.css'
import  './contact.css'
import { LayOut } from '../Home'
import { ToastContainer, toast } from 'react-toastify'

function ContactUsContent() {

    const [fullName, setfullName] = useState<string | undefined>()
    const [email, setemail] = useState<string | undefined>()
    const [message, setmessage] = useState<string | undefined>()
    
    
  const [error, setError] = useState([
    {
        fullName: '',
        email: '',
        message: '',

    },
  ])

  const addvalidate = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i;
    const nameRegex = /^[A-Za-z\s]+$/;
    var isValid = true
    var errorTmp = [...error]

    if (fullName == '' || fullName == null || fullName == undefined || fullName.trim() == '') {
      errorTmp[0].fullName = 'Full Name is required'
      isValid = false
    }else if (!nameRegex.test(fullName.trim())) {
        errorTmp[0].fullName = 'Please Enter a Valid Full Name';
        isValid = false;
    }

    if (email == "" || email == null || email == undefined || email.trim() == '') {
      errorTmp[0].email = 'Email is required';
      isValid = false;
    }else if (!emailRegex.test(email.trim())) {
        errorTmp[0].email = 'Please Enter a Valid Email Address';
        isValid = false;
    }

    if (message == "" || message == null || message == undefined || message.trim() == '') {
        errorTmp[0].message = 'Message is required';
        isValid = false;
      }

    setError(errorTmp)
    return isValid
  }

  const removeError = (err: any) => {
    var tmp = [...error] as any
    tmp[0][err] = ''
    setError(tmp)
  }

  const enquirySentOld = async (id: any = '') => {

    if (addvalidate()) {

     let obj = {
        fullName:fullName,
        email:email,
        message:message,
     }

      var res = await fetchData("userEnquiryEmail",obj );
    
      if (res.status == '200') {
        toastsuccessmsg('')
        setTimeout(() => {
        //   navigate('../support/')
        }, 1500)
      } else {
        toasterrormsg(res.message)
        console.log(res)
      }
    }

  }

  const enquirySent = async (id: any = '') => {

    if (addvalidate()) {

      let obj = {
        name:fullName,
        email:email,
        message:message,
     }
     
     var res = await fetchData("addenquiry",obj );
     
   
      if (res.status == '200') {
        toastsuccessmsg(res.message)
        setTimeout(() => {
          setmessage('')
          setemail('')
          setfullName('')
        //   navigate('../support/')
        }, 1500)
      } else {
        toasterrormsg(res.message)
        
      }
    }

  }


    useEffect(() => {
        document.title = 'Inalts Investor';

    }, []);

    return (
        <>
        <ToastContainer />
            <div className='mt-5 bg-white pb-5'>
                <div className='mt-5 pt-5'>
                    <div className="contact containerContact">
                        
                            <div className="formContact">
                                <div className="formContact-txt">
                                    <h1>Contact Us</h1>
                                    <span>You may contact us using the information below </span>
                                    <h3 className="text-gray-900">INALTS FINSOLVE PRIVATE LIMITED</h3>


                                    <h3>Email</h3>
                                    <p><FontAwesomeIcon icon={faEnvelope} className='me-2' /> admin@inalts.com</p>

                                    <h3>Contact Number</h3>
                                    <p><FontAwesomeIcon icon={faPhone} className='me-2' /> 9822940633</p>

                                    <h3>Registered Address</h3>
                                    <p><FontAwesomeIcon icon={faLocationDot} className='me-2' /> 222, DMGB MARG, BAJAJ NAGAR, NAGPUR, Maharashtra, PIN: 440010
                                    </p>

                                    <h3>Operational Address</h3>
                                    <p><FontAwesomeIcon icon={faLocationDot} className='me-2' /> DMGB MARG, BAJAJ NAGAR, NAGPUR, Maharashtra, PIN: 440010</p>
                                </div>
                                <div className="formContact-details">
                                    <div>
                                        <input type="text" name="name" id="name" placeholder="Enter Your Full Name"  value={fullName} onChange={(e: any) => { setfullName(e.target.value)
                                              removeError('fullName')}}/>
                                              <div className='text-danger fw-bold' style={{marginLeft:"15px"}}>{error[0].fullName}</div>

                                    </div>
                                    <div>
                                        <input type="email" name="email" id="email" placeholder="Enter Email" value={email} onChange={(e: any) => { setemail(e.target.value)
                                              removeError('email')}} />
                                              <div className='text-danger fw-bold' style={{marginLeft:"15px"}}>{error[0].email}</div>
                                    </div>
                                    <textarea name="message" id="message" cols={52} rows={7} placeholder="Message" value={message} onChange={(e: any) => { setmessage(e.target.value)
                                              removeError('message')}}></textarea>
                                              <div className='text-danger fw-bold' style={{marginLeft:"15px"}}>{error[0].message}</div>
                                    <button style={{backgroundColor:"#181c32"}}  onClick={() => {enquirySent()}}>SEND MESSAGE</button>
                                </div>
                            </div>
                            <div>
                                <h3>Inalts Finsolve Private Limited registrations and licenses</h3>

                                <div className='mt-10'>
                                    <h4 className="text-gray-900">Corporate Identity Number of the company</h4>
                                    <p>U66190MH2024PTC420283(Issued by - GOVERNMENT OF INDIA MINISTRY OF CORPORATE AFFAIRS)</p>
                                </div>

                              
                                <div className='mt-7'>
                                    <h4>Goods and Services Tax Identification Number</h4>
                                    <p>27AAHCI5549L1Z0</p>
                                </div>

                                <div className='mt-7'>
                                    <h4>Startup India</h4>
                                    <p> DIPP174143(Issued by - Government of India, Ministry of Commerce & Industry, Department for Promotion of Industry and Internal Trade)</p>
                                </div>

                                <div className='mt-7'>
                                    <h4>UDYAM</h4>
                                    <p>UDYAM-MH-20-0186977(Issued by - Government of India, Ministry of Micro, Small and Medium Enterprises)</p>
                                </div>

                            </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default function ContactUs() {
    return <LayOut data={<ContactUsContent />} ></LayOut>;
}