/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import noUiSlider from 'nouislider'
import {useLayout} from '../../core'
import {KTIcon} from '../../../helpers'
import {DefaultTitle} from './page-title/DefaultTitle'
import {ThemeModeSwitcher} from '../../../partials'
import { Link } from 'react-router-dom'

const HeaderToolbar = () => {
  const {classes} = useLayout()
  const [status, setStatus] = useState<string>('1')
  var vendor: any = sessionStorage.getItem('vendor')
  vendor = JSON.parse(vendor) 
  // console.log(vendor);
  
  useEffect(() => {

    const rangeSlider = document.querySelector('#kt_toolbar_slider')
    const rangeSliderValueElement = document.querySelector('#kt_toolbar_slider_value')

    if (!rangeSlider || !rangeSliderValueElement) {
      return
    }

    // @ts-ignore
    noUiSlider.create(rangeSlider, {
      start: [5],
      connect: [true, false],
      step: 1,
      format: {
        to: function (value) {
          const val = +value
          return Math.round(val).toString()
        },
        from: function (value) {
          return value
        },
      },
      range: {
        min: [1],
        max: [10],
      },
    })

    // @ts-ignore
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      rangeSliderValueElement.innerHTML = values[handle]
    })

    const handle = rangeSlider.querySelector('.noUi-handle')
    if (handle) {
      handle.setAttribute('tabindex', '0')
    }

    // @ts-ignore
    handle.addEventListener('click', function () {
      // @ts-ignore
      this.focus()
    })

    // @ts-ignore
    handle.addEventListener('keydown', function (event) {
      // @ts-ignore
      const value = Number(rangeSlider.noUiSlider.get())
      // @ts-ignore
      switch (event.which) {
        case 37:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value - 1)
          break
        case 39:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value + 1)
          break
      }
    })
    return () => {
      // @ts-ignore
      rangeSlider.noUiSlider.destroy()
    }
  }, [])

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />

        {vendor ? (
          <div className='row  my-3'>
            <div className=' mt-5' style={{cursor: 'pointer'}}>
              {vendor.isVerified == '0' ? (
                <Link to={'/crafted/pages/profilemanagement/editprofile'}>
                  <span className=' fs-5 text-center badge badge-light-primary'>
                    KYC Document Status - Pending
                  </span>
                </Link>
              ) : vendor.isVerified == '1' ? (
                <Link to={'/crafted/pages/profilemanagement/editprofile'}>
                  <span className='text-center badge badge-light-success'>
                    KYC Document Status - Approved
                  </span>
                </Link>
              ) : (
                <Link to={'/crafted/pages/profilemanagement/editprofile'}>
                  <span className=' text-center badge badge-light-danger'>
                    KYC Document Status - Rejected
                  </span>
                </Link>
              )}
            </div>
            {/* <span className='col badge badge-light-success'>Approved</span> */}
          </div>
        ) : (
          <>
            <Link
              to={'/auth/login'}
              className='btn col-lg-1 col-sm rounded bg-warning text-center h-50 align-item-center mt-5 pb-lg-10'
              style={{color: 'rgb(5, 14, 36)'}}
            >
              Login
            </Link>
          </>
        )}
        {/* <div className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0'>
          <div className='d-flex align-items-center'>
            <div className='d-flex'>
              <div className='d-flex align-items-center'>
                <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export {HeaderToolbar}
