import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { ProjectList } from '../pages/projects/ProjectList'
import { ProjectDetails } from '../pages/projects/ProjectDetails'
import { InvestorList } from '../pages/projects/InvestorList'
import ProfilePageWrapper from '../pages/profile-management/ProfilePageWrapper'
import { AddProject } from '../pages/projects/AddProject'
import { LearnMore } from '../pages/projects/learnmore'
import { NewsFeedList } from '../pages/projects/newsfeed/newsfeedlist'
import { NewsFeedDetail } from '../pages/projects/newsfeed/newsfeeddeatil'
import { InvestmentList } from '../pages/projects/investment/investmentlist'
import { ShortlistProject } from '../pages/projects/shortlist/shortlistproject'
import { ReportProject } from '../pages/projects/report/report'
import { ViewSupport } from '../pages/projects/support/Support'
import { AddHelpAndSupport } from '../pages/projects/support/AddSupport'
import { InaltsBankDetail } from '../pages/projects/inaltsBankDetail'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='projectlist' element={<ProjectList />} />
        <Route path='projectdetails' element={<ProjectDetails />} />
        <Route path='addproject' element={<AddProject />} />
        <Route path='investorlist' element={<InvestorList />} />

        <Route path='learnmore' element={<LearnMore />} />
        
        <Route path='newsFeedList' element={<NewsFeedList />} />
        <Route path='newsFeedDetail' element={<NewsFeedDetail />} />

        <Route path='investmentlist' element={<InvestmentList />} />
        <Route path='shortlistproject' element={<ShortlistProject />} />
        <Route path='reports' element={<ReportProject />} />
        <Route path='support' element={<ViewSupport />} />
        <Route path='addsupport' element={<AddHelpAndSupport />} />
        <Route path='inaltsBankDetail' element={<InaltsBankDetail />} />
        <Route path='inaltsBankDetail' element={<InaltsBankDetail />} />
        <Route
          path='crafted/pages/profilemanagement/*'
          element={
            <SuspensedView>
              <ProfilePageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
