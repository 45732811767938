/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'
import {toAbsoluteUrl} from '../../../helpers'
import { Link } from 'react-router-dom'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  redirect: string
  status?: string
}

const StatisticsWidget7: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  redirect,
  status
}) => {
  return (
    <div
    className={`card bgi-no-repeat ${className} `}
    style={{
      backgroundPosition: 'right top',
      backgroundSize: '30% auto',
      backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-2.svg')})`,
    }}
  >

      <Link className='card-body text-center' to={redirect} state={{status:status}}>
        <Link className={`fw-semibold fs-3 text-${descriptionColor}`} to={redirect} state={{status:status}}>{description}</Link>
        <div className={`text-${titleColor} fw-bold fs-1 mb-2 mt-5`}>{title}</div>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-2x ms-n1`} />
        


      </Link>
  </div>
  )
}

export {StatisticsWidget7}
