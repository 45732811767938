/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {IconUserModel} from '../../../../app/modules/profile/ProfileModels'
import {UsersList} from '../../../../app/modules/profile/components/UsersList'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faFile, faKey, faPhone, faTrash, faUser} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import Swal from 'sweetalert2'
import {fetchData} from '../../../../reusable'
import { KTIcon } from '../../../../_metronic/helpers'

// type Props = {
//   data: any
// }

const ModuleReportTabel = (props:any) => {

  return (
    <>
      <div className='card p-6'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead className='text-start text-muted fw-bolder w-100 fs-7 text-uppercase gs-0'>
              <tr className='text-start text-muted fw-bolder'>
                <td className='col min-w-60px'>Sr no.</td>
                <td className='col min-w-125px'>Project Name</td>
                <td className='col min-w-125px'>Total Invest Amount</td>
                <td className='col min-w-125px'>Total Interest Amount</td>
                <td className='col min-w-125px'>Total Maturity Amount</td>
                {/* <td className='col min-w-125px'>Avarage Intereset Rate</td> */}
                
                {/* <td className='col min-w-125px'>Action</td> */}
              </tr>
            </thead>
            <tbody className='text fw-bold'>
              {props.data.length > 0 ? (
                props.data.map((row: any, i: any) => (
                  <tr key={i}>
                    <td role='cell' className='text-gray-600 mb-1'>
                      {row.srNo}
                    </td>
                    <td role='cell' className='text-gray-600 mb-1'>
                      {row['name']}
                    </td>
                    <td role='cell' className='text-gray-600 mb-1'>
                    ₹ {row.amount}
                    </td>
                    <td role='cell' className='text-gray-600 mb-1'>
                    ₹ {row.interestAmount.toFixed(2)}
                    </td>
                    <td role='cell' className='text-gray-600 mb-1'>
                    ₹ {row.totalMaturityAmount.toFixed(2)}
                    </td>
                    
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export {ModuleReportTabel}
