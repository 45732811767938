import { useEffect, useState } from "react"
import { useListView } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { fetchData, toasterrormsg } from "../../../../reusable"
import { useIntl } from "react-intl"
import { PageTitle } from "../../../../_metronic/layout/core"
import { KTIcon } from "../../../../_metronic/helpers"
import { ModuleReportTabel } from "./ModuleReportTabel"

export function ReportProject() {
    const {itemIdForUpdate} = useListView()
  
    useEffect(() => {
      document.title = 'Investor | Report'
    }, [])
    var vendorId = ''
    const vendorData = sessionStorage.getItem('vendor')
    if (vendorData) {
      var vendor = JSON.parse(vendorData)
      vendorId = vendor._id
    } else {
      console.log('No vendor data found')
    }
    // let reportProjectArr: {srNo:any, name: any; amount: any; interestAmount: any; totalMaturityAmount: any }[] = []
    const [offset, setoffset] = useState(0)
    const [reportProjectArr, setreportProjectArr] = useState([])
    const [limit, setlimit] = useState(10)
    const [page, setpage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [outdata, setoutdata] = useState(0)
    const [from, setfrom] = useState(1)
    const [to, setTo] = useState(1)
    const [totalData, setTotalData] = useState(0)
    const [pageNumbersList, setPageNumberList] = useState([1])
  
    const [projectDetails, setProjectDetails] = useState([])
    const [projectDetailsList, setProjectDetailsList] = useState([])
    const [projectList, setProjectList] = useState([])
    const [showPopup, setShowPopup] = useState(false)
  
    const [frontAadharCard, setfrontAadharCard] = useState('')
    const [backAadharCard, setbackAadharCard] = useState('')
    const [investorId, setinvestorId] = useState('')
    const [panCard, setpanCard] = useState('')
  
    const Suburl = 'https://inalts.com/api/inalts/image/company/'
    // page number generater
    function generatePageNumbers(currentPage: any, totalPages: any, maxPagesToShow: any) {
      const halfMaxPages = Math.floor(maxPagesToShow / 2)
      let startPage = Math.max(1, currentPage - halfMaxPages)
      let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1)
  
      // Adjust startPage and endPage if the total pages to show is less than maxPagesToShow
      if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1)
      }
  
      // Generate the list of page numbers
      const pageNumbers = []
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i)
      }
  
      return pageNumbers
    }
    // on page change function
    const onpagecahnge=(pageId:any,data:any=[],fromSearch:any=false,limit:any=10)=>{
      console.log("page no",pageId);
      
      
      var newdata:any=[]
      if(fromSearch==true)
        {
          newdata=[...data];
        }
        else
        {
          newdata=[...projectDetails]
          console.log("newdata.length",newdata.length);
        }
        console.log("data",data);
        
        setTotalData(newdata.length)
      if((pageId!=page && pageId!=0 && pageId<=totalPage) || data.length>0)
      {
        // console.log(pageId);
        var tmp=(pageId-1)*limit
        setpage(pageId)
        setoffset(tmp)
        setfrom(tmp+1)
        // setto
        var mod=newdata.length%limit
        var tolimi:any=limit
        if(mod>0)
        {
          if(mod<=limit)
          {
            tolimi=pageId*limit
            if(tolimi>newdata.length)
            {
              tolimi=newdata.length
            }
          }
          else
          {
            tolimi=pageId*limit+limit
            if(tolimi>newdata.length)
            {
              tolimi=newdata.length
            }
          }
        }
        else
        {
          tolimi=pageId*limit
        }
        setTo(tolimi)
        var tmpdata:any=newdata.slice(tmp,tolimi)
        setProjectDetailsList(tmpdata)
        // console.log("tmp offset",tmp);
      } 
      else if(data.length == 0){
        setTo(0)
        setfrom(0)
  
      }
      var totpag=(Math.ceil(newdata.length/limit))
          setTotalPage(totpag)
          // setProjectDetailsList(data)
          // onpagecahnge(1,data,true)
          setpage(pageId)
          var genpag=generatePageNumbers(1,totpag,6)
          setPageNumberList(genpag)
  
    }
    const projectsearch = async (text: any) => {
      
      if(text=="" || text==null || text==undefined)
        { 
         
          var tmp:any=reportProjectArr.slice(offset, offset+limit);
          
            setProjectDetailsList(tmp)
  
            var totpag=(Math.ceil(reportProjectArr.length/limit))
            setTotalPage(totpag)
            setProjectDetailsList(reportProjectArr)
            onpagecahnge(1,reportProjectArr,true)
            setpage(1)
            var genpag=generatePageNumbers(1,totpag,6)
            setPageNumberList(genpag)
          
        }
        else
        {
          var tmp: any = reportProjectArr.filter((item: any) => {
            console.log("=====",item.name);
            
            return (item['name'] != null && item['name'] != "" && (item['name']).toLowerCase().includes(text.toLowerCase()))
                   
            });
    
        
          // var tmp:any=projectDetails.filter((item:any)=>item.name!=null && item.name!="" && (item.name).toLowerCase() == text.toLowerCase())
          // var tmp:any=projectDetails.filter((item:any)=>item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase() == text.toLowerCase()) ||(item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase().includes(text.toLowerCase())) ||(item.email!=null && item.email!="" && (item.email).toLowerCase() == text.toLowerCase()) ||(item.email!=null && item.email!="" && (item.email).toLowerCase().includes(text.toLowerCase())) ||(item.location!=null && item.location!="" && (item.location).toLowerCase() == text.toLowerCase()) ||(item.location!=null && item.location!="" && (item.location).toLowerCase().includes(text.toLowerCase()))
          // var tmp:any=orguserList.filter((item:any)=>((item.fullName)).toLowerCase() == text.toLowerCase() 
          // || ((item.email)+' '+(item.email)).toLowerCase().includes(text.toLowerCase()))
          var totpag=(Math.ceil(tmp.length/limit))
          setTotalPage(totpag)
          setProjectDetailsList(tmp)
          onpagecahnge(1,tmp,true)
          setpage(1)
          var genpag=generatePageNumbers(1,totpag,6)
          setPageNumberList(genpag)
        }
    }
    
     const profileDetailsApiCall = async () => {
      var param: any = {
        investorId: vendorId
      }
      var response: any = await fetchData('investmentdetail', param)
  
      if (response.status == '200') {
      
        
        setProjectDetails(response.data)
       
        var res = response.data
        setTotalData(res.length)
        var tmp: any = res.slice(offset, offset + limit)
  
        setProjectDetailsList(tmp)
  
        var totpag = Math.ceil(res.length / limit)
        setTotalPage(totpag)
        if (totpag == 1) {
          var mod = res.length % limit
          if (mod > 0) {
            setTo(mod)
          } else {
            setTo(tmp.length)
          }
        } else {
          var mod = res.length % limit
  
          if (mod > 0) {
            if (mod <= limit) {
              setTo(page * limit)
            } else {
              setTo(page * limit + limit)
            }
          } else {
            setTo(page * limit)
          }
        }

        var genpag = generatePageNumbers(page, totpag, 6)
        setPageNumberList(genpag)

        // calculate For Reports
        var reportOfProject = response.data
        var reportProjectArrr = [] as any
        for (let i = 0; i < reportOfProject.length; i++) {
          
          var param: any = {
            projectId: reportOfProject[i]['projectData']['_id'],
            prinicipleAmount: `${reportOfProject[i]['amount']}`
          }
          var resPro: any = await fetchData('calculateReturns', param)
          

          if(resPro.status == "200"){
            
            reportProjectArrr.push({
              srNo:i+1,
              name:reportOfProject[i]['projectData']['name'],
              amount:reportOfProject[i]['amount'],
              interestAmount:resPro['data'][0]['interestAmount'],
              totalMaturityAmount:resPro['data'][0]['totalMaturityAmount'],
            })
            
          }
            
          
        }
        setreportProjectArr(reportProjectArrr)
        
        
        
      } else {
        toasterrormsg(response.message)
      }
    }
  
  
    const reportDownload = async () => {
      var param: any = {
        "type":"investorReport",
        "data":reportProjectArr
      }
       
      var response: any = await fetchData('investorProjectReport', param)
    
      if (response.status == '200') {
        const link = document.createElement('a');
        link.href =response.data[0]['path'] ; // Path to your image
        link.download = 'Investor Report'; // Filename for the downloaded image
        link.click();
      } else {
        toasterrormsg(response.message)
      }
    }

    
    const intl = useIntl()
    useEffect(() => {
      profileDetailsApiCall()
    }, [])
  
    return (
      <>
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Report'})}</PageTitle>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <h3 className='fw-bolder my-2'>
              
              {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span> */}
            </h3>
  
            <div className='d-flex flex-wrap my-2'>
              <div className='d-flex align-items-center position-relative me-4'>
                {/* <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' /> */}
                {/* <input
                  type='text'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm w-150px ps-9'
                  placeholder='Search'
                  onChange={(e) => {
                    projectsearch(e.target.value)
                  }}
                /> */}
              </div>
               
              <button  onClick={reportDownload} className='btn btn-primary btn-sm'>
              Download File
            </button > 
            </div>
            
          </div>
  
          <div className='row g-6 g-xl-9'>
            <div className=''>
              <ModuleReportTabel data={reportProjectArr}  />
            </div>
          </div>
  
          <div className='d-flex flex-stack flex-wrap pt-10'>
          <div className='row'>
          <select
                    className='form-select form-select-solid fw-bolder w-50'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => {
                      setlimit(parseInt(e.target.value));
                      onpagecahnge(page, projectDetailsList,false,parseInt(e.target.value));
                    }}
                    
                  >
                    <option value='10'>10</option>
                    <option value='30'>30</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                </select>
                <div className='fs-6 fw-bold text-gray-700'>
            Showing {from} to {to} of {totalData} entries
          </div>
              
          </div>
  
            <ul className='pagination'>
              <li className={`page-item previous ${page > 1 ? '' : 'disabled'}`}>
                <span
                  className={`page-link ${page > 1 ? 'cursor-pointer' : 'disabled'}`}
                  onClick={() => onpagecahnge(page - 1)}
                >
                  <i className='previous'></i>
                </span>
              </li>
              {pageNumbersList.map((item: any) => {
                return (
                  <li className={`page-item cursor-pointer ${page == item ? 'active' : ''}`}>
                    <span className='page-link' onClick={() => onpagecahnge(item)}>
                      {item}
                    </span>
                  </li>
                )
              })}
  
              <li className={`page-item next ${page + 1 <= totalPage ? '' : 'disabled'}`}>
                <span
                  className={`page-link ${page + 1 <= totalPage ? 'cursor-pointer' : 'disabled'}`}
                  onClick={() => (page + 1 <= totalPage ? onpagecahnge(page + 1) : '')}
                >
                  <i className='next'></i>
                </span>
              </li>
            </ul>
          </div>
        </>
      </>
    )
  }