/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {IconUserModel} from '../../../../app/modules/profile/ProfileModels'
import {UsersList} from '../../../../app/modules/profile/components/UsersList'
import {toAbsoluteUrl} from '../../../helpers'
 
type Props = {
  icon: string
  statusColor: string
  title: string
  businessCategory: string
  investorInterested: string
  averageAmount: string
  riskRating: string
  irr: string
  roi: string
  exitDuration: string
  exitPeriod: string
  investmentOppurtunitySize: string
  progress: number
  projectId: string
  projectCode: string
}

const Card6: FC<Props> = ({
  icon,
  statusColor,
  title,
  businessCategory,
  investorInterested,
  averageAmount,
  riskRating,
  irr,
  roi,
  exitDuration,
  exitPeriod,
  investmentOppurtunitySize,
  progress,
  projectId,
  projectCode
}) => {
  return (
    <>
      {/* <Link
        to='/crafted/pages/profile/overview'
        className='card border border-2 border-gray-300 border-hover'
      > */}
      <div className='card shadow-sm p-0 rounded '  style={{
        // backgroundPosition: 'center center',
        backgroundSize: '100% auto',
        backgroundRepeat:'no-repeat',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')})`,
      }}>
        <div className='p-5'>
          <div className='fs-3 fw-bolder text-dark'>{title}</div>
          <div className='fs-3 fw-bolder text-gray-400'>{projectCode }</div>
        </div>
        <div className='separator'></div>
        <div className='card-header border-0 pt-3'>
          <div className='card-title m-0'>
            <div className='bg-light'>
              <img
                src={icon}
                alt='Card6'
                className='p-2 img-fluid'
                style={{height: '150px', width: '150px'}}
              />
            </div>
          </div>
          <div>
            <div className='my-3 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'>Category: </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{marginLeft: '5px'}}>
                {businessCategory}
              </p>
            </div>
            {/* <div className='my-3 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'>
                Investor Interested:{' '}
              </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{marginLeft: '5px'}}>
                {' '}
                {investorInterested}
              </p>
            </div> */}
            <div className='my-3 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'>Minimum Investment: </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{marginLeft: '5px'}}>
                {' '}
                ₹{averageAmount}
              </p>
            </div>
          </div>
          {/* <div className='card-toolbar'>
              <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}>
                {status}
              </span>
            </div> */}
        </div>

        <div className='card-body p-9'>
          {/* <div className='fs-3 fw-bolder text-dark'>{title}</div> */}

          {/* <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{description}</p> */}

          <div className='d-flex flex-wrap mb-5'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='fw-bold text-gray-400'>Risk Rating</div>
              <div className='fs-6 text-gray-800 fw-bolder'>{riskRating}</div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='fw-bold text-gray-400'>IRR</div>
              <div className='fs-6 text-gray-800 fw-bolder'>{irr}</div>
            </div>
            
          </div>

          <div className='d-flex flex-wrap mb-5'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='fw-bold text-gray-400'>ROI</div>
                <div className='fs-6 text-gray-800 fw-bolder'>{roi}</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <div className='fw-bold text-gray-400'>Exit Duration</div>
                <div className='fs-6 text-gray-800 fw-bolder'>{exitPeriod} {exitDuration}</div>
              </div>
          </div>

          <div
            className='h-4px w-100 bg-light mb-10'
            data-bs-toggle='tooltip'
            // style={{position:"relative"}}
            title='This project completed'
          >
            <div
              className={`bg-${statusColor} rounded h-4px`}
              role='progressbar'
              style={{width: `${progress}%`}}
            ></div>
            <div className='mt-2 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'>
                ₹{parseInt(investmentOppurtunitySize).toLocaleString('en-IN')}{' '}
              </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{marginLeft: '5px'}}>
                {' '}
                (0 Pending)
              </p>
            </div>
          </div>
          <div className='pt-5 d-flex justify-content-center'>
            <Link
              to='../projectdetails/'
              state={{projectId: projectId}}
              className='fw-bold text-primary'
            >
              View Details
            </Link>
          </div>
          {/* <UsersList users={users} /> */}
        </div>
      </div>
      {/* </Link> */}
    </>
  )
}

export {Card6}
