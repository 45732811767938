/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../reusable'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Card7 } from '../../../_metronic/partials/content/cards/Card7'
import { FeedsWidget7 } from '../../../_metronic/partials/widgets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faFile } from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap'
import { log } from 'console'
import { ToastContainer } from 'react-toastify'
import '../../../_metronic/assets/style/style.css'
// import {load} from '@cashfreepayments/cashfree-js';

var { load } = require('@cashfreepayments/cashfree-js');

export function ProjectDetails() {

  useEffect(() => {
    document.title = 'Investor | Project Details';
  }, []);
  const location = useLocation();

  const isInvestMent = sessionStorage.getItem('investmentList')

  const projectId = (location.state as any)?.projectId || '';
  const isShort = (location.state as any)?.isShort || '';

  var vendorId = "";
  var vendorData: any = sessionStorage.getItem("vendor");
  vendorData = JSON.parse(vendorData);
  vendorId = vendorData?._id;

  const navigate = useNavigate()
  const [projectDetails, setProjectDetails] = useState([]);
  const [calculatevalue, setcalculatevalue] = useState(0);
  const [youtube, setyoutube] = useState([]);


  // profile Details api call
  const profileDetailsApiCall = async () => {
   
    var param: any = {
      projectId: projectId,
      investorId: vendorId,
    }

    var response: any = await fetchData('viewproject', param)


    if (response.status == "200") {
      var data = response.data;

      setProjectDetails(data)
      setyoutube(data[0]['videoLinks'] ? JSON.parse(data[0]['videoLinks']) : [])

    }
    else {
      toasterrormsg(response.message)
    }
  }

  const [show, setShow] = useState(false);
  const [paymentTypeModelShow, setpaymentTypeModelShow] = useState(false);
  const [recivible, setrecivible] = useState(0);
  const [totalMaturityAmount, settotalMaturityAmount] = useState(0);
  const [investDetail, setinvestDetail] = useState({});

  const [returnArr, setreturnArr] = useState({
    "grossYield": "0",
    "interestRate": 0,
    "tenure": 0,
    "tenureYears": 0,
    "tdsInterest": 0,
    "tenureType": "MONTH",
    "interestAmount": 0,
    "totalMaturityAmount": 0,
    "totalReceivableAmount": 0,
    "platformFee": 0,
    "tdsDeduct": false
  });

  const [error, setError] = useState([{
    calculatevalue: ''
  }])

  // remove validation function
  const removeError = (err: any) => {
    var tmp = [...error] as any;
    tmp[0][err] = "";
    setError(tmp);
  }

  const validate = () => {

    
    var totalProjectSizeAmount = parseInt(projectDetails[0]['investmentOppurtunitySize']);
    var totalProjectInvestedAmount = parseInt(projectDetails[0]['totalProjectAmount']);

    var validAmount = 0;

    validAmount = totalProjectSizeAmount - totalProjectInvestedAmount

    var isValid = true;
    var errorTmp = [...error];

    if (projectDetails[0]['minAmount'] >= calculatevalue && projectDetails[0]['minAmount'] <= validAmount) {
      errorTmp[0].calculatevalue = 'Enter More than Minimum Amount';
      isValid = false;
    }else if(projectDetails[0]['minAmount'] >= validAmount && calculatevalue >= validAmount){
      errorTmp[0].calculatevalue = `Due Investe Amount is Only ₹${validAmount}`;
      isValid = false;
    }

    setError(errorTmp);
    return isValid;
  }

  const handleClose = () => setShow(false);

  const handleShow = async (input: any) => {

    if (validate()) {

      setShow(true)
      var param: any = {
        projectId: projectId,
        prinicipleAmount: input || '0'
      }
      var response: any = await fetchData('calculateReturns', param)

      if (response.status == "200") {
        var data = response.data[0];

        setreturnArr(data)

      }
      else {
        toasterrormsg(response.message)
      }
    }

  };

  // Type Of Payment Model
  const handlePaymentTypeClose = () => setpaymentTypeModelShow(false);
  
  const handelPaymentTypeShow = async (investDetail?:any) => {

    setpaymentTypeModelShow(true)
    setinvestDetail(investDetail)

  };

  //Payment Mehod wise Redirection
  const handlePaymentredirection = async (type: any,input:any) => {

    if (type == "FundTransfer") {
     
      var param: any = {
        investorId: vendorId,
        amount: input.amount,
        financialcompanyId: input.financialcompanyId,
        transactionType: "OFFLINE",
        projectId: input.projectId,
      }
      var response: any = await fetchData('addeditinvestment', param)

      if (response.status == "200") {
        var data = response.data;
        // toastsuccessmsg(response.message)
        
        // setTimeout(() => {
          
          // navigate('/investmentlist')
          navigate("/inaltsBankDetail",{state:{ismessage:true,message:response.message}})
          
        // }, 1500);
      }
      else {
        toasterrormsg(response.message)
      }

     
    } else {
      // handelPayment()
    }

  };

  const recivebleAmount = async (input: any) => {
    
    if (validate()) {

      var param: any = {
        projectId: projectId,
        prinicipleAmount: input || '0'
      }
      var response: any = await fetchData('calculateReturns', param)

      if (response.status == "200") {
        var data = response.data[0];
        setrecivible(data.totalReceivableAmount)
        settotalMaturityAmount(data.totalMaturityAmount)
      }
      else {
        toasterrormsg(response.message)
      }
    }

  };

  // Investment APIs 
  const investApi = async (input: any) => {
    
    if (validate()) {

      if (vendorId) {

        if (vendorData.investorBankDetail.length != 0) {
        
          handelPaymentTypeShow(input);

        } else {
          navigate("/crafted/pages/profilemanagement/editprofile")
        }
      } else {
        navigate("/auth/login");
      }

    }

  };

  // Remove from ShortList
  const removeShort = async (e: any, ids: any) => {

    var res = await fetchData("removeSortProject", { investorId: vendorId, projects: projectId });


    if (res.status == "200") {
      toastsuccessmsg("Removed From Shortlist Successfully")

      profileDetailsApiCall()
    } else {
      toasterrormsg(res.message)
    }

  }

  // ------------- Payment API ------------- 
  const handelPayment = async () => {

    const cashfree = await load({
      mode: "sandbox" //or production
    });


    let checkoutOptions = {
      paymentSessionId: "session_yZuQVGDwECBsAH1skP9vQnoZqCu04M0ej4JS3_Rdpd2JHSdiQqdGxuRcRpxGgp0g3qwap3wYo5neT9uF83I4PBvpwiKiItcVZkR1VLolkUvL",
      returnUrl: "https://test.cashfree.com/pgappsdemos/return.php?order_id=order_123",

    }
    cashfree.checkout(checkoutOptions).then(function (result: any) {
      if (result.success) {
        alert(result)
      }
      if (result.redirect) {
        console.log("Redirection")
      }
    });
  }


  useEffect(() => {
    profileDetailsApiCall()
  }, [])
  return (
    <>
      <ToastContainer />
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          <KTIcon iconName='category' className='fs-2 text-primary' />
          &nbsp;&nbsp;Project Details
        </h3>
        {isShort == 1 ?
          (<div className="d-flex justify-content-around">
            <div className='btn btn-sm btn-light-primary mx-1' onClick={(e: any) => removeShort?.(e, projectId)}>
              <KTIcon iconName='heart' className='fs-3' />
              Remove From Shortlist
            </div>
          </div>) : ("")
        }
      </div>
      {
        projectDetails.map((project: any, index: any) => {

          
          var roi;
          var irr;


          if (project['payout'] == "FIXED_AMOUNT") {
            if (project['payoutDetail'].length != 0) {
              roi = project['payoutDetail'].length != 0 ? '₹ ' + project['payoutDetail'][0]['roiAmount'] : '₹ 0'
              irr = project['payoutDetail'].length != 0 ? '₹ ' + project['payoutDetail'][0]['irrAmount'] : '₹ 0'
            } else {
              roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
              irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
            }
          } else if (project['payout'] == "PERCENTAGE") {
            if (project['payoutDetail'].length != 0) {
              roi = project['payoutDetail'].length != 0 ? project['payoutDetail'][0]['roiPercentage'] + '%' : '0%'
              irr = project['payoutDetail'].length != 0 ? project['payoutDetail'][0]['irrPercentage'] + '%' : '0%'
            } else {
              roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
              irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
            }
          } else if (project['payout'] == "VARIABLE") {
            if (project['payoutDetail'].length != 0) {

              for (let i = 0; i < project['payoutDetail'].length; i++) {
                roi = 0;
                irr = 0;
                for (let i = 0; i < project['payoutDetail'].length; i++) {
                  roi += parseFloat(project['payoutDetail'][i]['roiPercentage']);
                  irr += parseFloat(project['payoutDetail'][i]['irrPercentage']);
                }
                roi = roi + '%';
                irr = irr + '%';
              }
            } else {
              roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
              irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
            }
          }


          return (

            <div className='row g-5 g-xxl-12' key={index}>
              <div className='col-xl-12'>
                <div className='card mb-5 mb-xxl-8'>
                  {/* begin::Body */}
                  <div className='card-body pb-0'>
                    {/* begin::Header */}
                    <div className='d-flex align-items-center mb-5'>
                      {/* begin::User */}
                      <div className='d-flex align-items-center flex-grow-1'>
                        {/* begin::Info */}
                        <div className='d-flex flex-column'>
                          <div className='text-dark fs-3 fw-bold'>{project['name']}</div>
                        </div>
                        {/* end::Info */}
                      </div>
                      {/* end::User */}
                    </div>
                    {/* end::Header */}

                    {/* begin::Post */}
                    <div className='row'>
                      {
                        project['projectmedia'].length == 1 ? (
                          <>
                            {project['projectmedia'].map((image: any, index: any) => {
                              if (index == 0) {
                                if (image['type'] == 'IMAGE') {
                                  return (
                                    <div className='col-lg-12 mb-3' key={index}>
                                      {/* begin::Image */}
                                      <div
                                        className='bgi-no-repeat bgi-size-cover rounded mb-5'
                                        style={{
                                          backgroundImage: `url('${image['name']}')`,
                                          minHeight: '400px',
                                          height: '100%',
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                        }}
                                      ></div>
                                      {/* end::Image */}
                                    </div>
                                  )
                                } else if (image['type'] == 'VIDEO') {
                                  return (
                                    <div className='col-lg-12' key={index}>
                                      <video
                                        controls
                                        className='rounded'
                                        style={{ height: '100%', width: '100%' }}
                                      >
                                        <source src={image['name']} type='video/mp4' />{' '}
                                        {/* Provide the path to the video */}
                                        Your browser does not support the video tag.
                                      </video>
                                    </div>
                                  )
                                } else if (image['type'] == 'DOCUMENT') {
                                  <div className='col-lg-12' key={index}>
                                    <a href={image['name']} target='_blank' rel='noopener noreferrer'>
                                      Open PDF in New Tab
                                    </a>
                                  </div>
                                }
                              }
                            })}
                            {/* full screen section */}
                            <div className='col-lg-2 d-none d-lg-block'>
                              <div className='row' style={{ overflowX: 'scroll' }}>
                                {project['projectmedia'].map((image: any, index: any) => {
                                  if (index > 0) {
                                    if (image['type'] == 'IMAGE') {
                                      return (
                                        <div className='col-lg-12 col-4' key={index}>
                                          {/* begin::Image */}
                                          <div
                                            className='bgi-no-repeat bgi-size-cover rounded min-h-100px h-200px mb-5'
                                            style={{
                                              backgroundImage: `url('${image['name']}')`,
                                              backgroundPosition: 'center',
                                              minHeight: '100px',
                                            }}
                                          ></div>
                                          {/* end::Image */}
                                        </div>
                                      )
                                    } else if (image['type'] == 'VIDEO') {
                                      return (
                                        <div className='col-lg-12 col-4' key={index}>
                                          <video
                                            controls
                                            className='rounded'
                                            style={{ height: '100%', width: '100%' }}
                                          >
                                            <source src={image['name']} type='video/mp4' />{' '}
                                            {/* Provide the path to the video */}
                                            Your browser does not support the video tag.
                                          </video>
                                        </div>
                                      )

                                    } else if (image['type'] == 'DOCUMENT') {

                                      return (
                                        <div className='col-lg-12' key={index}>

                                          <a href={image['name']} target='_blank' rel='noopener noreferrer'>
                                            <FontAwesomeIcon icon={faFile} className='mx-2 w-100 h-60px' />
                                          </a>
                                        </div>
                                      )
                                    }
                                  }
                                })}
                                {youtube.length != 0 && (

                                  youtube.map((link: any, index: any) => {
                                    if (link['link'].includes("youtu.be")) {
                                      var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                      // var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                      var videoId = videoIdWithParams.split('?')[0];

                                    }
                                    if (link['link'].includes("youtube.com")) {
                                      var videoIdWithParams = link['link'].replace('https://www.youtube.com/watch?v=', '');
                                      // var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                      var videoId = videoIdWithParams.split('?v=')[0];

                                    }

                                    const embedLink = `https://www.youtube.com/embed/${videoId}`;
                                    return (<div className='col-lg-12 col-4 mb-2' key={index}>
                                      <iframe
                                        style={{ width: '100%', height: '100%' }}
                                        src={embedLink}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded YouTube Video"
                                        className='rounded'
                                      ></iframe>
                                    </div>)
                                  }

                                  )
                                )

                                }

                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {project['projectmedia'].map((image: any, index: any) => {
                              if (index == 0) {
                                if (image['type'] == 'IMAGE') {
                                  return (
                                    <div className='col-lg-10 mb-3' key={index}>
                                      {/* begin::Image */}
                                      <div
                                        className='bgi-no-repeat bgi-size-cover rounded mb-5'
                                        style={{
                                          backgroundImage: `url('${image['name']}')`,
                                          minHeight: '400px',
                                          height: '100%',
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                        }}
                                      ></div>
                                      {/* end::Image */}
                                    </div>
                                  )
                                } else if (image['type'] == 'VIDEO') {
                                  return (
                                    <div className='col-lg-10' key={index}>
                                      <video
                                        controls
                                        className='rounded'
                                        style={{ height: '100%', width: '100%' }}
                                      >
                                        <source src={image['name']} type='video/mp4' />{' '}
                                        {/* Provide the path to the video */}
                                        Your browser does not support the video tag.
                                      </video>
                                    </div>
                                  )
                                } else if (image['type'] == 'DOCUMENT') {
                                  <div className='col-lg-10' key={index}>
                                    <a href={image['name']} target='_blank' rel='noopener noreferrer'>
                                      Open PDF in New Tab
                                    </a>
                                  </div>
                                }
                              }
                            })}
                            {/* full screen section */}
                            <div className='col-lg-2 d-none d-lg-block'>
                              <div className='row' style={{ overflowX: 'scroll', height: '440px' }}>
                                {project['projectmedia'].map((image: any, index: any) => {
                                  if (index > 0) {
                                    if (image['type'] == 'IMAGE') {
                                      return (
                                        <div className='col-lg-12 col-4' key={index}>
                                          {/* begin::Image */}
                                          <div
                                            className='bgi-no-repeat bgi-size-cover rounded min-h-100px h-200px mb-5'
                                            style={{
                                              backgroundImage: `url('${image['name']}')`,
                                              backgroundPosition: 'center',
                                              minHeight: '100px',
                                            }}
                                          ></div>
                                          {/* end::Image */}
                                        </div>
                                      )
                                    } else if (image['type'] == 'VIDEO') {
                                      return (
                                        <div className='col-lg-12 col-4' key={index}>
                                          <video
                                            controls
                                            className='rounded'
                                            style={{ height: '100%', width: '100%' }}
                                          >
                                            <source src={image['name']} type='video/mp4' />{' '}
                                            {/* Provide the path to the video */}
                                            Your browser does not support the video tag.
                                          </video>
                                        </div>
                                      )

                                    } else if (image['type'] == 'DOCUMENT') {

                                      return (
                                        <div className='col-lg-10' key={index}>

                                          <a href={image['name']} target='_blank' rel='noopener noreferrer'>
                                            <FontAwesomeIcon icon={faFile} className='mx-2 w-100 h-60px' />
                                          </a>
                                        </div>
                                      )
                                    }
                                  }
                                })}
                                {youtube.length != 0 && (

                                  youtube.map((link: any, index: any) => {
                                    if (link['link'].includes("youtu.be")) {
                                      var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                      // var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                      var videoId = videoIdWithParams.split('?')[0];

                                    }
                                    if (link['link'].includes("youtube.com")) {
                                      var videoIdWithParams = link['link'].replace('https://www.youtube.com/watch?v=', '');
                                      // var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                      var videoId = videoIdWithParams.split('?v=')[0];

                                    }

                                    const embedLink = `https://www.youtube.com/embed/${videoId}`;
                                    return (<div className='col-lg-12 col-4 mb-2' key={index}>
                                      <iframe
                                        style={{ width: '100%', height: '100%' }}
                                        src={embedLink}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded YouTube Video"
                                        className='rounded'
                                      ></iframe>
                                    </div>)
                                  }

                                  )
                                )

                                }

                              </div>

                            </div>
                          </>
                        )
                      }
                      {/* mobile screen section */}
                      <div className='col-lg-2 d-block d-lg-none'>
                        <div className='row d-flex flex-nowrap' style={{ overflowX: 'scroll' }}>
                          {project['projectmedia'].map((image: any, index: any) => {
                            if (index > 0) {
                              if (image['type'] == 'IMAGE') {
                                return (
                                  <div className='col-lg-12 col-6' key={index}>
                                    {/* begin::Image */}
                                    <div
                                      className='bgi-no-repeat bgi-size-cover rounded min-h-100px h-200px mb-5'
                                      style={{
                                        backgroundImage: `url('${image['name']}')`,
                                        backgroundPosition: 'center',
                                      }}
                                    ></div>
                                    {/* end::Image */}
                                  </div>
                                )
                              } else if (image['type'] == 'VIDEO') {
                                return (
                                  <div className='col-lg-12 col-6' key={index}>
                                    <video
                                      controls
                                      className='rounded'
                                      style={{ height: '200px', width: '100%' }}
                                    >
                                      <source src={image['name']} type='video/mp4' />{' '}
                                      {/* Provide the path to the video */}
                                      Your browser does not support the video tag.
                                    </video>
                                  </div>
                                )
                              } else if (image['type'] == 'DOCUMENT') {
                                ; <div className='col-lg-10' key={index}>
                                  <div className=''>
                                    <p className='fs-4'>
                                      {' '}
                                      <FontAwesomeIcon icon={faFile} className='mx-2' />
                                      {image['name']}
                                    </p>
                                  </div>
                                  <a href={image['name']} target='_blank' rel='noopener noreferrer'>
                                    Open PDF in New Tab
                                  </a>
                                </div>
                              }
                            }
                          })}
                          {youtube.length != 0 && (

                            youtube.map((link: any, index: any) => {
                              if (link['link'].includes("youtu.be")) {
                                var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                // var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                var videoId = videoIdWithParams.split('?')[0];

                              }
                              if (link['link'].includes("youtube.com")) {
                                var videoIdWithParams = link['link'].replace('https://www.youtube.com/watch?v=', '');
                                // var videoIdWithParams = link['link'].replace('https://youtu.be/', '');
                                var videoId = videoIdWithParams.split('?v=')[0];

                              }

                              const embedLink = `https://www.youtube.com/embed/${videoId}`;
                              return (<div className='col-lg-12 col-4 mb-2' key={index}>
                                <iframe
                                  style={{ width: '100%', height: '100%' }}
                                  src={embedLink}
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                  title="Embedded YouTube Video"
                                  className='rounded'
                                ></iframe>
                              </div>)
                            }

                            )
                          )

                          }
                        </div>
                      </div>
                    </div>
                    <div className='my-5'>
                      {/* begin::Text */}
                      <div className='text-gray-800 mb-5'>
                        <div
                          dangerouslySetInnerHTML={{ __html: project['projectDescription'] }}
                        ></div>
                      </div>
                      {/* end::Text */}
                    </div>
                    {/* end::Post */}

                    {/* begin::Separator */}
                    <div className='separator mb-4'></div>
                    {/* end::Separator */}
                    <div className='d-flex flex-wrap mb-5 mt-5'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                        <div className='fw-bold text-gray-400'>Risk Rating</div>
                        <div className='fs-6 fw-bolder text-gray-700'>{project['riskRating']}</div>
                      </div>
                      {project['category'] != "665d4c3e25c3014efceae144" ? (
                        <>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                            <div className='fw-bold text-gray-400'>IRR</div>
                            <div className='fs-6 fw-bolder text-gray-700'>
                              {irr}
                            </div>
                          </div>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                            <div className='fw-bold text-gray-400'>ROI</div>
                            <div className='fs-6 fw-bolder text-gray-700'>
                              {roi}
                            </div>
                          </div>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                            <div className='fw-bold text-gray-400'>Payout</div>
                            <div className='fs-6 fw-bolder text-gray-700'>{project['payout']}</div>
                          </div>
                        </>
                      ) : (
                        // <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                        //   <div className='fw-bold text-gray-400'>Exp. Gross Yield</div>
                        //   <div className='fs-6 fw-bolder text-gray-700'>{project['grossYield']} %</div>
                        // </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                          <div className='fw-bold text-gray-400'>Interest Rate</div>
                          <div className='fs-6 fw-bolder text-gray-700'>{project['interestRate']} %</div>
                        </div>
                      )}
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                        <div className='fw-bold text-gray-400'>Tenure</div>
                        <div className='fs-6 fw-bolder text-gray-700'>
                          {project['tenure'] + ' ' + project['tenureType']}
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                        <div className='fw-bold text-gray-400'>Exit Duration</div>
                        <div className='fs-6 fw-bolder text-gray-700'>
                          {project['exitPeriod']} {project['exitPeriodType']}
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                        <div className='fw-bold text-gray-400'>LockIn Period</div>
                        <div className='fs-6 fw-bolder text-gray-700'>
                          {project['lockInPeriod']} {project['lockInPeriodType']}
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                        <div className='fw-bold text-gray-400'>Total Size of investment</div>
                        <div className='fs-6 fw-bolder text-gray-700'>
                          {project['investmentOppurtunitySize']}
                        </div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                        <div className='fw-bold text-gray-400'>TDS Deduction</div>
                        <div className='fs-6 fw-bolder text-gray-700'>
                          {project['tdsDeduct'] ? 'Yes' : 'No'}
                        </div>
                      </div>
                      {project['tdsDeduct'] ? (
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-center'>
                          <div className='fw-bold text-gray-400'>TDS</div>
                          <div className='fs-6 fw-bolder text-gray-700'>{project['tdsPercentage']} %</div>
                        </div>
                      ) : ''}



                    </div>
                    {/* begin::Separator */}
                    {/* <div className='separator mb-4'></div> */}
                    {/* end::Separator */}
                    {/* <div className='row g-5 g-xxl-12 mb-2'>
                      <div className='col-xl-12'>
                        <div className='d-flex flex-wrap justify-content-between'>
                          <div className='my-2'>
                            <div className='fw-bold text-gray-400'>Investment Amount</div>
                            <div className='fs-6 fw-bolder text-gray-700'>₹0</div>
                          </div>
                          <div className='my-2'>
                            <div className='fw-bold text-gray-400'>Expected Return Amount</div>
                            <div className='fs-6 fw-bolder text-gray-700'>₹0</div>
                          </div>
                          <div className='my-2'>
                            <div className='fw-bold text-gray-400'>Payout</div>
                            <div className='fs-6 fw-bolder text-gray-700'>0 Investment</div>
                          </div>
                          <div className='my-2'>
                            <div className='fw-bold text-gray-400'>TDS Deduction</div>
                            <div className='fs-6 fw-bolder text-gray-700'>
                              {project['tdsPercentage']}%
                            </div>
                          </div>
                          <div className='my-2'>
                            <div className='fw-bold text-gray-400'>LockIn Period</div>
                            <div className='fs-6 fw-bolder text-gray-700'>
                              {project['lockInPeriod']} {project['lockInPeriodType']}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className='separator mb-8'></div>
                    {/* -------------- calculator ------------------------ */}

                    {/* <div className='m-15 text-center'>
                    <label className='fs-1 fw-bold '> Investment Calculator</label>
                  </div> */}

                    <div className='row g-5 g-xxl-12 mb-2'>
                      <div className='col-xl-12'>
                        <div className='row'>
                          <div className='col my-2'>
                            <div className='fw-bold text-gray-400'>Max Amount</div>
                            <div className='fs-6 fw-bolder text-gray-700'>₹{project['maxAmount']}</div>
                          </div>
                          <div className='col my-2'>
                            <div className='fw-bold text-gray-400'>Min Amount</div>
                            <div className='fs-6 fw-bolder text-gray-700'>₹{project['minAmount']}</div>
                          </div>
                          <div className='col my-2'>
                            <div className='fw-bold text-gray-400'>Enter Investment Amount </div>
                            {/* <div className='fs-6 fw-bolder text-gray-700'></div> */}
                            <input type="number" placeholder='₹ 0' className='form-control bg-transparent mb-3' onKeyUp={() => {
                              // if (project['minAmount'] <= calculatevalue) {
                              recivebleAmount(calculatevalue);
                              // }
                              removeError("calculatevalue");
                            }} onChange={(e: any) => {
                              // setcalculatevalue(e.target.value && project['minAmount'] <= e.target.value ? e.target.value : 0);removeError("calculatevalue");
                              setcalculatevalue(e.target.value); removeError("calculatevalue");
                            }} />

                            {/* <span style={{ color: '#b5b5c3' }}>Note - Enter More than Minimum Amount</span> */}
                            <div className='text-danger fw-bold'>{error[0].calculatevalue}</div>

                          </div>
                          <div className='col my-2'>
                            <button className='btn btn-primary' onClick={() => {
                              // if (project['minAmount'] <= calculatevalue) {
                              handleShow(calculatevalue);
                              // }
                            }
                            }><FontAwesomeIcon icon={faCalendarDays} className='mx-2' />Calculate Returns</button>
                          </div>
                        </div>
                      </div>
                    </div>

                      <div className='row g-5 g-xxl-12 mb-2'>
                        <div className='col-xl-12'>
                          <div className='row'>
                            <div className='col my-2'>
                              <div className='fw-bold text-gray-400'>Total Investment Amount</div>
                              <div className='fs-6 fw-bolder text-gray-700'>₹{calculatevalue}</div>
                            </div>
                            <div className='col my-2'>
                              {project['tdsDeduct'] ? (<>
                                <div className='fw-bold text-gray-400'>Maturity Amount</div>
                                <div className='fs-6 fw-bolder text-gray-700'>₹{totalMaturityAmount.toFixed(2)}</div>
                              </>) : ""

                              }
                            </div>
                            <div className='col my-2'>
                              {project['tdsDeduct'] ? (<>
                                <div className='fw-bold text-gray-400'>Post TDS Amount</div>
                                {/* <div className='fs-6 fw-bolder text-gray-700'>₹{(calculatevalue) - ((calculatevalue) * (project['tdsPercentage'] / 100))}</div> */}
                                <div className='fs-6 fw-bolder text-gray-700'>₹{recivible.toFixed(2)}</div>
                              </>) : ""

                              }
                              {/* <div className='fw-bold text-gray-400'>Post TDS Amount</div>
                            <div className='fs-6 fw-bolder text-gray-700'>₹{ (parseFloat(calculatevalue)) ? (parseFloat(calculatevalue)) - ((parseFloat(calculatevalue))*(project['tdsPercentage']/100)) : 0}</div> */}
                            </div>

                           <div className='col my-2'>
                                <button type='button' id='kt_sign_in_submit' className='btn btn-primary' onClick={() => {
                                    
                                    investApi({amount: calculatevalue, financialcompanyId: project['financialCompanyId'], projectId: project['_id'] })

                                 }}>Invest Now
                                </button>
                              </div>

                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                {/* end::Body */}
              </div>

              <div className='d-grid mb-10 col-2'>

              </div>

            </div>



          )
        })
      }

      {/* ------------------- investor sesction -------------------- */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Visualise Returns</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=''>
            <div className='d-flex justify-content-between'>
              <label className='fw-bold fs-4 text-gray-400'>Investment Amount</label>
              <label className='fw-bold fs-4'>{calculatevalue}</label>
            </div>
            <div className='separator mb-8'></div>
            {/* 
            <div className='d-flex justify-content-between'>
              <label className='fw-bold fs-4 text-gray-400'>Gross Yield</label>
              <label className='fw-bold fs-4'>{returnArr['grossYield']} %</label>
            </div>
            <div className='separator mb-8'></div> */}

            <div className='d-flex justify-content-between'>
              <label className='fw-bold fs-4 text-gray-400'>Interest Rate</label>
              <label className='fw-bold fs-4'>{returnArr['interestRate']} %</label>
            </div>
            <div className='separator mb-8'></div>

            <div className='d-flex justify-content-between'>
              <label className='fw-bold fs-4 text-gray-400'>Tenure</label>
              <label className='fw-bold fs-4'>{returnArr['tenure']} {returnArr['tenureType']}</label>
            </div>
            <div className='separator mb-8'></div>

            <div className='d-flex justify-content-between'>
              <label className='fw-bold fs-4 text-gray-400'>Interest Amount</label>
              {/* (principal * rate * timeInYears) / 100; */}
              <label className='fw-bold fs-4'>{returnArr['interestAmount'].toFixed(2)}</label>
            </div>
            <div className='separator mb-8'></div>

            <div className='d-flex justify-content-between'>
              <label className='fw-bold fs-4 text-gray-400'>Platform Fee</label>
              {/* (principal * rate * timeInYears) / 100; */}
              <label className='fw-bold fs-4'>{returnArr['platformFee']}</label>
            </div>
            <div className='separator mb-8'></div>

            <div className='d-flex justify-content-between'>
              {/* <label className='fw-bold fs-4 text-gray-400'>Interest Amount Post TDS</label> */}
              <label className='fw-bold fs-4 text-gray-400'>TDS Deduction</label>
              {/* (principal * rate * timeInYears) / 100; */}
              <label className='fw-bold fs-4'>{returnArr['tdsInterest'].toFixed(2)}</label>
            </div>
            <div className='separator mb-8'></div>

            <div className='d-flex justify-content-between'>
              <label className='fw-bold fs-4 text-gray-400'>Total Maturity Amount</label>
              <label className='fw-bold fs-4'>{returnArr['totalMaturityAmount'].toFixed(2)}</label>
            </div>
            <div className='separator mb-8'></div>

            <div className='d-flex justify-content-between'>
              <label className='fw-bold fs-4 text-gray-400'>Total Receivable Amount</label>
              <label className='fw-bold fs-4'>{returnArr['totalReceivableAmount'].toFixed(2)}</label>
            </div>
            <div className='separator mb-8'></div>
            {!returnArr['tdsDeduct'] && (<>
              <div className='d-flex justify-content-between'>
                <label className='fw-bold fs-4 text-gray-400'>Note - TDS Not being Deducted</label>
              </div>
              <div className='separator mb-8'></div>
            </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <div className='m-3'>
                    <label className='fs-4 fw-bold' >Pre-Tax returns: ₹ {}</label> 
                  </div> */}
          <button className='btn btn-danger' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* ----------------- InvestMent Type Section ---------------------*/}

      <Modal show={paymentTypeModelShow} onHide={handlePaymentTypeClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=''>
            <div className='row'>
              <div
                className='dropzone dz-clickable payment-option'
                id='kt_ecommerce_add_frontPanCard'
                onClick={()=>{handlePaymentredirection("FundTransfer",investDetail)}}
              >
                <div className='dz-message needsclick'>
                 
                  <i className='ki-outline ki-bank text-primary fs-3x'></i>
                  <div className='ms-4'>
                    <h3 className='fw-bold text-gray-900 mt-3'>
                    Pay Direct Offline
                    </h3>
                    
                  </div>
                  {/* <!--end::Info--> */}
                </div>
              </div>
              <div
                className='dropzone dz-clickable mt-5 payment-option'
                id='kt_ecommerce_add_frontPanCard'
                onClick={()=>{handlePaymentredirection("OnlineTransfer",investDetail)}}
              >
                <div className='dz-message needsclick'>
                 
                  <i className='ki-outline ki-bill text-primary fs-3x'></i>
                  <div className='ms-4'>
                    <h3 className='fw-bold text-gray-900 mt-3'>
                     Pay Online
                    </h3>
                    
                  </div>
                  {/* <!--end::Info--> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <div className='m-3'>
                    <label className='fs-4 fw-bold' >Pre-Tax returns: ₹ {}</label> 
                  </div> */}
          <button className='btn btn-danger' onClick={handlePaymentTypeClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
