/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { fetchData, toasterrormsg } from '../../../../reusable';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Card6 } from '../../../../_metronic/partials/content/cards/Card6'
import { IconUserModel } from '.././ProfileModels'
import { useQueryResponse } from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider';
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { Dropdown1 } from '../../../../_metronic/partials';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertToRaw } from 'draft-js';
import Swal from 'sweetalert2';
// import { Card4V2 } from '../../../../_metronic/partials/content/cards/Card4V2';

export function NewsFeedDetail() {
  useEffect(() => {
    document.title = 'Investor | News Feed';
  }, []);
  var vendorId = "";
  const vendorData = sessionStorage.getItem("vendor");
  if (vendorData) {
    var vendor = JSON.parse(vendorData);
    vendorId = vendor.vendorId;

  } else {
    console.log("No vendor data found");
  }

  useEffect(() => {
    profileDetailsApiCall(editNewsId)
    newsListSideAPi()
  }, [])

  const location = useLocation();
  const editNewsId = (location.state as any)?.newsFeedId || '';

  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  const [filter, setfilter] = useState(0)

  const formatDate = (isoDate: any) => {
    const date = parseISO(isoDate);
    return format(date, "do MMMM yyyy");
  };

  const [tab, setTab] = useState('Artical') // EditProfile, ChangePassword, KYCDocument
  const [offset, setoffset] = useState(0)
  const [limit, setlimit] = useState(9);
  const [page, setpage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [outdata, setoutdata] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [from, setfrom] = useState(1)
  const [to, setTo] = useState(1)
  const [pageNumbersList, setPageNumberList] = useState([1])

  const [projectDetails, setProjectDetails] = useState([]);
  const [projectDetailsList, setProjectDetailsList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  // page number generater
  function generatePageNumbers(currentPage: any, totalPages: any, maxPagesToShow: any) {
    const halfMaxPages = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPages);
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Adjust startPage and endPage if the total pages to show is less than maxPagesToShow
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    // Generate the list of page numbers
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }
  // on page change function
  const onpagecahngeold = (pageId: any) => {
    if (pageId != page && pageId != 0 && pageId <= totalPage) {
      // console.log(pageId);
      var tmp = (pageId - 1) * limit
      setpage(pageId)
      setoffset(tmp)
      setfrom(tmp + 1)
      // setto
      var mod = projectDetails.length % limit
      var tolimi: any = limit
      if (mod > 0) {
        if (mod <= limit) {
          tolimi = pageId * limit
          if (tolimi > projectDetails.length) {
            tolimi = projectDetails.length
          }
        }
        else {
          tolimi = pageId * limit + limit
          if (tolimi > projectDetails.length) {
            tolimi = projectDetails.length
          }
        }
      }
      else {
        tolimi = pageId * limit
      }
      setTo(tolimi)
      var tmpdata: any = projectDetails.slice(tmp, tolimi)
      setProjectDetailsList(tmpdata)
      // console.log("tmp offset",tmp);
    }

  }

  const onpagecahnge = (pageId: any, data: any = [], fromSearch: any = false, limit: any = 10) => {


    var newdata: any = []
    if (fromSearch == true) {
      newdata = [...data];
    }
    else {
      newdata = [...projectDetails]
      console.log("newdata.length", newdata.length);
    }
    console.log("data", data);

    setTotalData(newdata.length)
    if ((pageId != page && pageId != 0 && pageId <= totalPage) || data.length > 0) {
      // console.log(pageId);
      var tmp = (pageId - 1) * limit
      setpage(pageId)
      setoffset(tmp)
      setfrom(tmp + 1)
      // setto
      var mod = newdata.length % limit
      var tolimi: any = limit
      if (mod > 0) {
        if (mod <= limit) {
          tolimi = pageId * limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
        else {
          tolimi = pageId * limit + limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
      }
      else {
        tolimi = pageId * limit
      }
      setTo(tolimi)
      var tmpdata: any = newdata.slice(tmp, tolimi)
      setProjectDetailsList(tmpdata)
      // console.log("tmp offset",tmp);
    }
    else if (data.length == 0) {
      setTo(0)
      setfrom(0)

    }
    var totpag = (Math.ceil(newdata.length / limit))
    setTotalPage(totpag)
    // setProjectDetailsList(data)
    // onpagecahnge(1,data,true)
    setpage(pageId)
    var genpag = generatePageNumbers(1, totpag, 6)
    setPageNumberList(genpag)

  }

  const projectsearch = async (text: any) => {


    if (text == "" || text == null || text == undefined) {
      // setProjectDetailsList(projectDetails)
      console.log(projectDetails);
      var tmp: any = projectDetails.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(projectDetails.length / limit))
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = projectDetails.length % limit
        if (mod > 0) {
          setTo(mod)
        }
        else {
          setTo(tmp.length)
        }
      }
      else {
        var mod = projectDetails.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)

          }
          else {
            setTo(page * limit + limit)
          }
        }
        else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      var tmp: any = projectDetails.filter((item: any) => {
        return (item.name != null && item.name != "" && (item.name).toLowerCase().includes(text.toLowerCase())) ||
          (item.riskRating != null && item.riskRating != "" && (item.riskRating).toLowerCase().includes(text.toLowerCase()) ||
            (item.category != null && item.category != "" && (item.category.name).toLowerCase().includes(text.toLowerCase())));
      });

      // var tmp:any=projectDetails.filter((item:any)=>item.name!=null && item.name!="" && (item.name).toLowerCase() == text.toLowerCase())
      // var tmp:any=projectDetails.filter((item:any)=>item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase() == text.toLowerCase()) ||(item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase().includes(text.toLowerCase())) ||(item.email!=null && item.email!="" && (item.email).toLowerCase() == text.toLowerCase()) ||(item.email!=null && item.email!="" && (item.email).toLowerCase().includes(text.toLowerCase())) ||(item.location!=null && item.location!="" && (item.location).toLowerCase() == text.toLowerCase()) ||(item.location!=null && item.location!="" && (item.location).toLowerCase().includes(text.toLowerCase()))
      // var tmp:any=orguserList.filter((item:any)=>((item.fullName)).toLowerCase() == text.toLowerCase() 
      // || ((item.email)+' '+(item.email)).toLowerCase().includes(text.toLowerCase()))
      setProjectDetailsList(tmp)
    }
  }
  // profile Details api call
  const profileDetailsApiCall = async (editNewsId:any) => {

    console.log("-----------------------",editNewsId);
    

    var param: any = {
      createrId: vendorId,
      newsFeedId: editNewsId
    }
    var response: any = await fetchData("viewnews", param)
    console.log(response)
    if (response.status == "200") {
      setProjectDetails(response.data);
      var res = response.data
      setTotalData(res.length)
      console.log(res);

      var tmp: any = res.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(res.length / limit))
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = res.length % limit
        if (mod > 0) {
          setTo(mod)
        }
        else {
          setTo(tmp.length)
        }
      }
      else {
        var mod = res.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)

          }
          else {
            setTo(page * limit + limit)
          }
        }
        else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      toasterrormsg(response.message)
    }
  }

  const newsListSideAPi = async () => {

    var param: any = {
      createrId: vendorId,

    }
    var response: any = await fetchData("viewnews", param)
    console.log(response)
    if (response.status == "200") {

      setNewsList(response.data);

    }
    else {
      toasterrormsg(response.message)
    }
  }


  const convertHtmlToEditorState = (html: any) => {
    const blocksFromHTML = stateFromHTML(html);

    return EditorState.createWithContent(blocksFromHTML);
  };

  const editorStateToPlainText = (editorState: any) => {
    const contentState = editorState.getCurrentContent();
    return contentState.getPlainText();
  };

  const intl = useIntl()
  useEffect(() => {
    profileDetailsApiCall(editNewsId)
    newsListSideAPi()
  }, [])


  return (
    <>
      <div className=''>

        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'News Feed' })}</PageTitle>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>

            {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span> */}
          </h3>

          <div className='d-flex flex-wrap my-2'>
            <div className='d-flex align-items-center position-relative me-4'>
              <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-white form-control-sm w-150px ps-9'
                placeholder='Search'
                onChange={(e) => {
                  projectsearch(e.target.value)
                }}
              />
            </div>
            {/* {addbtnShw ?  */}
            {/* <Link to='../addNewsFeed/' className='btn btn-primary btn-sm'>
              Add News Feed{' '}
            </Link> */}
            {/* : ''} */}
          </div>

        </div>
        <div className=' pt-3'>
          <div className={clsx('g-6 g-xl-9 ')}>
            <div className='card shadow-sm p-0 rounded'>
              <div className='row p-9'>

                  {projectDetailsList.map((project: any, index: any) => {

                    const description = project.description;

                    let displayText;

                    // Convert the full HTML to EditorState and then to plain text
                    const editorState = convertHtmlToEditorState(description);
                    displayText = editorStateToPlainText(editorState);


                    return (
                      <div className='py-2 px-3 col'>
                        {/* begin::Header */}
                        <div className='d-flex align-items-center mb-5'>
                          {/* begin::User */}
                          <div className='d-flex align-items-center flex-grow-1'>

                            <div className='d-flex flex-column'>
                              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                                {project.title}
                              </a>

                              <span className='text-gray-400 fw-semibold mb-1'>{formatDate(project.date)}</span>

                            </div>
                            {/* end::Info */}
                          </div>

                        </div>

                        <div className='mb-5'>
                          {/* begin::Image */}
                          <div
                            className='bgi-no-repeat bgi-size-cover rounded  mb-5'
                            style={{
                              backgroundImage: `url('${project.file}')`,
                              minHeight: '400px',
                              height: '100%',
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                            }}
                          ></div>

                          <div className='text-gray-800 mb-5'>
                            {displayText}
                          </div>

                        </div>
                      </div>
                    )
                  })}

                  <div className='col-4 p-2'>
                    <div className='row' style={{ overflowX: 'scroll' ,height: '620px'}}>
                      {newsList.map((news: any, index: any) => {
                        const description = news.description;
                        let displayText;

                        // Convert the full HTML to EditorState and then to plain text
                        const editorState = convertHtmlToEditorState(description);
                        displayText = editorStateToPlainText(editorState)
                        return (
                          <div className='pb-0' style={{cursor:'pointer'}} onClick={() => profileDetailsApiCall(news._id)} key={index}>
                            {/* begin::Header */}
                            <div className='d-flex align-items-center mb-5'>
                              {/* begin::User */}
                              <div className='d-flex align-items-center flex-grow-1'>

                                <div className='d-flex flex-column'>
                                  <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                                    {news.title}
                                  </a>

                                  <span className='text-gray-400 fw-semibold mb-1'>{formatDate(news.date)}</span>

                                </div>
                                {/* end::Info */}
                              </div>

                            </div>

                            <div className='mb-5'>
                              {/* begin::Image */}
                              <div
                                className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5'
                                style={{
                                  backgroundImage: `url('${news.file}')`,
                                }}
                              ></div>

                              <div className='text-gray-800 mb-5'>
                                {displayText}
                              </div>

                            </div>
                          </div>
                        )
                      })}

                    </div>
                  </div>
              </div>


            </div>
          </div>

        </div>


      </div>
    </>
  )
}
