import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { fetchData, fetchFormData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'
import { ToastContainer } from 'react-toastify'
import { FileUploader } from 'react-drag-drop-files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'

export function KycUpload() {
  var investorId: any = sessionStorage.getItem('author') 
  useEffect(() => {
    investorId = sessionStorage.getItem('author')

    document.title = 'Investor | KYC Upload';
  }, []);
  const navigate=useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState("")
  const [countryCodeList,setCountryCodeList]=useState([])
  const [countryCode,setCountryCode]=useState(Object)
  const [email,setEmail]=useState("")
  const [mobileNumber,setMobileNumber]=useState("")
  const [isSubmited,setIsSubmited]=useState(false)


  const [frontPan, setFrontPan] = useState('')
  const [frontAadhar, setFrontAadhar] = useState('')
  const [backAadhar, setBackAadhar] = useState('')

  const [frontPanCard, setFrontPanCard] = useState() as any
  const [frontAadharCard, setFrontAadharCard] = useState() as any
  const [backAadharCard, setBackAadharCard] = useState() as any
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const fileTypes = ['JPEG', 'JPG', 'PNG','PDF']

  const validateEmail=async()=>{
    
    if(email.trim()==="" || email.trim()===null || email.trim()===undefined)
    {
        setHasErrors("Email Address Is Required")
    }
    else if(!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i) ) {
        setHasErrors("Enter Valid Email Address")
    }
    else
    {
      
      // try {
      //   await auth.signInWithPhoneNumber(mobileNumber, new firebase.auth.RecaptchaVerifier('recaptcha-container'));
      //   // Handle success, navigate to OTP verification page
      // } catch (error) {
      //   console.error("Error sending OTP:", error);
      //   // Handle error
      // }
      // forgotpasswordapicall()
      // navigate("../reset-password")
    }
  }
  
  const ownerDocuemntvalidate = () => {
    var isValid = true
    var errorTmp = [...ownerDocumentError]

    if (!frontPan) {
      if (frontPanCard == undefined || frontPanCard == null || frontPanCard == undefined) {
        errorTmp[0].frontPanCard = 'Front Pan Card is required'
        isValid = false
      }
    }
    if (!frontAadhar) {
      if (frontAadharCard == undefined || frontAadharCard == null || frontAadharCard == undefined) {
        errorTmp[0].frontAadharCard = 'Front Aadhar Card is required'
        isValid = false
      }
    }
    if (!backAadhar) {
      if (backAadharCard == undefined || backAadharCard == null || backAadharCard == undefined) {
        errorTmp[0].backAadharCard = 'Back Aadhar Card is required'
        isValid = false
      }
    }
    setOwnerDocumentError(errorTmp)
    return isValid
  }

  const ownerDocumentApi = async () => {
     
     if (ownerDocuemntvalidate()) {
     
      let param = new FormData()

       param.append('investorId', investorId)
       if (frontPanCard) {
         param.append('panCard', frontPanCard)
       }
       if (frontAadharCard) {
         param.append('frontAadharCard', frontAadharCard)
       }
       if (backAadharCard) {
         param.append('backAadharCard', backAadharCard)
       }
       var res = await fetchFormData('kycupload', param)
       if (res.status == '200') {
         toastsuccessmsg('Kyc Document Saved Successfully!')
         setTimeout(() => {
           navigate('../login')
         }, 1500)
       } else {
         toasterrormsg(res.message)
       }
     }
  }

  // Validation ------------------
  const [ownerDocumentError, setOwnerDocumentError] = useState([
    {
      frontPanCard: '',
      frontAadharCard: '',
      backAadharCard: '',
    },
  ])

  const removeOwnerDocuemnt = (err: any) => {
    var tmp = [...ownerDocumentError] as any
    tmp[0][err] = ''
    setOwnerDocumentError(tmp)
  }

  const handleDivClick = (inputId: string) => {
    const inputElement = document.getElementById(inputId)
    if (inputElement) {
      inputElement.click()
    }
  }

  const handleFrontPanCardChange = (file: any) => {
     if (file) {
      //  if (!file.type.startsWith('image/')) {
      //    // Invalid file type
      //    // Handle error or display a message
      //    toasterrormsg('Invalid file type. Please upload only image files.')
      //    setFrontPanCard(undefined)
      //    return
      //  }
       // Handle business certificate file change
       // For example:
       setFrontPanCard(file)
       removeOwnerDocuemnt('frontPanCard')
     } else {
       // Handle no file selected case
       console.error('No file selected.')
     }
  }

  const handleFrontAdharCardChange = (file: any) => {
    if (file) {
      // if (!file.type.startsWith('image/')) {
      //   // Invalid file type
      //   // Handle error or display a message
      //   toasterrormsg('Invalid file type. Please upload only image files.')
      //   setFrontAadharCard(undefined)
      //   return
      // }
      // Handle business certificate file change
      // For example:
      setFrontAadharCard(file)
      removeOwnerDocuemnt('frontAdharCard')
    } else {
      // Handle no file selected case
      console.error('No file selected.')
    }
  }
  const handleBackAdharCardChange = (file: any) => {
    if (file) {
      // if (!file.type.startsWith('image/')) {
      //   // Invalid file type
      //   // Handle error or display a message
      //   toasterrormsg('Invalid file type. Please upload only image files.')
      //   setBackAadharCard(undefined)
      //   return
      // }
      // Handle business certificate file change
      // For example:
      setBackAadharCard(file)
      removeOwnerDocuemnt('backAdharCard')
    } else {
      // Handle no file selected case
      console.error('No file selected.')
    }
  }

  return (
    <>
      <ToastContainer />
      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        id='kt_login_password_reset_form'
      >
        <div className='text-start my-15'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>
            Be investor ready by <br /> completing your KYC
          </h1>
        </div>

        {/* begin::Form group */}
        {/* <div className='fv-row mb-15'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder='Email'
            autoComplete='off'
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value)
            }}
            className={'form-control bg-transparent'}
          />
          <span className='text-danger mt-3'>{hasErrors}</span>
        </div> */}
        {/* end::Form group */}

        <div className={'tab-pane KYCDocument'}>
          <div className='row mb-10'>
            <div className='col-lg-10'>
              <>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='mt-5'>
                      <FileUploader
                        multiple={false}
                        handleChange={handleFrontPanCardChange}
                        name='frontPanCard'
                        types={fileTypes}
                        classes='w-100'
                      >
                        <div
                          className='dropzone dz-clickable'
                          id='kt_ecommerce_add_frontPanCard'
                          onClick={() => handleDivClick('frontPanCard')}
                        >
                          {/* <input type="file" name='frontPanCard' id='frontPanCard' style={{ display: 'none' }} onChange={(e:any)=>{if(e.target.files[0]!=null && e.target.files[0]!=undefined){setFrontPanCard(e.target.files[0]);removeOwnerDocuemnt("frontPanCard");}}}/> */}
                          {/* <input type="file" name='frontPanCard' id='frontPanCard' style={{ display: 'none' }} onChange={handleFrontPanCardChange}/> */}
                          {/* <!--begin::Message--> */}
                          <div className='dz-message needsclick'>
                            {/* <!--begin::Icon--> */}
                            <i className='ki-outline ki-file-up text-primary fs-3x'></i>
                            {/* <!--end::Icon--> */}
                            {/* <!--begin::Info--> */}
                            <div className='ms-4'>
                              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                Upload Front PAN Card
                              </h3>
                              <span className='fs-7 fw-semibold text-gray-500'>
                                Drag & Drop or choose files from computer
                              </span>
                            </div>
                            {/* <!--end::Info--> */}
                          </div>
                        </div>
                      </FileUploader>
                    </div>
                    <div className='text-danger fw-bold'>{ownerDocumentError[0].frontPanCard}</div>
                    <div className='row mt-3'>
                      {frontPanCard ? (
                        <div className=' mb-5'>
                          {frontPanCard.type === 'application/pdf' ? (
                            <div className=''>
                              <p className='fs-4'>
                                {' '}
                                <FontAwesomeIcon icon={faFile} className='mx-2' />
                                {frontPanCard.name}
                              </p>
                            </div>
                          ) : (
                            <div style={{maxWidth: 'fit-content', width: '200px', height: '200px'}}>
                              <img
                                src={URL.createObjectURL(frontPanCard)}
                                width='100%'
                                style={{width: '200px', height: '200px'}}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          {frontPan ? (
                            <div className='col-lg-4 col-3 d-flex flex-column mb-5'>
                              <div
                                style={{maxWidth: 'fit-content', width: '200px', height: '200px'}}
                              >
                                <img
                                  src={frontPan}
                                  width='100%'
                                  style={{width: '200px', height: '200px'}}
                                />
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='mt-5'>
                      <FileUploader
                        multiple={false}
                        handleChange={handleFrontAdharCardChange}
                        name='frontAdharCard'
                        types={fileTypes}
                        classes='w-100'
                      >
                        <div
                          className='dropzone dz-clickable'
                          id='kt_ecommerce_add_frontAadharCard'
                          onClick={() => handleDivClick('frontAadharCard')}
                        >
                          {/* <input type="file" name='frontAadharCard' id='frontAadharCard' style={{ display: 'none' }} onChange={(e:any)=>{if(e.target.files[0]!=null && e.target.files[0]!=undefined){setFrontAadharCard(e.target.files[0]);removeOwnerDocuemnt("frontAadharCard");}}}/> */}
                          {/* <input type="file" name='frontAadharCard' id='frontAadharCard' style={{ display: 'none' }} onChange={handleFrontAdharCardChange}/> */}
                          {/* <!--begin::Message--> */}
                          <div className='dz-message needsclick'>
                            {/* <!--begin::Icon--> */}
                            <i className='ki-outline ki-file-up text-primary fs-3x'></i>
                            {/* <!--end::Icon--> */}
                            {/* <!--begin::Info--> */}
                            <div className='ms-4'>
                              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                Upload Front Aadhar Card
                              </h3>
                              <span className='fs-7 fw-semibold text-gray-500'>
                                Drag & Drop or choose files from computer
                              </span>
                            </div>
                            {/* <!--end::Info--> */}
                          </div>
                        </div>
                      </FileUploader>
                    </div>
                    <div className='text-danger fw-bold'>
                      {ownerDocumentError[0].frontAadharCard}
                    </div>
                    <div className='row mt-3'>
                      {frontAadharCard ? (
                        <div className=' mb-5'>
                          {frontAadharCard.type === 'application/pdf' ? (
                            <div className=''>
                              <p className='fs-4'>
                                {' '}
                                <FontAwesomeIcon icon={faFile} className='mx-2' />
                                {frontAadharCard.name}
                              </p>
                            </div>
                          ) : (
                            <div style={{maxWidth: 'fit-content', width: '200px', height: '200px'}}>
                              <img
                                src={URL.createObjectURL(frontAadharCard)}
                                width='100%'
                                style={{width: '200px', height: '200px'}}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          {frontAadhar ? (
                            <div className='col-lg-4 col-3 d-flex flex-column mb-5'>
                              <div
                                style={{maxWidth: 'fit-content', width: '200px', height: '200px'}}
                              >
                                <img
                                  src={frontAadhar}
                                  width='100%'
                                  style={{width: '200px', height: '200px'}}
                                />
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='mt-5'>
                      <FileUploader
                        multiple={false}
                        handleChange={handleBackAdharCardChange}
                        name='backAdharCard'
                        types={fileTypes}
                        classes='w-100'
                      >
                        <div
                          className='dropzone dz-clickable'
                          id='kt_ecommerce_add_backAadharCard'
                          onClick={() => handleDivClick('backAadharCard')}
                        >
                          {/* <input type="file" name='backAadharCard' id='backAadharCard' style={{ display: 'none' }} onChange={(e:any)=>{if(e.target.files[0]!=null && e.target.files[0]!=undefined){setBackAadharCard(e.target.files[0]);removeOwnerDocuemnt("backAadharCard");}}}/> */}
                          {/* <input type="file" name='backAadharCard' id='backAadharCard' style={{ display: 'none' }} onChange={handleBackAdharCardChange}/> */}
                          {/* <!--begin::Message--> */}
                          <div className='dz-message needsclick'>
                            {/* <!--begin::Icon--> */}
                            <i className='ki-outline ki-file-up text-primary fs-3x'></i>
                            {/* <!--end::Icon--> */}
                            {/* <!--begin::Info--> */}
                            <div className='ms-4'>
                              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                Upload Back Aadhar Card
                              </h3>
                              <span className='fs-7 fw-semibold text-gray-500'>
                                Drag & Drop or choose files from computer
                              </span>
                            </div>
                            {/* <!--end::Info--> */}
                          </div>
                        </div>
                      </FileUploader>
                    </div>
                    <div className='text-danger fw-bold'>
                      {ownerDocumentError[0].backAadharCard}
                    </div>
                    <div className='row mt-3'>
                      {backAadharCard ? (
                        <div className='mb-5'>
                          {backAadharCard.type === 'application/pdf' ? (
                            <div className=''>
                              <p className='fs-4'>
                                {' '}
                                <FontAwesomeIcon icon={faFile} className='mx-2' />
                                {backAadharCard.name}
                              </p>
                            </div>
                          ) : (
                            <div style={{maxWidth: 'fit-content', width: '200px', height: '200px'}}>
                              <img
                                src={URL.createObjectURL(backAadharCard)}
                                width='100%'
                                style={{width: '200px', height: '200px'}}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          {backAadhar ? (
                            <div className='col-lg-4 col-3 d-flex flex-column mb-5'>
                              <div
                                style={{maxWidth: 'fit-content', width: '200px', height: '200px'}}
                              >
                                <img
                                  src={backAadhar}
                                  width='100%'
                                  style={{width: '200px', height: '200px'}}
                                />
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className='row mt-5'>
                  <div className='col-lg'>
                    <button
                      type='button'
                      className='btn btn-primary me-2 w-100'
                      onClick={ownerDocumentApi}
                    >
                      {!configLoading && <span className='indicator-label'>Save</span>}
                      {configLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-lg-8'></div>
                </div> */}
              </>
            </div>
          </div>
        </div>

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='button'
            id='kt_password_reset_submit'
            className='btn btn-primary me-4'
            onClick={() => ownerDocumentApi()}
          >
            {!configLoading && <span className='indicator-label'>Save</span>}
            {configLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>{' '}
        </div>
        {/* end::Form group */}
      </div>
    </>
  )
}
