/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocation, faLocationDot, faPhone, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import 'react-multi-carousel/lib/styles.css'
import '../../../_metronic/assets/style/style.css'
// import '../../../_metronic/assets/style/bankDetail.css'
import { stateFromHTML } from 'draft-js-import-html'
import { EditorState } from 'draft-js'
import { ToastContainer, toast } from 'react-toastify'
import { toastsuccessmsg } from '../../../reusable'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


export function InaltsBankDetail() {

    const location = useLocation();

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        // alert(`${text} copied to clipboard!`);
        toastsuccessmsg("copied to clipboard!")
    };

    // const handleDownload = () => {
    //     const link = document.createElement('a');
    //     link.href = "https://inalts.com/api/inalts/image/DepositSlip.pdf" ; 
    //     link.download = 'DepositSlip.pdf'; 
    //     link.click();
    // };

    const handleDownload = () => {
        window.open("https://inalts.com/api/inalts/image/DepositSlip.pdf", '_blank');
    };

    useEffect(() => {
        document.title = 'Inalts Bank Detail';
        const obj = (location.state ?? { ismessage: false, message: "" }) as { ismessage: boolean, message: string };

        if (obj.ismessage) {
            toastsuccessmsg(obj.message)
        }

    }, []);

    return (
        <>
            <ToastContainer />

            {/* <div className="containerBank">
                <h2>Bank Details</h2>
                <div className="required-label-bank">Inalts Finsolve Private Limited</div>

                <div className="info-row-bank">
                    <label>Bank name</label>
                    <span>Signature Bank</span>
                    <button className="copy-btn-bank" onClick={() => handleCopy('Signature Bank')} title="Copy">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M19,2H14.82A3,3,0,0,0,12,0H6A3,3,0,0,0,3,3V21a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM18,21H6V3H12v3H18Z" />
                        </svg>
                    </button>
                </div>

                <div className="info-row-bank">
                    <label>Bank account no.</label>
                    <span>150322XXXX</span>
                    <button className="copy-btn-bank" onClick={() => handleCopy('150322XXXX')} title="Copy">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M19,2H14.82A3,3,0,0,0,12,0H6A3,3,0,0,0,3,3V21a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM18,21H6V3H12v3H18Z" />
                        </svg>
                    </button>
                </div>

                <div className="info-row-bank">
                    <label>SWIFT Code</label>
                    <span>SIGNUSXXXX</span>
                    <button className="copy-btn-bank" onClick={() => handleCopy('SIGNUSXXXX')} title="Copy">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M19,2H14.82A3,3,0,0,0,12,0H6A3,3,0,0,0,3,3V21a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM18,21H6V3H12v3H18Z" />
                        </svg>
                    </button>
                </div>

                <div className="info-row-bank">
                    <label>Routing Number</label>
                    <span>02601XXXX</span>
                    <button className="copy-btn-bank" onClick={() => handleCopy('02601XXXX')} title="Copy">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M19,2H14.82A3,3,0,0,0,12,0H6A3,3,0,0,0,3,3V21a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM18,21H6V3H12v3H18Z" />
                        </svg>
                    </button>
                </div>
            </div> */}

            <div className='card shadow-sm p-0 rounded ' style={{
                // backgroundPosition: 'center center',
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')})`,
            }}>
                <div className='p-5'>
                    <div className='d-flex justify-content-between'>
                        <div className='fs-1 fw-bolder text-dark'>Bank Detail</div>
                        <div className=''>
                            <button className='btn btn-primary' onClick={handleDownload}>Download Pay Slip</button>
                        </div>
                    </div>
                    <div className='fs-3 fw-bolder text-gray-400'>{''}</div>
                </div>
                <div className='separator'></div>
                <div className='card-header border-0 pt-3'>
                    <div className='card-title m-0'>

                        <h3 className="text-gray-900">INALTS FINSOLVE PRIVATE LIMITED</h3>

                    </div>

                </div>

                <div className='card-body p-9'>
                    <div className='mt-5 fs-4 fw-bold d-flex' style={{ justifyContent: "space-between" }}>
                        <label className="text-gray-400">BANK NAME </label>
                        <div>
                            <span className='text-dark'> HDFC BANK </span>
                            <button className="copy-btn-bank" onClick={() => handleCopy('HDFC BANK')} title="Copy">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M19,2H14.82A3,3,0,0,0,12,0H6A3,3,0,0,0,3,3V21a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM18,21H6V3H12v3H18Z" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className='mt-5 fs-4 fw-bold d-flex' style={{ justifyContent: "space-between" }}>
                        <label className="text-gray-400">ACCOUNT TYPE </label>
                        <div>
                            <span className='text-dark'> Current / Checking Account</span>
                            <button className="copy-btn-bank" onClick={() => handleCopy('')} title="Copy">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M19,2H14.82A3,3,0,0,0,12,0H6A3,3,0,0,0,3,3V21a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM18,21H6V3H12v3H18Z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='mt-5 fs-4 fw-bold d-flex' style={{ justifyContent: "space-between" }}>
                        <label className="text-gray-400">AC NO. </label>
                        <div>
                            <span className='text-dark'> 50200095804272</span>
                            <button className="copy-btn-bank" onClick={() => handleCopy('50200095804272')} title="Copy">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M19,2H14.82A3,3,0,0,0,12,0H6A3,3,0,0,0,3,3V21a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM18,21H6V3H12v3H18Z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='mt-5 fs-4 fw-bold d-flex' style={{ justifyContent: "space-between" }}>
                        <label className="text-gray-400">IFSC Code </label>
                        <div>
                            <span className='text-dark'> HDFC0000102</span>
                            <button className="copy-btn-bank" onClick={() => handleCopy('HDFC0000102')} title="Copy">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M19,2H14.82A3,3,0,0,0,12,0H6A3,3,0,0,0,3,3V21a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM18,21H6V3H12v3H18Z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="formContact my-5" >
                    <div className="formContact-txt">
                        <h1>Bank Detail</h1>
                    </div>

                </div>
                <div>
                    <h3 className="text-gray-900">INALTS FINSOLVE PRIVATE LIMITED</h3>
                    
                    <div className='mt-10'>
                        <h4 className="text-gray-900">BANK NAME</h4>
                        <p> HDFC BANK</p>
                    </div>


                    <div className='mt-7'>
                        <h4>ACCOUNT TYPE</h4>
                        <p> Current / Checking Account</p>
                    </div>

                    <div className='mt-7'>
                        <h4>AC NO</h4>
                        <p>50200095804272</p>
                    </div>

                    <div className='mt-7'>
                        <h4>IFSC Code:</h4>
                        <p>50200095804272</p>
                    </div>
                </div> */}

        </>
    )
}