/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from './../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from './../core/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faArrowRightLong, faEye, faEyeSlash, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'
import { ToastContainer } from 'react-toastify'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import '../../../../_metronic/assets/style/style.css'
import { LayOut } from '../Home'
import NumberCounter from './NumberCounter'

function HomeContent() {

  useEffect(() => {
    document.title = 'Inalts Investor';
    loginapicall()
  }, []);
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState();
  const [password, setPassword] = useState()
  const [error, setError] = useState({ "email": "", "password": "" })
  const [datapost, setDatapost] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const [passwordDisplay, setPasswordDisplay] = useState(false)
  const [projects, setprojects] = useState([])
  const [contentList, setcontentList] = useState([])

  //  Refreance variable -------------
  const abouref = useRef(null)
  const projectsref = useRef(null)
  const whoweareref = useRef(null)

  // Crousel Responcive
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef: any = useRef(null);
  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
      carouselRef.current.goToSlide(currentSlide - 1);
    }
  };

  const handleNextSlide = () => {
    if (currentSlide < projects.length - 1) {
      setCurrentSlide(currentSlide + 1);
      carouselRef.current.goToSlide(currentSlide + 1);
    }
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const [totalInvestment, settotalInvestment] = useState(0);
  const [totalCompany, settotalCompany] = useState(0);


  const counterAPIcall = async () => {


    var response: any = await fetchData('desktopcount', {})

    if (response.status == "200") {

      console.log(response.data)
      settotalInvestment(parseInt(response.data.totalInvestment))
      settotalCompany(parseInt(response.data.investorCommunity))
    }
    else {
      console.log(response)
    }

  }

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    counterAPIcall()
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };


  }, []);

  function redirectdashboard() {

    navigate('../dashboard')
    document.body.removeAttribute('style');

  }

  const loginapicall = async () => {


    var response: any = await fetchData('viewproject', {})

    if (response.status == "200") {
      setprojects(response.data)
      console.log(response.data)

    }
    else {
      console.log(response)
    }

  }

  return (
    <>

      {/* ----------------------------------- Content Start ------------------------------------------- */}

      {/* -----------banner Start-------- */}
      <div className='col-12 px-3 mt-15'>
        <div className='row'>
          <div className='col-lg-6 my-auto'>
            <div className='line-under-image-text my-6'>
              <p className=''>Make Your Money</p>
              <p className='m-0'>Work With Max Invest</p>
              <img src={toAbsoluteUrl('/media/homeImg/Group.svg')} className='img-fluid line-image' />
            </div>
            <span style={{ color: '#979797', fontSize: "24px", fontWeight: 400 }}>We Help Transform investment into success stories through
              our innovative management stories through and
              cutting-edge analytics</span>
          </div>
          <img src={toAbsoluteUrl('/media/homeImg/bannerWitthouText.png')} className='col-lg-6' />
        </div>
      </div>
      {/* -----------banner End-------- */}

      <div className='col-12 my-10'>
        <h1 className="recruitment-profession-text recruitment-profession-text-mobile text-center text-md-left">
          <span>MAKE YOUR MONEY INVEST</span>
        </h1>
      </div>

      {/* --------------- Category View Start -------------- */}

      <div className='col-12'>
        <div className='row'>
          <div className='col text-center' >
            <div className='line-under-image-text mb-7'>
              <p className='m-0 about-section'>Our Best Investment Type</p>
              <img src={toAbsoluteUrl('/media/homeImg/Group.svg')} className='img-fluid line-image' />
            </div>
            <span className='' style={{ color: '#979797', fontSize: "24px", fontWeight: 400, textAlign: 'justify' }}>At INALTS, we invest in opportunities like Bill Discounting, Movies, and Real
              Estate to maximize value.<br/>Each investment is carefully selected for growth, reliability, and profitability</span>
          </div>
        </div>

        <div className='row mt-10'>
          <div className='col-6 mx-auto row p-10' style={{ width: '610px', backgroundColor: '#EDEEF7', borderRadius: '33px' }}>
            <div className='col-6 d-grid ' style={{ alignContent: "space-between" }}>
              <h1>Bill Discount</h1>
              <h1 className='invest-redirect' onClick={() => redirectdashboard()}>Invest
                <FontAwesomeIcon icon={faArrowRightLong} className='mx-3 fs-1' />
              </h1>
            </div>
            <div className='col-6'>
              <img src={toAbsoluteUrl('/media/homeImg/homeBuildingSideImg.png')} className='img-fluid line-image' />
            </div>
          </div>
        </div>

        <div className='mt-15 p-4'>
          <div className='row p-4' style={{ backgroundColor: '#F3F3F3', borderRadius: '33px' }}>
            <div className='col-lg p-4'>
              <div className='' style={{ fontSize: "48px", fontWeight: 800, lineHeight: 1.2 }}>
                <p className='m-0 mb-10 whoWeAre-section'>Our Approach</p>
                <div className="apporoach-content overflow-scroll" >
                  <div>
                    <div className='mb-7 apporach-number-div'>
                      <p className='m-0'>01</p>
                    </div>
                    <div className='mb-7 apporach-number-title'>
                      <p className='m-0'>Risk-Mitigation & High-Yield</p>
                    </div>
                    <div className='mb-5 apporach-number-text'>
                      <p className='m-0'>Inalts creates a risk-mitigated, high-yield environment by offering curated alternative investments backed by thorough due diligence, expert oversight, and transparent processes, empowering secure growth.</p>
                    </div>
                  </div>

                  <div>
                    <div className='mb-7 apporach-number-div'>
                      <p className='m-0'>02</p>
                    </div>
                    <div className='mb-7 apporach-number-title'>
                      <p className='m-0'>Customer First</p>
                    </div>
                    <div className='mb-5 apporach-number-text'>
                      <p className='m-0'>Our customer-first policy ensures personalized support, transparent communication, and tailored investment solutions designed to meet individual financial goals and needs.</p>
                    </div>
                  </div>

                  <div>
                    <div className='mb-7 apporach-number-div'>
                      <p className='m-0'>03</p>
                    </div>
                    <div className='mb-7 apporach-number-title'>
                      <p className='m-0'>Transparency</p>
                    </div>
                    <div className='mb-5 apporach-number-text' >
                      <p className='m-0'>Provide clear, upfront details about fees, returns, and risks, building trust through honest communication.</p>
                    </div>
                  </div>

                  <div>
                    <div className='mb-7 apporach-number-div'>
                      <p className='m-0'>04</p>
                    </div>
                    <div className='mb-7 apporach-number-title'>
                      <p className='m-0'>Community Engagement</p>
                    </div>
                    <div className='mb-5 apporach-number-text'>
                      <p className='m-0'> Foster a sense of belonging by creating a community space for investors to discuss trends, share experiences, and learn from each other.</p>
                    </div>
                  </div>

                  <div>
                    <div className='mb-7 apporach-number-div'>
                      <p className='m-0'>05</p>
                    </div>
                    <div className='mb-7 apporach-number-title'>
                      <p className='m-0'>Seamless Experience</p>
                    </div>
                    <div className='mb-5 apporach-number-text'>
                      <p className='m-0'>Ensure a smooth, intuitive user journey with a user-friendly interface, enabling quick registration, easy investment tracking, and 24/7 support.</p>
                    </div>
                  </div>

                  <div>
                    <div className='mb-7 apporach-number-div'>
                      <p className='m-0'>06</p>
                    </div>
                    <div className='mb-7 apporach-number-title'>
                      <p className='m-0'>Value Creation</p>
                    </div>
                    <div className='mb-5 apporach-number-text'>
                      <p className='m-0'> Focus on delivering value by showcasing curated, high-potential alternative investment products, helping users diversify their portfolios and achieve financial growth.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 text-center p-0'>
              <img src={toAbsoluteUrl('/media/homeImg/apporchImg.png')} className='w-100' />
            </div>
          </div>
        </div>
      </div>

      {/* --------------- Category View END -------------- */}

      {/* ------------- Count Reset ------------ */}
      <div className='col-12 my-15'>
        <div className='row'>
          <div className='col text-center line-under-image-text' style={{ lineHeight: '0.7' }}>
            <p>In Our Journey</p>
            <p>We Have Delivered</p>
            <img src={toAbsoluteUrl('/media/homeImg/Group.svg')} className='img-fluid line-image' />
          </div>
        </div>
        <div className='col-12 row my-15 line-under-image-text'>
          <div className='col-sm row'>
            <div className='col-sm text-center borderRight'>
              <p className='mb-0'>₹<NumberCounter endValue={totalInvestment} duration={2} />+</p>
              <div className='my-8 couter-text'>Total Investment</div>
            </div>

            <div className='col-sm text-center borderRight'>
              <p className='mb-0'><NumberCounter endValue={totalCompany} duration={2} />+</p>
              <div className='my-8 couter-text'>Investor Community</div>
            </div>
          </div>
          <div className='col-sm row'>

            <div className='col-sm text-center borderRight'>
              <p className='mb-0'>12.5%</p>
              <div className='my-8 couter-text'>Avg Returns Earned</div>
            </div>
            <div className='col-sm text-center' >
              <p className='mb-0'>0.40%</p>
              <div className='my-8 couter-text'>Default Percentage</div>
            </div>
          </div>

        </div>
      </div>
      {/* ------------- Count Reset ------------ */}


      {/* ---------------- Much More Content Start ----------- */}

      <div className='col-12'>
        <div className='row text-center' >
          <div className='line-under-image-text'>
            <p className='m-0'>We’re Much More</p>
            <img src={toAbsoluteUrl('/media/homeImg/Group.svg')} className='img-fluid line-image' />
          </div>
        </div>
        <div className='row my-10 '>
          <div className='col-lg-4 position-relative text-center'>
            <img src={toAbsoluteUrl('/media/homeImg/imageBlue.png')} className='img-much-more' />
            <button className='position-relative py-10 btn mx-auto rounded-pill btn-more-content' >Quality Leader Industry</button>
          </div>
          <div className='col-lg-4 position-relative text-center'>
            <img src={toAbsoluteUrl('/media/homeImg/imagePurple.png')} className='img-much-more' />
            <button className='position-relative btn mx-auto rounded-pill btn-more-content'>Transparent Pricing</button>
          </div>
          <div className='col-lg-4 position-relative text-center'>
            <img src={toAbsoluteUrl('/media/homeImg/imageSupport.png')} className='img-much-more' />
            <button className='position-relative btn mx-auto rounded-pill btn-more-content'>Customer Support</button>
          </div>
        </div>



      </div>

      <div className='col-12 my-10'>
        <h1 className="recruitment-profession-text text-center text-md-left">
          <span>HURRY UP, INVEST NOW</span>
        </h1>
      </div>

      {/* ---------------- Much More Content End ----------- */}

      <div className='col-12 text-center line-under-image-text'>
        <div className='m-0'>Our Latest Projects</div>
        <img src={toAbsoluteUrl('/media/homeImg/Group.svg')} width={'276px'} />
      </div>

      {screenSize > 992 ?
        (
          <div className="row my-15">
            <div className='col-1 row'>
              <button className="btnCrouselNew mx-auto" style={{ marginTop: "150px" }} onClick={handlePrevSlide}><FontAwesomeIcon icon={faArrowLeft} /></button>
            </div>
            <div className="carousel-container col-10">
              <Carousel responsive={responsive} ref={carouselRef} arrows={false} afterChange={(previousSlide, { currentSlide }) => {
                setCurrentSlide(currentSlide);
              }} additionalTransfrom={currentSlide} className=' ' >
                {projects.map((data: any) => {

                  var roi;
                  var irr;


                  if (data['payout'] == "FIXED_AMOUNT") {
                    if (data['payoutDetail'].length != 0) {
                      roi = data['payoutDetail'].length != 0 ? '₹ ' + data['payoutDetail'][0]['roiAmount'] : '₹ 0'
                      irr = data['payoutDetail'].length != 0 ? '₹ ' + data['payoutDetail'][0]['irrAmount'] : '₹ 0'
                    } else {
                      roi = data['irrPercentage'] ? data['irrPercentage'] + '%' : '0%'
                      irr = data['roiPercentage'] ? data['roiPercentage'] + '%' : '0%'
                    }
                  } else if (data['payout'] == "PERCENTAGE") {
                    if (data['payoutDetail'].length != 0) {
                      roi = data['payoutDetail'].length != 0 ? data['payoutDetail'][0]['roiPercentage'] + '%' : '0%'
                      irr = data['payoutDetail'].length != 0 ? data['payoutDetail'][0]['irrPercentage'] + '%' : '0%'
                    } else {
                      roi = data['irrPercentage'] ? data['irrPercentage'] + '%' : '0%'
                      irr = data['roiPercentage'] ? data['roiPercentage'] + '%' : '0%'
                    }
                  } else if (data['payout'] == "VARIABLE") {
                    if (data['payoutDetail'].length != 0) {

                      for (let i = 0; i < data['payoutDetail'].length; i++) {
                        roi = 0;
                        irr = 0;
                        for (let i = 0; i < data['payoutDetail'].length; i++) {
                          roi += parseFloat(data['payoutDetail'][i]['roiPercentage']);
                          irr += parseFloat(data['payoutDetail'][i]['irrPercentage']);
                        }
                        roi = roi + '%';
                        irr = irr + '%';
                      }
                    } else {
                      roi = data['irrPercentage'] ? data['irrPercentage'] + '%' : '0%'
                      irr = data['roiPercentage'] ? data['roiPercentage'] + '%' : '0%'
                    }
                  }

                  return (
                    <>
                      {/* <div className='card card-sm-stretch me-md-6 py-3'> */}
                      <div className='card d-flex flex-center flex-column p-7  mx-2 row home-card'  >
                        <div className='col-12 mb-5 row'>
                          <div className='col-4 symbol symbol-75px me-3 my-auto'>
                            <div className='bg-light ' style={{borderRadius:'50%',height:'90px',width:'90px'}}>
                              <img
                                src={data.profilePicture}
                                alt='Card6'
                                className='p-2 img-fluid'
                                style={{ height: '90px', width: '90px', borderRadius:'50%' }}
                              />
                            </div>
                          </div>
                          <div className='col my-auto'>
                            <div>
                              <Link to='/projectdetails' state={{ projectId: data['_id'] }} className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                                {data['name'].length >= 30 ? data['name'].slice(0, 20) + '...' : data['name']}
                              </Link>
                            </div>
                            <div
                              className='h-4px w-100 bg-light mb-10'
                              data-bs-toggle='tooltip'

                              title='This project completed'
                            >
                              <div
                                className={` rounded h-4px`}
                                role='progressbar'
                                style={{ backgroundColor: '#050E24', width: `${(parseFloat(data['totalProjectAmount']) / parseFloat(data['investmentOppurtunitySize'])) * 100}%` }}
                              ></div>
                              <div className='mt-2 d-flex'>
                                <p className='link-redirect fw-bold fs-6 mb-0 mt-1 text-left'>
                                  ₹{parseInt(data['investmentOppurtunitySize']).toLocaleString('en-IN')}{' '}
                                </p>
                                <p className='fw-bold fs-6 mt-1 text-left grey-text-all' style={{ marginLeft: '5px' }}>
                                  {' '}
                                  {"(₹" + (parseInt(data['investmentOppurtunitySize']) - parseInt(data['totalProjectAmount'])) + " Pending)" }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <Link to='/projectdetails' state={{ projectId: data['_id'] }} className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                              {data['name'].length >= 30 ? data['name'].slice(0, 20) + '...' : data['name']}
                            </Link>

                            <div className='fw-bold text-gray-400 mb-6'>{data['projectCode'] == "" || data['projectCode'] == null || data['projectCode'] == undefined ? "0000" : data['projectCode']}</div> */}

                        <div className='d-flex  flex-wrap'>
                          <div className='dash-border-line rounded min-w-125px py-3 px-4 mx-3 mb-3'>
                            <div className='fs-6 fw-bolder grey-text-all'>Tenure</div>
                            <div className='fw-bold link-redirect'>{data["exitPeriod"] + " - " + data["exitPeriodType"]}</div>
                          </div>

                          <div className='dash-border-line rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                            <div className='fs-6 fw-bolder grey-text-all'>Interest Rate</div>
                            <div className='fw-bold link-redirect'>{data['interestRate']} %</div>
                          </div>
                        </div>

                        <div className='d-flex flex-wrap mb-5'>
                          <div className='dash-border-line rounded min-w-125px py-3 px-4 mx-3 mb-3'>
                            <div className='fs-6 fw-bolder grey-text-all'>Risk Rating</div>
                            <div className='fw-bold link-redirect'>{data['riskRating']}</div>
                          </div>

                          <div className='dash-border-line rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                            <div className='fs-6 fw-bolder grey-text-all'>Min Amount</div>
                            <div className='fw-bold link-redirect'> ₹{data['minAmount']}</div>
                          </div>
                        </div>
                        <div className='pt-5 row' >
                          <div className='col-12'>
                            <Link
                              to='../projectdetails/'
                              state={{ projectId: data['_id'] }}
                              className='fw-bold text-primary fs-1 link-redirect'
                            >
                              Invest  <FontAwesomeIcon icon={faArrowRight} />
                            </Link>
                          </div>
                        </div>
                      </div>

                    </>
                  )
                })}
              </Carousel>
            </div>
            <div className='col-1 row'>
              <button className="btnCrouselNew mx-auto" style={{ marginTop: "150px" }} onClick={handleNextSlide}><FontAwesomeIcon icon={faArrowRight} /></button>
            </div>
          </div>
        ) : (
          <div className="carousel-container">
            <Carousel responsive={responsive} ref={carouselRef} arrows={false} afterChange={(previousSlide, { currentSlide }) => {
              setCurrentSlide(currentSlide);
            }} additionalTransfrom={currentSlide} className=' ' >
              {projects.map((data: any) => {

                var roi;
                var irr;


                if (data['payout'] == "FIXED_AMOUNT") {
                  if (data['payoutDetail'].length != 0) {
                    roi = data['payoutDetail'].length != 0 ? '₹ ' + data['payoutDetail'][0]['roiAmount'] : '₹ 0'
                    irr = data['payoutDetail'].length != 0 ? '₹ ' + data['payoutDetail'][0]['irrAmount'] : '₹ 0'
                  } else {
                    roi = data['irrPercentage'] ? data['irrPercentage'] + '%' : '0%'
                    irr = data['roiPercentage'] ? data['roiPercentage'] + '%' : '0%'
                  }
                } else if (data['payout'] == "PERCENTAGE") {
                  if (data['payoutDetail'].length != 0) {
                    roi = data['payoutDetail'].length != 0 ? data['payoutDetail'][0]['roiPercentage'] + '%' : '0%'
                    irr = data['payoutDetail'].length != 0 ? data['payoutDetail'][0]['irrPercentage'] + '%' : '0%'
                  } else {
                    roi = data['irrPercentage'] ? data['irrPercentage'] + '%' : '0%'
                    irr = data['roiPercentage'] ? data['roiPercentage'] + '%' : '0%'
                  }
                } else if (data['payout'] == "VARIABLE") {
                  if (data['payoutDetail'].length != 0) {

                    for (let i = 0; i < data['payoutDetail'].length; i++) {
                      roi = 0;
                      irr = 0;
                      for (let i = 0; i < data['payoutDetail'].length; i++) {
                        roi += parseFloat(data['payoutDetail'][i]['roiPercentage']);
                        irr += parseFloat(data['payoutDetail'][i]['irrPercentage']);
                      }
                      roi = roi + '%';
                      irr = irr + '%';
                    }
                  } else {
                    roi = data['irrPercentage'] ? data['irrPercentage'] + '%' : '0%'
                    irr = data['roiPercentage'] ? data['roiPercentage'] + '%' : '0%'
                  }
                }

                return (
                  <>
                    {/* <div className='card card-sm-stretch me-md-6 py-3'> */}
                    <div className='card d-flex flex-center flex-column p-9  mx-2'>
                      <div className='mb-5'>
                        <div className='symbol symbol-75px '>
                          <img alt='Pic' src={data.profilePicture} />
                        </div>
                      </div>

                      <Link to='/projectdetails' state={{ projectId: data['_id'] }} className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                        {data['name'].length >= 30 ? data['name'].slice(0, 20) + '...' : data['name']}
                      </Link>

                      <div className='fw-bold text-gray-400 mb-6'>{data['projectCode'] == "" || data['projectCode'] == null || data['projectCode'] == undefined ? "0000" : data['projectCode']}</div>

                      <div className='d-flex flex-center flex-wrap mb-5'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
                          <div className='fs-6 fw-bolder text-gray-700'>Tenure</div>
                          <div className='fw-bold text-gray-400'>{data["exitPeriod"] + " - " + data["exitPeriodType"]}</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                          <div className='fs-6 fw-bolder text-gray-700'>Interest Rate</div>
                          <div className='fw-bold text-gray-400'>{data['interestRate']} %</div>
                        </div>
                      </div>


                    </div>

                  </>
                )
              })}
            </Carousel>
          </div>
        )

      }

      {/* ----------------------------------- Content End ------------------------------------------- */}

    </>
  )
}

export default function Home() {
  return <LayOut data={<HomeContent />} ></LayOut>;
}