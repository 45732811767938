/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTCard, KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ListViewProvider, useListView } from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import { Link } from 'react-router-dom'
import { dateFormat, fetchData, toasterrormsg } from '../../../../reusable'
import { PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'


export function ViewSupport() {
  const { itemIdForUpdate } = useListView()

  useEffect(() => {
    document.title = 'Investor | Help And Support'
  }, [])
  var vendorId = ''
  const vendorData = sessionStorage.getItem('vendor')
  if (vendorData) {
    var vendor = JSON.parse(vendorData)
    vendorId = vendor._id
  } else {
    console.log('No vendor data found')
  }

  const [offset, setoffset] = useState(0)
  const [limit, setlimit] = useState(10)
  const [page, setpage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [outdata, setoutdata] = useState(0)
  const [from, setfrom] = useState(1)
  const [to, setTo] = useState(1)
  const [pageNumbersList, setPageNumberList] = useState([1])
  const [totalData, setTotalData] = useState(0)

  const [projectDetails, setProjectDetails] = useState([])
  const [projectDetailsList, setProjectDetailsList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [showPopup, setShowPopup] = useState(false)

  const [frontAadharCard, setfrontAadharCard] = useState('')
  const [backAadharCard, setbackAadharCard] = useState('')
  const [investorId, setinvestorId] = useState('')
  const [panCard, setpanCard] = useState('')

  const Suburl = 'https://inalts.com/api/inalts/image/company/'
  // page number generater
  function generatePageNumbers(currentPage: any, totalPages: any, maxPagesToShow: any) {
    const halfMaxPages = Math.floor(maxPagesToShow / 2)
    let startPage = Math.max(1, currentPage - halfMaxPages)
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1)

    // Adjust startPage and endPage if the total pages to show is less than maxPagesToShow
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1)
    }

    // Generate the list of page numbers
    const pageNumbers = []
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }

    return pageNumbers
  }
  // on page change function
  const onpagecahnge = (pageId: any, data: any = [], fromSearch: any = false, limit: any = 10) => {

    var newdata: any = []
    if (fromSearch == true) {
      newdata = [...data];
    }
    else {
      newdata = [...projectDetails]
      console.log("newdata.length", newdata.length);
    }
    console.log("data", data);

    setTotalData(newdata.length)
    if ((pageId != page && pageId != 0 && pageId <= totalPage) || data.length > 0) {
      // console.log(pageId);
      var tmp = (pageId - 1) * limit
      setpage(pageId)
      setoffset(tmp)
      setfrom(tmp + 1)
      // setto
      var mod = newdata.length % limit
      var tolimi: any = limit
      if (mod > 0) {
        if (mod <= limit) {
          tolimi = pageId * limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
        else {
          tolimi = pageId * limit + limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
      }
      else {
        tolimi = pageId * limit
      }
      setTo(tolimi)
      var tmpdata: any = newdata.slice(tmp, tolimi)
      setProjectDetailsList(tmpdata)
      // console.log("tmp offset",tmp);
    }
    else if (data.length == 0) {
      setTo(0)
      setfrom(0)

    }
    var totpag = (Math.ceil(newdata.length / limit))
    setTotalPage(totpag)
    // setProjectDetailsList(data)
    // onpagecahnge(1,data,true)
    setpage(pageId)
    var genpag = generatePageNumbers(1, totpag, 6)
    setPageNumberList(genpag)

  }
  const projectsearch = async (text: any) => {

    if (text == "" || text == null || text == undefined) {
      // setProjectDetailsList(projectDetails)
      console.log(projectDetails);
      var tmp: any = projectDetails.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(projectDetails.length / limit))
      setTotalPage(totpag)
      setProjectDetailsList(projectDetails)
      onpagecahnge(1, projectDetails, true)
      setpage(1)
      var genpag = generatePageNumbers(1, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      var tmp: any = projectDetails.filter((item: any) => {
        return (
          (item.roleName != null &&
            item.roleName != '' &&
            item.roleName.toLowerCase().includes(text.toLowerCase()))
        )
      })


      var totpag = (Math.ceil(tmp.length / limit))
      setTotalPage(totpag)
      setProjectDetailsList(tmp)
      onpagecahnge(1, tmp, true)
      setpage(1)
      var genpag = generatePageNumbers(1, totpag, 6)
      setPageNumberList(genpag)
    }
  }

  // profile Details api call
  const profileDetailsApiCall = async (statusFilter:any= "Active") => {
    if(vendorId){

      var param: any = {
        investorId: vendorId,
        status: statusFilter != "Active" ? statusFilter : '',
      }
      var response: any = await fetchData('supportList', param)
  
      if (response.status == '200') {
        setProjectDetails(response.data)
  
        var res = response.data
        setTotalData(res.length)
        var tmp: any = res.slice(offset, offset + limit)
  
        setProjectDetailsList(tmp)
  
        var totpag = Math.ceil(res.length / limit)
        setTotalPage(totpag)
        if (totpag == 1) {
          var mod = res.length % limit
          if (mod > 0) {
            setTo(mod)
          } else {
            setTo(tmp.length)
          }
        } else {
          var mod = res.length % limit
  
          if (mod > 0) {
            if (mod <= limit) {
              setTo(page * limit)
            } else {
              setTo(page * limit + limit)
            }
          } else {
            setTo(page * limit)
          }
        }
  
        var genpag = generatePageNumbers(page, totpag, 6)
        setPageNumberList(genpag)
      } else {
        toasterrormsg(response.message)
      }
    }
  }

  const intl = useIntl()
  useEffect(() => {
    profileDetailsApiCall()
  }, [])

  return (
    <>
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Help And Support' })}</PageTitle>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>

          </h3>

          <div className='d-flex flex-wrap my-2'>
          <div className='mx-1'>
              <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-sm form-select-white w-125px'
                  defaultValue='Active'
                  onChange={(e) => {
                    profileDetailsApiCall(e.target.value)
                  }}
                >
                  <option value='Active'>All</option>
                  <option value='1'>Solved</option>
                  <option value='0'>Pending</option>
                 
                </select>
            </div>
            <Link to='../addsupport/' className='btn btn-primary btn-sm'>
              Add Support{' '}
            </Link>

          </div>

        </div>

        <div className='row g-6 g-xl-9'>


          {projectDetailsList.map((data: any) => {


            return (
              <div className=''>
                <div className={`card shadow-sm `}>
                  {/* begin::Body */}
                  <div className='card-body pb-0'>
                    {/* begin::Header */}
                    <div className='d-flex align-items-center mb-5'>
                      {/* begin::User */}
                      <div className='d-flex align-items-center flex-grow-1'>

                        {/* begin::Info */}
                        <div className='d-flex flex-column'>
                          <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                            {data.subject}
                          </a>

                          <span className='text-gray-400 fw-semibold'>Date : {dateFormat(data.created, "DD-MM-YYYY")}</span>
                        </div>

                        {/* end::Info */}
                      </div>
                      <div className='my-0'>

                        <span className={data.status == 0 ? 'text-warning fw-semibold' : 'text-success fw-semibold'}>{data.status == 0 ? 'Pending' : 'Solved'}</span>
                      </div>
                      {/* end::User */}

                    </div>
                    {/* end::Header */}

                    {/* begin::Post */}
                    <div className='mb-5'>

                      {/* <div
                        className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5'
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/20.jpg')}')`,
                        }}
                      ></div> */}
                      {/* end::Image */}

                      {/* begin::Text */}
                      <div dangerouslySetInnerHTML={{ __html: data.description }}>
                        {/* {data.description} */}
                      </div>

                    </div>
                    {/* end::Post */}

                    {/* begin::Separator */}
                    {/* <div className='separator mb-4'></div> */}
                      {/* ------ For Admin view user ------ */}
                    {/* <div className='d-flex align-items-center mb-5'>
                     
                      <div className='d-flex align-items-center flex-grow-1'>
                       
                        <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl('/media/avatars/300-25.jpg')} alt='' />
                        </div>
                        
                        <div className='d-flex flex-column'>
                          <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                            Sam Logan
                          </span>
                        </div>
                       
                      </div>
                    
                    </div> */}
                  </div>
                  {/* end::Body */}
                </div>
              </div>
            )
          })}

        </div>

        <div className='d-flex flex-stack flex-wrap pt-10'>
          <div className='row'>
            <select
              className='form-select form-select-solid fw-bolder w-50'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => {
                setlimit(parseInt(e.target.value));
                onpagecahnge(page, projectDetailsList, false, parseInt(e.target.value));
              }}

            >
              <option value='10'>10</option>
              <option value='30'>30</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
            <div className='fs-6 fw-bold text-gray-700'>
              Showing {from} to {to} of {totalData} entries
            </div>

          </div>

          <ul className='pagination'>
            <li className={`page-item previous ${page > 1 ? '' : 'disabled'}`}>
              <span
                className={`page-link ${page > 1 ? 'cursor-pointer' : 'disabled'}`}
                onClick={() => onpagecahnge(page - 1)}
              >
                <i className='previous'></i>
              </span>
            </li>
            {pageNumbersList.map((item: any) => {
              return (
                <li className={`page-item cursor-pointer ${page == item ? 'active' : ''}`}>
                  <span className='page-link' onClick={() => onpagecahnge(item)}>
                    {item}
                  </span>
                </li>
              )
            })}

            <li className={`page-item next ${page + 1 <= totalPage ? '' : 'disabled'}`}>
              <span
                className={`page-link ${page + 1 <= totalPage ? 'cursor-pointer' : 'disabled'}`}
                onClick={() => (page + 1 <= totalPage ? onpagecahnge(page + 1) : '')}
              >
                <i className='next'></i>
              </span>
            </li>
          </ul>
        </div>
      </>
    </>
  )
}

