import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'
import { ToastContainer } from 'react-toastify'

export function OtpVerification() {

  useEffect(() => {
    document.title = 'Investor | OTP Verification';
  }, []);
  const navigate=useNavigate()
  const [loading, setLoading] = useState(false)

  const [code_1, setCode_1] = useState('');
  const [code_2, setCode_2] = useState('');
  const [code_3, setCode_3] = useState('');
  const [code_4, setCode_4] = useState('');
  const otp = `${code_1}${code_2}${code_3}${code_4}`;

  // Function to handle the input change for each code input
  const handleInputChange = (e:any, setterFunction:any) => {
    const value = e.target.value;
    const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward';

    // Ensure the value is a single digit and update the state
    if (/^\d*$/.test(value) && value.length <= 1) {
        setterFunction(value);
        // Focus on the next input after setting the value
        if (isBackspace && value === '') {
            const currentInput = e.target;
            if (currentInput.previousSibling) {
                currentInput.previousSibling.focus();
            }
        } else { // Focus on the next input after setting the value
            const currentInput = e.target;
            if (currentInput.nextSibling && value !== '') {
                currentInput.nextSibling.focus();
            }
        }
    }
  };

  const handleKeyPress = (e:any) => {
      const keyCode = e.which || e.keyCode;
  
      if (keyCode < 48 || keyCode > 57) {
        e.preventDefault();
      }
  };

  // otp verification api call
  const otpverificaitonapicall=async()=>{
    var param:any={
      otp:otp
    }
    var response:any=await fetchData("verifyOTP",param)
    
    if(response.status=="200")
    {
      
      localStorage.setItem("vendorId",response.data[0]['_id'])
      console.log(response.data);
      
      toastsuccessmsg("OTP Verify Successfully")

      setTimeout(() => {
        navigate("../reset-password");
      }, 1500);
      
    }
    else
    {
      // setSubmitting(false)
      setLoading(false)
      toasterrormsg(response.message)
    }
  }
  const [timer, setTimer] = useState(40); // Initial timer value in seconds
  
   useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer === 0) {
          clearInterval(countdown);
          // Handle timeout logic here (e.g., show option to resend OTP)
          return 0; // Return 0 to stop the timer
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);

    // Cleanup function to clear the interval when component unmounts or timer reaches 0
    return () => clearInterval(countdown);
  }, []);

  var email = sessionStorage.getItem('autherEmail') || "";
  const resendOTPApiCall=async()=>{
    console.log(email);
    
    var param:any={
      email:email
    }
    var response:any=await fetchData("forgetPassword",param)
    
    if(response.status=="200")
    {
      sessionStorage.setItem('auther',response.data[0]['vendorId']);
      localStorage.setItem("vendorId",response.data[0]['vendorId']);
      sessionStorage.setItem('autherEmail',email);
      setTimer(10)
      const countdown = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === 0) {
            clearInterval(countdown);
            // Handle timeout logic here (e.g., show option to resend OTP)
            return 0; // Return 0 to stop the timer
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
  
      // Cleanup function to clear the interval when component unmounts or timer reaches 0
      console.log(response.data);
      
      toastsuccessmsg("OTP Send Successfully")
      return () => clearInterval(countdown);
      
    }
    else
    {
      setLoading(false)
      toasterrormsg(response.message)
    }
  }
  if(timer === 0){
    sessionStorage.removeItem('auther');
    sessionStorage.removeItem('autherEmail');
  }
  useEffect(() => {
    var auther = sessionStorage.getItem('auther');
    if(auther == "" || auther == null || auther == undefined){
      navigate('../forgot-password');
    }
  }, [])
  
  return (
    <>
      <ToastContainer/>
      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        id='kt_login_password_reset_form'
      >
        <div className='text-start my-15'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Verify OTP</h1>
          {/* end::Title */}
        </div>

        {/* begin::Form group */}
        <div className='row mb-50'>
          <label className="col-lg-12 col-form-label required fw-bold fs-6">Enter OTP</label>
          <div className="col-lg-12 fv-row h-200px">
            {/* <!--begin::Input group--> */}
              <div className="d-flex flex-wrap flex-stack justify-content-around">
                  <input type="text" name="code_1" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_1} onChange={(e) => {setCode_1(e.target.value);handleInputChange(e, setCode_1)}} onKeyPress={handleKeyPress}/>
                  <input type="text" name="code_2" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_2} onChange={(e) => {setCode_2(e.target.value);handleInputChange(e, setCode_2)}} onKeyPress={handleKeyPress}/>
                  <input type="text" name="code_3" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_3} onChange={(e) => {setCode_3(e.target.value);handleInputChange(e, setCode_3)}} onKeyPress={handleKeyPress}/>
                  <input type="text" name="code_4" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_4} onChange={(e) => {setCode_4(e.target.value);handleInputChange(e, setCode_4)}} onKeyPress={handleKeyPress}/>
              </div>
            {/* <!--begin::Input group--> */}
            <div className='mt-5 text-dark fw-bold'>
              {timer === 0 ? (
                <>
                  Didn’t get OTP?<Link to='#' onClick={resendOTPApiCall}> Resend OTP</Link>
                </>
              ) : (
                <p>Resend OTP in {timer} seconds</p>
              )}
            </div>
          </div>
          <div className="col-lg-12">
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button type='button' id='kt_password_reset_submit' className='btn btn-primary me-4' onClick={otpverificaitonapicall}>
                <span className='indicator-label'>Verify OTP</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {' '}
            </div>
        {/* end::Form group */}
      </div>
    </>
  )
}
