import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { fetchData } from '../../../reusable';
import { Link } from 'react-router-dom';
import { TablesWidgetDemo } from '../../../_metronic/partials/widgets/tables/TablesWidgetDemo';
import { TablesWidget1Demo } from '../../../_metronic/partials/widgets/tables/TablesWidget1Demo';
import { TablesWidgetDemoShort } from '../../../_metronic/partials/widgets/tables/TablesWidgetDemoShort';
import { TablesWidgetDemoNews } from '../../../_metronic/partials/widgets/tables/TablesWidgetDemoNews';
import { StatisticsWidget7 } from '../../../_metronic/partials/widgets/statistics/StatisticsWidget7';

const DashboardPage = () => {
  useEffect(() => {
    document.title = 'Investor | Dashboard';
    profileDetailsApiCall();
    investMentDeals()
    shortListProject()
    news()
  }, [])

  var vendor: any = sessionStorage.getItem('vendor')
  vendor = JSON.parse(vendor)
  
  // console.log("=========",vendor['investorBankDetail']);
  

  const [totalProjects, settotalProjects] = useState(0)
  const [invested, setinvested] = useState([])
  const [Oppurtunity, setOppurtunity] = useState([])
  const [shortProjects, setshortProjects] = useState([])
  const [newsList, setnewsList] = useState([])

  // const [categoryFilter, setcategoryFilter] = useState("")

  const profileDetailsApiCall = async (categoryFilter?:any) => {
    
    var param: any = {
      // filter: filter,
      categoryId: categoryFilter,
    }
    var response: any = await fetchData('viewproject', param)
    if(response.status == "200"){
      settotalProjects(response.data.length)
      setOppurtunity(response.data)
    }
  }

  const investMentDeals = async () => {

    let condition = {
      investorId: vendor._id
    }
    var response: any = await fetchData('investmentdetail', condition)
    if(response.status == "200"){
      setinvested(response.data)
    }
  }

  const shortListProject = async () => {

    let condition = {
      investorId: vendor._id
    }
    var response: any = await fetchData('projectSortList', condition)
    if(response.status == "200"){
      setshortProjects(response.data)
    }
  }

  
  const news = async () => {

    let condition = {
      // investorId: vendor._id
    }
    var response: any = await fetchData('viewnews', condition)
    if(response.status == "200"){
      setnewsList(response.data)
    }
  }

  const onclick = (data:any) => {
    profileDetailsApiCall(data)
  }

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8 '>
       
       {vendor && vendor['investorBankDetail'].length == 0 && <Link
        to={"/crafted/pages/profilemanagement/editprofile"}
          className='dropzone dz-clickable'
          
        >
         
          <div className='dz-message needsclick'>
            
            <div className='ms-4'>
              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
               Your Bank Detail is Pending. Please Add Bank Details!
              </h3>
              
            </div>
           
          </div>
        </Link>}
        <div className='col-xl-4 '>
          <StatisticsWidget7
            className='card-xl-stretch mb-xl-8 shadow-sm p-0 rounded'
            svgIcon='element-11'
            color='primary'
            iconColor='orange'
            description='New Investment Opportunities'
            title={`${totalProjects}`}
            titleColor='dark'
            descriptionColor='gray-600'
            redirect='/projectlist'
          />
        </div>
        <div className='col-xl-4 '>
          <StatisticsWidget7
            className='card-xl-stretch mb-xl-8 shadow-sm p-0 rounded'
            svgIcon='chart-simple'
            color='primary'
            iconColor='orange'
            description='My Earnings'
            title='0'
            titleColor='dark'
            descriptionColor='gray-600'
            redirect='/reports'
          />
        </div>
        <div className='col-xl-4 '>
          <StatisticsWidget7
            className='card-xl-stretch mb-xl-8 shadow-sm p-0 rounded'
            svgIcon='bill'
            color='primary'
            iconColor='orange'
            description='My Investment'
            title={invested.length.toString()}
            titleColor='dark'
            descriptionColor='gray-600'
            redirect='/investmentlist'
          />
        </div>

      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8 my-5'>
        <div className='col-xl mt-5'>
          <TablesWidget1Demo className='card-xl-stretch shadow-sm mb-5 mb-xl-8' title={'Latest Investment Opportunities'} data={Oppurtunity} clickEvent={onclick} />
        </div>
        <div className='col-xl mt-5'>
          <TablesWidgetDemo className='card-xl-stretch shadow-sm mb-5 mb-xl-8' title={'My Latest Investment'} data={invested}/>
        </div>
      </div>

      <div className='row g-5 g-xl-8 my-5'>
        <div className='col-xl mt-5'>
          <TablesWidgetDemoNews className='card-xl-stretch shadow-sm mb-5 mb-xl-8' title={'Top News Feed'} data={newsList}/>
        </div>
        <div className='col-xl mt-5'>
          <TablesWidgetDemoShort className='card-xl-stretch shadow-sm mb-5 mb-xl-8' title={'Top ShortListed Deals'} data={shortProjects}/>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* <div className='col-xxl-4'>
          <MixedWidget2
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            strokeColor='#cb1e46'
          />
        </div> */}
        {/* <div className='col-xxl-4'>
          <ListsWidget5 className='card-xxl-stretch' />
        </div> */}
        {/* <div className='col-lg'>

          <MixedWidget11
            className='card-lg-stretch-100 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
          />
        </div> */}
        {/* <div className='col-6'>
          
          <MixedWidget7
            className='card-lg-stretch-100 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
          />
        </div> */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div> */}
        {/* <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div> */}
        {/* <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
          
        </div> */}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
