/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'
import { fetchData } from '../../../../reusable'

type Props = {
  className: string
  title?: string
  data?: any
  clickEvent?: any
}

const TablesWidget1Demo: React.FC<Props> = ({ className, title, data, clickEvent }) => {

  const [category, setcategory] = useState([])
  // const [categoryFilter, setcategoryFilter] = useState("")

  const shortListProject = async () => {

    let condition = {

    }
    var response: any = await fetchData('categoryList', condition)
    if (response.status == "200") {
      setcategory(response.data)
    }
  }

  useEffect(() => {
    shortListProject()
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className='row'>
          <h3 className='col-sm card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>

          {/* <div className='card-toolbar'>

            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>

              <div className='separator border-gray-200'></div>

              <div className='px-7 py-5'>
                <div className='mb-10'>
                  <label className='form-label fw-bold'>Catgeory:</label>

                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={''}
                      onChange={(e) => {
                        clickEvent(e.target.value)
                        // setcategoryFilter(e.target.value);
                      }}
                    >
                      <option></option>
                      {category.map((obj: any) => {

                        return (
                          <option value={obj._id}>{obj.name}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

          </div> */}

          <div className='col-sm'>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={''}
              onChange={(e) => {
                clickEvent(e.target.value)
                // setcategoryFilter(e.target.value);
              }}
            >
              <option>Select Category</option>
              {category.map((obj: any) => {

                return (
                  <option value={obj._id}>{obj.name}</option>
                )
              })}
            </select>
          </div>
        </div>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 w-50px fw-semibold'>Image</th>
                <th className='p-0 min-w-150px fw-semibold'>Project Name</th>
                <th className='p-0 min-w-110px text-center fw-semibold'>Risk</th>
                <th className='p-0 min-w-50px text-center fw-semibold'>Progress</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.map((project: any, index: any) => {

                if (index < 5) {
                  return (<tr>
                    <th>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={project.profilePicture}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </th>
                    <td>
                      <Link to={'../projectdetails/'} state={{ projectId: project._id }} className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {project.name}
                      </Link>
                    </td>
                    <td>
                      <span className='text-dark fw-bold'>{project.riskRating}</span>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>{((parseFloat(project['totalProjectAmount']) / parseFloat(project['investmentOppurtunitySize'])) * 100).toFixed(2)}%</span>
                        </div>
                        <div className='progress h-6px w-100'>
                          <div
                            className='progress-bar bg-primary'
                            role='progressbar'
                            style={{ width: `${(parseFloat(project['totalProjectAmount']) / parseFloat(project['investmentOppurtunitySize'])) * 100}%` }}
                          ></div>
                        </div>
                      </div>
                    </td>

                  </tr>)

                }
              })
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export { TablesWidget1Demo }
