/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../reusable';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { Card6 } from '../../../_metronic/partials/content/cards/Card6'
import { IconUserModel } from './ProfileModels'
import { useQueryResponse } from '../../modules/apps/user-management/users-list/core/QueryResponseProvider';
import { Card6Billingcategory } from '../../../_metronic/partials/content/cards/Card6Billingcategory';
import { ToastContainer } from 'react-toastify';

export function ProjectList() {
  useEffect(() => {
    document.title = 'Investor | My Project';
  }, []);
  const navigate = useNavigate()
  var vendorId = "";
  const vendorData = sessionStorage.getItem("vendor");
  if (vendorData) {
    var vendor = JSON.parse(vendorData);
    vendorId = vendor._id;

  } else {
    console.log("No vendor data found");
  }


  sessionStorage.removeItem('investmentList')
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  const [filter, setfilter] = useState("")

  const handleSelectChange = (e: any) => {
    setfilter(e.target.value)
    profileDetailsApiCall(e.target.value)
  }

  const [offset, setoffset] = useState(0)
  const [limit, setlimit] = useState(9);
  const [page, setpage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [outdata, setoutdata] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [from, setfrom] = useState(1)
  const [to, setTo] = useState(1)
  const [pageNumbersList, setPageNumberList] = useState([1])

  const [projectDetails, setProjectDetails] = useState([]);
  const [projectDetailsList, setProjectDetailsList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  // page number generater
  function generatePageNumbers(currentPage: any, totalPages: any, maxPagesToShow: any) {
    const halfMaxPages = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPages);
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Adjust startPage and endPage if the total pages to show is less than maxPagesToShow
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    // Generate the list of page numbers
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }
  // on page change function
  const onpagecahngeold = (pageId: any) => {
    if (pageId != page && pageId != 0 && pageId <= totalPage) {
      // console.log(pageId);
      var tmp = (pageId - 1) * limit
      setpage(pageId)
      setoffset(tmp)
      setfrom(tmp + 1)
      // setto
      var mod = projectDetails.length % limit
      var tolimi: any = limit
      if (mod > 0) {
        if (mod <= limit) {
          tolimi = pageId * limit
          if (tolimi > projectDetails.length) {
            tolimi = projectDetails.length
          }
        }
        else {
          tolimi = pageId * limit + limit
          if (tolimi > projectDetails.length) {
            tolimi = projectDetails.length
          }
        }
      }
      else {
        tolimi = pageId * limit
      }
      setTo(tolimi)
      var tmpdata: any = projectDetails.slice(tmp, tolimi)
      setProjectDetailsList(tmpdata)
      // console.log("tmp offset",tmp);
    }

  }

  const onpagecahnge = (pageId: any, data: any = [], fromSearch: any = false, limit: any = 10) => {


    var newdata: any = []
    if (fromSearch == true) {
      newdata = [...data];
    }
    else {
      newdata = [...projectDetails]
      console.log("newdata.length", newdata.length);
    }
    console.log("data", data);

    setTotalData(newdata.length)
    if ((pageId != page && pageId != 0 && pageId <= totalPage) || data.length > 0) {
      // console.log(pageId);
      var tmp = (pageId - 1) * limit
      setpage(pageId)
      setoffset(tmp)
      setfrom(tmp + 1)
      // setto
      var mod = newdata.length % limit
      var tolimi: any = limit
      if (mod > 0) {
        if (mod <= limit) {
          tolimi = pageId * limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
        else {
          tolimi = pageId * limit + limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
      }
      else {
        tolimi = pageId * limit
      }
      setTo(tolimi)
      var tmpdata: any = newdata.slice(tmp, tolimi)
      setProjectDetailsList(tmpdata)
      // console.log("tmp offset",tmp);
    }
    else if (data.length == 0) {
      setTo(0)
      setfrom(0)

    }
    var totpag = (Math.ceil(newdata.length / limit))
    setTotalPage(totpag)
    // setProjectDetailsList(data)
    // onpagecahnge(1,data,true)
    setpage(pageId)
    var genpag = generatePageNumbers(1, totpag, 6)
    setPageNumberList(genpag)

  }

  const projectsearch = async (text: any) => {


    if (text == "" || text == null || text == undefined) {
      // setProjectDetailsList(projectDetails)
      console.log(projectDetails);
      var tmp: any = projectDetails.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(projectDetails.length / limit))
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = projectDetails.length % limit
        if (mod > 0) {
          setTo(mod)
        }
        else {
          setTo(tmp.length)
        }
      }
      else {
        var mod = projectDetails.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)

          }
          else {
            setTo(page * limit + limit)
          }
        }
        else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      var tmp: any = projectDetails.filter((item: any) => {
        return (item.name != null && item.name != "" && (item.name).toLowerCase().includes(text.toLowerCase())) ||
          (item.riskRating != null && item.riskRating != "" && (item.riskRating).toLowerCase().includes(text.toLowerCase()) ||
            (item.category != null && item.category != "" && (item.category.name).toLowerCase().includes(text.toLowerCase())));
      });

      // var tmp:any=projectDetails.filter((item:any)=>item.name!=null && item.name!="" && (item.name).toLowerCase() == text.toLowerCase())
      // var tmp:any=projectDetails.filter((item:any)=>item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase() == text.toLowerCase()) ||(item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase().includes(text.toLowerCase())) ||(item.email!=null && item.email!="" && (item.email).toLowerCase() == text.toLowerCase()) ||(item.email!=null && item.email!="" && (item.email).toLowerCase().includes(text.toLowerCase())) ||(item.location!=null && item.location!="" && (item.location).toLowerCase() == text.toLowerCase()) ||(item.location!=null && item.location!="" && (item.location).toLowerCase().includes(text.toLowerCase()))
      // var tmp:any=orguserList.filter((item:any)=>((item.fullName)).toLowerCase() == text.toLowerCase() 
      // || ((item.email)+' '+(item.email)).toLowerCase().includes(text.toLowerCase()))
      setProjectDetailsList(tmp)
    }
  }
  // profile Details api call
  const profileDetailsApiCall = async (filter: any = "",categoryFilter:any = "") => {
    
    var param: any = {
      filter: filter,
      categoryId: categoryFilter,
      investorId: vendorId,
    }
    var response: any = await fetchData('viewproject', param)
    console.log(response)
    if (response.status == "200") {
      setProjectDetails(response.data);
      var res = response.data
      setTotalData(res.length)
      console.log(res);

      var tmp: any = res.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(res.length / limit))
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = res.length % limit
        if (mod > 0) {
          setTo(mod)
        }
        else {
          setTo(tmp.length)
        }
      }
      else {
        var mod = res.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)

          }
          else {
            setTo(page * limit + limit)
          }
        }
        else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      toasterrormsg(response.message)
    }
  }

  var arr: any = []
  const shortListedIds = async (e: any, ids: any) => {

    if (e.target.checked) {
      if (!arr.includes(ids)) {
        arr.push(ids)
      }
    } else {
      arr = arr.filter((item: any) => item !== ids)
    }

    if (arr.length != 0) {

      var res = await fetchData("addeditprojectsort", { investorId: vendor._id, projects: arr.join() });

      if (res.status == "200") {
        toastsuccessmsg("Project Sorted Successfully")
        profileDetailsApiCall()

      } else {
        toasterrormsg(res.message)
      }
    } else {

      toasterrormsg("Please Select Any One Project")
    }

  }

  const removeShort = async (e: any, ids: any) => {

    var res = await fetchData("removeSortProject", { investorId: vendor._id, projects: ids });


    if (res.status == "200") {
      toastsuccessmsg("Removed From Shortlist Successfully")

      profileDetailsApiCall()
    } else {
      toasterrormsg(res.message)
    }

  }

  const apiShort = async () => {

    if (arr.length != 0) {

      var res = await fetchData("addeditprojectsort", { investorId: vendor._id, projects: arr.join() });

      if (res.status == "200") {
        toastsuccessmsg(res.message)
        setTimeout(() => {

        }, 1500);
      } else {
        toasterrormsg(res.message)
      }
    } else {

      toasterrormsg("Please Select Any One Project")
    }


  }


  const [category, setcategory] = useState([])
  const [categoryFilter, setcategoryFilter] = useState("")

  const categoryList = async () => {

    var response: any = await fetchData('categoryList', {})
    if (response.status == "200") {
      setcategory(response.data)
    }
  }


  const resetFilter =() =>{
    setfilter("")
    setcategoryFilter("")
    profileDetailsApiCall("","")
  }
  useEffect(() => {
    profileDetailsApiCall()
    categoryList()
  }, [])


  return (
    <>
      <ToastContainer />
      <div className='row flex-wrap flex-stack mb-6'>
        <div className='col-lg-3'>
        <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  onChange={(e) => 
                    profileDetailsApiCall("",e.target.value)}
                  // value={categoryFilter}
                >
                  <option value='' selected>Select Category</option>
                  {category.map((obj: any) => {

                    return (
                      <option value={obj._id}>{obj.name}</option>
                    )
                  })}
                </select>
        </div>
        <div className='col-lg'>
          {/* <button className='btn btn-primary' onClick={()=>apiShort()}>Short</button> */}
          {/* <button className='btn btn-primary' >Short</button> */}
        </div>
        <div className='col-lg-2'>
          <button
            disabled={isLoading}
            type='button'
            className='btn btn-light-primary me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
            Filter
          </button>
          {/* end::Filter Button */}
          {/* begin::SubMenu */}
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'></div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}

              {/* end::Input group */}

              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Risk</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  // onChange={handleSelectChange}
                  onChange={(e) => setfilter(e.target.value)}
                  value={filter}
                >
                  <option value='' selected>Select</option>
                  <option value='LOW'>LOW</option>
                  <option value='MEDIUM'>MEDIUM</option>
                  <option value='HIGH'>HIGH</option>
                </select>
              </div>
              {/* <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Category</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  onChange={(e) => setcategoryFilter(e.target.value)}
                  value={categoryFilter}
                >
                  <option value='' selected>Select</option>
                  {category.map((obj: any) => {

                    return (
                      <option value={obj._id}>{obj.name}</option>
                    )
                  })}
                </select>
              </div> */}

              {/* begin::Actions */}
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  disabled={isLoading}
                  onClick={resetFilter}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='reset'
                  
                >
                  Reset
                </button>
                <button
                  disabled={isLoading}
                  type='button'
                  onClick={(e)=>{profileDetailsApiCall(filter,categoryFilter)}}
                  className='btn btn-primary fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  Apply
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::SubMenu */}
        </div>

        <div className='d-flex flex-wrap my-2'>
          <div className='d-flex align-items-center position-relative me-4'>
            {/* <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' /> */}
            {/* <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder='Search'
              onChange={(e) => {
                projectsearch(e.target.value)
              }}
            /> */}
          </div>
        </div>
        {/* <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>
          <a
            href='#'
            className='btn btn-primary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_project'
          >
            New Project
          </a>
        </div> */}
      </div>
      <div className='row g-6 g-xl-9'>
        {projectDetailsList.map((project: any, index: any) => {

          var imageUrl = ''
          if (project['profile'] && project['profile'] != '' && project['profile'] != null) {
            imageUrl = "https://inalts.com/api/inalts/image/company/" + project['profile']
          } else {
            imageUrl = toAbsoluteUrl('/media/logos/inalts-logo2.png')
          }
          var businessCategory = ''
          if (project['categoryDetail'] != '' && project['categoryDetail'] != null) {
            businessCategory = project['categoryDetail'].name
          }
          var investorInterested = '0'
          if (
            project['investorInterested'] &&
            project['investorInterested'] != '' &&
            project['investorInterested'] != null
          ) {
            investorInterested = project['investorInterested']
          }
          var averageAmount = '0'
          if (
            project['averageAmount'] &&
            project['averageAmount'] != '' &&
            project['averageAmount'] != null
          ) {
            averageAmount = project['averageAmount']
          }

          var roi;
          var irr;

          if (project['payout'] == "FIXED_AMOUNT") {
            if (project['payoutDetail'].length != 0) {
              roi = project['payoutDetail'].length != 0 ? '₹ ' + project['payoutDetail'][0]['roiAmount'] : '₹ 0'
              irr = project['payoutDetail'].length != 0 ? '₹ ' + project['payoutDetail'][0]['irrAmount'] : '₹ 0'
            } else {
              roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
              irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
            }
          } else if (project['payout'] == "PERCENTAGE") {
            if (project['payoutDetail'].length != 0) {
              roi = project['payoutDetail'].length != 0 ? project['payoutDetail'][0]['roiPercentage'] + '%' : '0%'
              irr = project['payoutDetail'].length != 0 ? project['payoutDetail'][0]['irrPercentage'] + '%' : '0%'
            } else {
              roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
              irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
            }
          } else if (project['payout'] == "VARIABLE") {
            if (project['payoutDetail'].length != 0) {

              for (let i = 0; i < project['payoutDetail'].length; i++) {
                roi = 0;
                irr = 0;
                for (let i = 0; i < project['payoutDetail'].length; i++) {
                  roi += parseFloat(project['payoutDetail'][i]['roiPercentage']);
                  irr += parseFloat(project['payoutDetail'][i]['irrPercentage']);
                }
                roi = roi + '%';
                irr = irr + '%';
              }
            } else {
              roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
              irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
            }
          }

          return (


            <div className='col-lg-6 col-xl-4' key={index}>
              {project['category'] == "665d4c3e25c3014efceae144" ? (
                <Card6Billingcategory
                  icon={"https://inalts.com/api/inalts/image/company/" + project['profile']}
                  statusColor='primary'
                  title={project['name']}
                  projectCode={"#" + project['projectCode']}
                  investmentOppurtunitySize={project['investmentOppurtunitySize']}
                  businessCategory={businessCategory}
                  investorInterested={investorInterested + ' Person'}
                  averageAmount={project['minAmount']}
                  riskRating={project['riskRating']}
                  pending={(parseFloat(project['investmentOppurtunitySize']) - parseFloat(project['totalProjectAmount'])).toString()}
                  grossYield={project['interestRate']}
                  tds={project['tdsDeduct'] ? 'YES' : 'NO'}
                  exitDuration={project['exitPeriodType']}
                  exitPeriod={project['exitPeriod']}
                  progress={(parseFloat(project['totalProjectAmount']) / parseFloat(project['investmentOppurtunitySize'])) * 100}
                  projectId={project['_id']}
                  onAccept={shortListedIds}
                  onRemove={removeShort}
                  isCheckBoxshortedOrNot={project['isSortList']}

                />
              ) : (<Card6
                icon={"https://inalts.com/api/inalts/image/company/" + project['profile']}
                statusColor='primary'
                title={project['name']}
                projectCode={"#" + project['projectCode']}
                investmentOppurtunitySize={project['investmentOppurtunitySize']}
                businessCategory={businessCategory}
                investorInterested={investorInterested + ' Person'}
                averageAmount={project['minAmount']}
                riskRating={project['riskRating']}
                irr={irr || ""}
                roi={roi || ""}
                exitDuration={project['exitPeriodType']}
                exitPeriod={project['exitPeriod']}
                progress={
                  (100 / project['investmentOppurtunitySize']) * 0 > 100
                    ? 100
                    : (100 / project['investmentOppurtunitySize']) * 0
                }
                projectId={project['_id']}
              />)


              }

            </div>
          )
        })}
      </div>
      <div className='d-flex flex-stack flex-wrap pt-10'>
        <div className='row'>
          <select
            className='form-select form-select-solid fw-bolder w-50'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='role'
            data-hide-search='true'
            onChange={(e) => {
              setlimit(parseInt(e.target.value));
              onpagecahnge(page, projectDetailsList, false, parseInt(e.target.value));
            }}

          >
            <option value='10'>10</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
          <div className='fs-6 fw-bold text-gray-700'>
            Showing {from} to {to} of {totalData} entries
          </div>

        </div>

        <ul className='pagination'>
          <li className={`page-item previous ${page > 1 ? '' : 'disabled'}`}>
            <span
              className={`page-link ${page > 1 ? 'cursor-pointer' : 'disabled'}`}
              onClick={() => onpagecahnge(page - 1)}
            >
              <i className='previous'></i>
            </span>
          </li>
          {pageNumbersList.map((item: any) => {
            return (
              <li className={`page-item cursor-pointer ${page == item ? 'active' : ''}`}>
                <span className='page-link' onClick={() => onpagecahnge(item)}>
                  {item}
                </span>
              </li>
            )
          })}

          {/* <li className='page-item active'>
              <Link to='#' className='page-link'>
                1
              </Link>
            </li>

            <li className='page-item'>
              <Link to='#' className='page-link'>
                2
              </Link>
            </li>

            <li className='page-item'>
              <Link to='#' className='page-link'>
                3
              </Link>
            </li>

            <li className='page-item'>
              <Link to='#' className='page-link'>
                4
              </Link>
            </li>

            <li className='page-item'>
              <Link to='#' className='page-link'>
                5
              </Link>
            </li>

            <li className='page-item'>
              <Link to='#' className='page-link'>
                6
              </Link>
            </li>
            */}

          <li className={`page-item next ${page + 1 <= totalPage ? '' : 'disabled'}`}>
            <span
              className={`page-link ${page + 1 <= totalPage ? 'cursor-pointer' : 'disabled'}`}
              onClick={() => (page + 1 <= totalPage ? onpagecahnge(page + 1) : '')}
            >
              <i className='next'></i>
            </span>
          </li>
          {/* <li className='page-item last'>
              <Link to='#' className='page-link'>
                <i className='next'></i>
                <i className='next'></i>
              </Link>
            </li>  */}
        </ul>
      </div>
    </>
  )
}

const users1: Array<IconUserModel> = [
  { name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg' },
  { name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg' },
  { name: 'Susan Redwood', initials: 'S', color: 'primary' },
]

const users2 = [
  { name: 'Alan Warden', initials: 'A', color: 'warning' },
  { name: 'Brian Cox', avatar: '/media/avatars/300-5.jpg' },
]

const users3 = [
  { name: 'Mad Masy', avatar: '/media/avatars/300-6.jpg' },
  { name: 'Cris Willson', avatar: '/media/avatars/300-1.jpg' },
  { name: 'Mike Garcie', initials: 'M', color: 'info' },
]

const users4 = [
  { name: 'Nich Warden', initials: 'N', color: 'warning' },
  { name: 'Rob Otto', initials: 'R', color: 'success' },
]

const users5 = [
  { name: 'Francis Mitcham', avatar: '/media/avatars/300-20.jpg' },
  { name: 'Michelle Swanston', avatar: '/media/avatars/300-7.jpg' },
  { name: 'Susan Redwood', initials: 'S', color: 'primary' },
]

const users6 = [
  { name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg' },
  { name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg' },
  { name: 'Susan Redwood', initials: 'S', color: 'primary' },
]

const users7 = [
  { name: 'Meloday Macy', avatar: '/media/avatars/300-2.jpg' },
  { name: 'Rabbin Watterman', initials: 'S', color: 'success' },
]

const users8 = [
  { name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg' },
  { name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg' },
  { name: 'Susan Redwood', initials: 'S', color: 'primary' },
]

const users9 = [
  { name: 'Meloday Macy', avatar: '/media/avatars/300-2.jpg' },
  { name: 'Rabbin Watterman', initials: 'S', color: 'danger' },
]
