/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import Home from '../modules/auth/header/homecontent'
import Refund from '../modules/auth/header/Refund'
import PrivacyPolicy from '../modules/auth/header/PrivacyPolicy'
import TermsAndCondition from '../modules/auth/header/TermsAndCondition'
import ContactUs from '../modules/auth/header/contactas'
// import { HomePage } from '../modules/auth/HomeNew'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  const userData: any = sessionStorage.getItem("vendor");
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          {/* <Route path='/newHomePage' element={<HomePage />} /> */}
          <Route path='refund' element={<Refund />} />
          <Route path='privacyPolicy' element={<PrivacyPolicy />} />
          <Route path='termsAndCondition' element={<TermsAndCondition />} />
          <Route path='contactUs' element={<ContactUs />} />

          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {/* {userData ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )} */}
          <Route path='/*' element={<PrivateRoutes />} />
          <Route path='auth/*' element={<AuthPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
