/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../../reusable';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useQueryResponse } from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useIntl } from 'react-intl';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertToRaw } from 'draft-js';
import Swal from 'sweetalert2';
import { Card6Billingcategory } from '../../../../_metronic/partials/content/cards/Card6Billingcategory';
import { Card6 } from '../../../../_metronic/partials/content/cards/Card6';
import { ToastContainer } from 'react-toastify';

export function ShortlistProject() {
  useEffect(() => {
    document.title = 'Investor | Investment';
  }, []);


  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  const [filter, setfilter] = useState(0)
  var vendorId = "";
  const vendorData = sessionStorage.getItem("vendor");
  if (vendorData) {
    var vendor = JSON.parse(vendorData);
    vendorId = vendor._id;

  } else {
    console.log("No vendor data found");
  }

  const formatDate = (isoDate: any) => {
    const date = parseISO(isoDate);
    return format(date, "do MMMM yyyy");
  };

  const [offset, setoffset] = useState(0)
  const [limit, setlimit] = useState(9);
  const [page, setpage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [from, setfrom] = useState(1)
  const [to, setTo] = useState(1)
  const [pageNumbersList, setPageNumberList] = useState([1])

  const [projectDetails, setProjectDetails] = useState([]);
  const [investmentdetail, setInvestmentdetail] = useState([]);
  const [projectDetailsList, setProjectDetailsList] = useState([]);
  // page number generater
  function generatePageNumbers(currentPage: any, totalPages: any, maxPagesToShow: any) {
    const halfMaxPages = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPages);
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Adjust startPage and endPage if the total pages to show is less than maxPagesToShow
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    // Generate the list of page numbers
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }

  const onpagecahnge = (pageId: any, data: any = [], fromSearch: any = false, limit: any = 10) => {


    var newdata: any = []
    if (fromSearch == true) {
      newdata = [...data];
    }
    else {
      newdata = [...projectDetails]
      console.log("newdata.length", newdata.length);
    }
    console.log("data", data);

    setTotalData(newdata.length)
    if ((pageId != page && pageId != 0 && pageId <= totalPage) || data.length > 0) {
      // console.log(pageId);
      var tmp = (pageId - 1) * limit
      setpage(pageId)
      setoffset(tmp)
      setfrom(tmp + 1)
      // setto
      var mod = newdata.length % limit
      var tolimi: any = limit
      if (mod > 0) {
        if (mod <= limit) {
          tolimi = pageId * limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
        else {
          tolimi = pageId * limit + limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
      }
      else {
        tolimi = pageId * limit
      }
      setTo(tolimi)
      var tmpdata: any = newdata.slice(tmp, tolimi)
      setProjectDetailsList(tmpdata)
      // console.log("tmp offset",tmp);
    }
    else if (data.length == 0) {
      setTo(0)
      setfrom(0)

    }
    var totpag = (Math.ceil(newdata.length / limit))
    setTotalPage(totpag)
    // setProjectDetailsList(data)
    // onpagecahnge(1,data,true)
    setpage(pageId)
    var genpag = generatePageNumbers(1, totpag, 6)
    setPageNumberList(genpag)

  }

  const projectsearch = async (text: any) => {


    if (text == "" || text == null || text == undefined) {
      // setProjectDetailsList(projectDetails)
      console.log(projectDetails);
      var tmp: any = projectDetails.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(projectDetails.length / limit))
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = projectDetails.length % limit
        if (mod > 0) {
          setTo(mod)
        }
        else {
          setTo(tmp.length)
        }
      }
      else {
        var mod = projectDetails.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)

          }
          else {
            setTo(page * limit + limit)
          }
        }
        else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      var tmp: any = projectDetails.filter((item: any) => {
        return (item.title != null && item.title != "" && (item.title).toLowerCase().includes(text.toLowerCase())) ||
          ((item.description != null && item.description != "" && (item.description).toLowerCase().includes(text.toLowerCase())));
      });

      // var tmp:any=projectDetails.filter((item:any)=>item.name!=null && item.name!="" && (item.name).toLowerCase() == text.toLowerCase())
      // var tmp:any=projectDetails.filter((item:any)=>item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase() == text.toLowerCase()) ||(item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase().includes(text.toLowerCase())) ||(item.email!=null && item.email!="" && (item.email).toLowerCase() == text.toLowerCase()) ||(item.email!=null && item.email!="" && (item.email).toLowerCase().includes(text.toLowerCase())) ||(item.location!=null && item.location!="" && (item.location).toLowerCase() == text.toLowerCase()) ||(item.location!=null && item.location!="" && (item.location).toLowerCase().includes(text.toLowerCase()))
      // var tmp:any=orguserList.filter((item:any)=>((item.fullName)).toLowerCase() == text.toLowerCase() 
      // || ((item.email)+' '+(item.email)).toLowerCase().includes(text.toLowerCase()))
      setProjectDetailsList(tmp)
    }
  }
  // profile Details api call
  const profileDetailsApiCall = async (filter: any = "") => {

    let condition = {
      investorId: vendorId
    }
    console.log("=--------",condition);
    
    var investmentData: any = await fetchData("projectSortList", condition)
    
    if (investmentData.status == "200") {
      
      setInvestmentdetail(investmentData.data);
      
      var res = investmentData.data
      setTotalData(res.length)
    }
    else {
      toasterrormsg(investmentData.message)
    }
    console.log(investmentdetail)

    var param: any = {
      filter: filter,
    }

    var response: any = await fetchData("viewproject", param)
    if (response.status == "200") {
      setProjectDetails(response.data);
      var res = response.data
      setTotalData(res.length)

      var tmp: any = res.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(res.length / limit))
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = res.length % limit
        if (mod > 0) {
          setTo(mod)
        }
        else {
          setTo(tmp.length)
        }
      }
      else {
        var mod = res.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)

          }
          else {
            setTo(page * limit + limit)
          }
        }
        else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      toasterrormsg(response.message)
    }
  }


  var arr:any = []
  const shortListedIds = async (e:any,ids:any) => {
    
    var res = await fetchData("removeSortProject",{investorId:vendor._id,projects:ids});
   
      
    if(res.status == "200"){
      toastsuccessmsg("Removed From Shortlist Successfully")
      
      profileDetailsApiCall()
    }else{
      toasterrormsg(res.message)
    }
  

  }

  const intl = useIntl()
  useEffect(() => {
    profileDetailsApiCall()
  }, [])


  return (
    <>
    <ToastContainer />
      <div className=''>

        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Investment' })}</PageTitle>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>

          </h3>

          <div className='d-flex flex-wrap my-2'>
            <div className='d-flex align-items-center position-relative me-4'>
              <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-white form-control-sm w-150px ps-9'
                placeholder='Search'
                onChange={(e) => {
                  projectsearch(e.target.value)
                }}
              />
            </div>
          </div>

        </div>
        <div className=' pt-3'>
          <div className={clsx('row g-6 g-xl-9 ')}>
            <div className='row'>
              {investmentdetail.map((project: any, index: any) => {

                var imageUrl = ''
                if (project['profile'] && project['profile'] != '' && project['profile'] != null) {
                  imageUrl = "https://inalts.com/api/inalts/image/company/" + project['profile']
                } else {
                  imageUrl = toAbsoluteUrl('/media/logos/inalts-logo2.png')
                }
                var businessCategory = ''
                if (project['categoryDetail'] != '' && project['categoryDetail'] != null) {
                  businessCategory = project['categoryDetail'].name
                }
                var investorInterested = '0'
                if (
                  project['investorInterested'] &&
                  project['investorInterested'] != '' &&
                  project['investorInterested'] != null
                ) {
                  investorInterested = project['investorInterested']
                }
                var averageAmount = '0'
                if (
                  project['averageAmount'] &&
                  project['averageAmount'] != '' &&
                  project['averageAmount'] != null
                ) {
                  averageAmount = project['averageAmount']
                }

                var roi;
                var irr;
                let parsePayoutDetail = JSON.parse(project['payoutDetail'])
                // console.log(parsePayoutDetail[0]['roiPercentage'] || 0, "==========")
                // console.log(parsePayoutDetail[0]['irrAmount'], "==========")

                if (project['payout'] == "FIXED_AMOUNT") {
                  if (parsePayoutDetail.length != 0) {
                    roi = parsePayoutDetail.length != 0 ? '₹ ' + 0 || parsePayoutDetail[0]['roiAmount'] : '₹ 0'
                    irr = parsePayoutDetail.length != 0 ? '₹ ' + 0 || parsePayoutDetail[0]['irrAmount'] : '₹ 0'
                  } else {
                    roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
                    irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
                  }
                } else if (project['payout'] == "PERCENTAGE") {
                  if (parsePayoutDetail.length != 0) {
                    roi = parsePayoutDetail.length != 0 ? 0 || parsePayoutDetail[0]['roiPercentage']  + '%' : '0%'
                    irr = parsePayoutDetail.length != 0 ? 0 || parsePayoutDetail[0]['irrPercentage']  + '%' : '0%'
                  } else {
                    roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
                    irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
                  }
                } else if (project['payout'] == "VARIABLE") {
                  if (parsePayoutDetail.length != 0) {

                    for (let i = 0; i < parsePayoutDetail.length; i++) {
                      roi = 0;
                      irr = 0;
                      for (let i = 0; i < parsePayoutDetail.length; i++) {
                        roi += parseFloat(project['payoutDetail'][i]['roiPercentage']);
                        irr += parseFloat(project['payoutDetail'][i]['irrPercentage']);
                      }
                      roi = roi + '%';
                      irr = irr + '%';
                    }
                  } else {
                    roi = project['irrPercentage'] ? project['irrPercentage'] + '%' : '0%'
                    irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
                  }
                }

                return (


                  <div className='col-lg-6 col-xl-4 mb-5' key={index}>
                    {project['category'] == "665d4c3e25c3014efceae144" ? (
                      <Card6Billingcategory
                        icon={"https://inalts.com/api/inalts/image/company/" + project['profile']}
                        statusColor='primary'
                        title={project['name']}
                        projectCode={"#" + project['projectCode']}
                        investmentOppurtunitySize={project['investmentOppurtunitySize']}
                        businessCategory={businessCategory}
                        investorInterested={investorInterested + ' Person'}
                        averageAmount={project['minAmount']}
                        riskRating={project['riskRating']}
                        
                        grossYield={project['grossYield']}
                        tds={project['tdsDeduct'] ? 'YES' : 'NO'}
                        exitDuration={project['exitPeriodType']}
                        exitPeriod={project['exitPeriod']}
                        
                        progress={
                          (100 / project['investmentOppurtunitySize']) * 0 > 100
                            ? 100
                            : (100 / project['investmentOppurtunitySize']) * 0
                        }
                        projectId={project['_id']}
                        isCheckBoxshortedOrNot={project['isSortList']}
                        onAccept={shortListedIds}
                      />
                    ) : (<Card6
                      icon={"https://inalts.com/api/inalts/image/company/" + project['profile']}
                      statusColor='primary'
                      title={project['name']}
                      projectCode={"#" + project['projectCode']}
                      investmentOppurtunitySize={project['investmentOppurtunitySize']}
                      businessCategory={businessCategory}
                      investorInterested={investorInterested + ' Person'}
                      averageAmount={project['minAmount']}
                      riskRating={project['riskRating']}
                      irr={irr || ""}
                      roi={roi || ""}
                      exitDuration={project['exitPeriodType']}
                      exitPeriod={project['exitPeriod']}
                      progress={
                        (100 / project['investmentOppurtunitySize']) * 0 > 100
                          ? 100
                          : (100 / project['investmentOppurtunitySize']) * 0
                      }
                      projectId={project['_id']}
                    />)


                    }

                  </div>
                )
              })}
            </div>
          </div>

        </div>

        <div className='d-flex flex-stack flex-wrap pt-10'>
          {/* </div> */}
          <div className='row'>
            <select
              className='form-select form-select-solid fw-bolder w-50'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => {
                setlimit(parseInt(e.target.value));
                onpagecahnge(page, projectDetailsList, false, parseInt(e.target.value));
              }}

            >
              <option value='10'>10</option>
              <option value='30'>30</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
            <div className='fs-6 fw-bold text-gray-700'>
              Showing {from} to {to} of {totalData} entries
            </div>

          </div>

          <ul className='pagination'>
            <li className={`page-item previous ${page > 1 ? '' : 'disabled'}`}>
              <span
                className={`page-link ${page > 1 ? 'cursor-pointer' : 'disabled'}`}
                onClick={() => onpagecahnge(page - 1)}
              >
                <i className='previous'></i>
              </span>
            </li>
            {pageNumbersList.map((item: any) => {
              return (
                <li className={`page-item cursor-pointer ${page == item ? 'active' : ''}`}>
                  <span className='page-link' onClick={() => onpagecahnge(item)}>
                    {item}
                  </span>
                </li>
              )
            })}



            <li className={`page-item next ${page + 1 <= totalPage ? '' : 'disabled'}`}>
              <span
                className={`page-link ${page + 1 <= totalPage ? 'cursor-pointer' : 'disabled'}`}
                onClick={() => (page + 1 <= totalPage ? onpagecahnge(page + 1) : '')}
              >
                <i className='next'></i>
              </span>
            </li>

          </ul>
        </div>
      </div>
    </>
  )
}
