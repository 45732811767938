/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchData, toasterrormsg } from '../../../../reusable';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useQueryResponse } from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useIntl } from 'react-intl';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertToRaw } from 'draft-js';
import Swal from 'sweetalert2';
import { Card6Billingcategory } from '../../../../_metronic/partials/content/cards/Card6Billingcategory';
import { Card6 } from '../../../../_metronic/partials/content/cards/Card6';

export function InvestmentList() {
  useEffect(() => {
    document.title = 'Investor | Investment';
  }, []);


  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  const [filter, setfilter] = useState(0)
  // const [vendorId, setVendorId] = useState("");

  const vendorData = sessionStorage.getItem("vendor");
  var vendorId = ""
  if (vendorData) {
    const vendor = JSON.parse(vendorData);
    vendorId = vendor._id
  }
  useEffect(() => {


  }, []);

  sessionStorage.setItem('investmentList', '1')


  const formatDate = (isoDate: any) => {
    const date = parseISO(isoDate);
    return format(date, "do MMMM yyyy");
  };

  const [offset, setoffset] = useState(0)
  const [limit, setlimit] = useState(9);
  const [page, setpage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [from, setfrom] = useState(1)
  const [to, setTo] = useState(1)
  const [pageNumbersList, setPageNumberList] = useState([1])

  const [projectDetails, setProjectDetails] = useState([]);
  const [projectDetailsList, setProjectDetailsList] = useState([]);
 // page number generater
 function generatePageNumbers(currentPage: any, totalPages: any, maxPagesToShow: any) {
  const halfMaxPages = Math.floor(maxPagesToShow / 2)
  let startPage = Math.max(1, currentPage - halfMaxPages)
  let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1)

  // Adjust startPage and endPage if the total pages to show is less than maxPagesToShow
  if (endPage - startPage + 1 < maxPagesToShow) {
    startPage = Math.max(1, endPage - maxPagesToShow + 1)
  }

  // Generate the list of page numbers
  const pageNumbers = []
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i)
  }

  return pageNumbers
}
// on page change function
const onpagecahnge=(pageId:any,data:any=[],fromSearch:any=false,limit:any=10)=>{
  console.log("page no",pageId);
  
  
  var newdata:any=[]
  if(fromSearch==true)
    {
      newdata=[...data];
    }
    else
    {
      newdata=[...projectDetails]
      console.log("newdata.length",newdata.length);
    }
    console.log("data",data);
    
    setTotalData(newdata.length)
  if((pageId!=page && pageId!=0 && pageId<=totalPage) || data.length>0)
  {
    // console.log(pageId);
    var tmp=(pageId-1)*limit
    setpage(pageId)
    setoffset(tmp)
    setfrom(tmp+1)
    // setto
    var mod=newdata.length%limit
    var tolimi:any=limit
    if(mod>0)
    {
      if(mod<=limit)
      {
        tolimi=pageId*limit
        if(tolimi>newdata.length)
        {
          tolimi=newdata.length
        }
      }
      else
      {
        tolimi=pageId*limit+limit
        if(tolimi>newdata.length)
        {
          tolimi=newdata.length
        }
      }
    }
    else
    {
      tolimi=pageId*limit
    }
    setTo(tolimi)
    var tmpdata:any=newdata.slice(tmp,tolimi)
    setProjectDetailsList(tmpdata)
    // console.log("tmp offset",tmp);
  } 
  else if(data.length == 0){
    setTo(0)
    setfrom(0)

  }
  var totpag=(Math.ceil(newdata.length/limit))
      setTotalPage(totpag)
      // setProjectDetailsList(data)
      // onpagecahnge(1,data,true)
      setpage(pageId)
      var genpag=generatePageNumbers(1,totpag,6)
      setPageNumberList(genpag)

}
const projectsearch = async (text: any) => {
  
  if(text=="" || text==null || text==undefined)
    { 
      // setProjectDetailsList(projectDetails)
      console.log(projectDetails);
      var tmp:any=projectDetails.slice(offset, offset+limit);
      
        setProjectDetailsList(tmp)

        var totpag=(Math.ceil(projectDetails.length/limit))
        setTotalPage(totpag)
        setProjectDetailsList(projectDetails)
        onpagecahnge(1,projectDetails,true)
        setpage(1)
        var genpag=generatePageNumbers(1,totpag,6)
        setPageNumberList(genpag)
      
    }
    else
    {
      var tmp: any = projectDetails.filter((item: any) => {
       
        
        return (item['projectData']['name'] != null && item['projectData']['name'] != "" && (item['projectData']['name']).toLowerCase().includes(text.toLowerCase()))
               
        });
     
      var totpag=(Math.ceil(tmp.length/limit))
      setTotalPage(totpag)
      setProjectDetailsList(tmp)
      onpagecahnge(1,tmp,true)
      setpage(1)
      var genpag=generatePageNumbers(1,totpag,6)
      setPageNumberList(genpag)
    }
}
  // profile Details api call
  const profileDetailsApiCall = async (filter: any = "") => {

    let condition = {
      investorId: vendorId
    }
    var investmentData: any = await fetchData("investmentdetail", condition)

    // if (investmentData.status == "200") {

    //   setProjectDetailsList(investmentData.data);

    //   var res = investmentData.data
    //   setTotalData(res.length)
    // }
    // else {
    //   toasterrormsg(investmentData.message)
    // }

    if (investmentData.status == '200') {
      setProjectDetails(investmentData.data)
      // console.log('**************', investmentData.data)
      var res = investmentData.data
      setTotalData(res.length)
      var tmp: any = res.slice(offset, offset + limit)

      setProjectDetailsList(tmp)

      var totpag = Math.ceil(res.length / limit)
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = res.length % limit
        if (mod > 0) {
          setTo(mod)
        } else {
          setTo(tmp.length)
        }
      } else {
        var mod = res.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)
          } else {
            setTo(page * limit + limit)
          }
        } else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)
    } else {
      toasterrormsg(investmentData.message)
    }

  }

  const intl = useIntl()
  useEffect(() => {
    profileDetailsApiCall()
  }, [])


  return (
    <>
      <div className=''>

        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Investment' })}</PageTitle>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>

          </h3>

          <div className='d-flex flex-wrap my-2'>
            <div className='d-flex align-items-center position-relative me-4'>
              <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-white form-control-sm w-150px ps-9'
                placeholder='Search'
                onChange={(e) => {
                  projectsearch(e.target.value)
                }}
              />
            </div>
          </div>

        </div>
        <div className=' pt-3'>
          <div className={clsx('row g-6 g-xl-9 ')}>
            <div className='row'>
              {projectDetailsList.map((project: any, index: any) => {
               
                var imageUrl = ''
                if (project['projectData']['profile'] && project['projectData']['profile'] != '' && project['projectData']['profile'] != null) {
                  imageUrl = "https://inalts.com/api/inalts/image/company/" + project['projectData']['profile']
                } else {
                  imageUrl = toAbsoluteUrl('/media/logos/inalts-logo2.png')
                }
                var businessCategory = ''
                if (project['projectData']['categoryDetail'] != '' && project['projectData']['categoryDetail'] != null) {
                  businessCategory = project['projectData']['categoryDetail'].name
                }
                var investorInterested = '0'
                if (
                  project['investorInterested'] &&
                  project['investorInterested'] != '' &&
                  project['investorInterested'] != null
                ) {
                  investorInterested = project['investorInterested']
                }
                var averageAmount = '0'
                if (
                  project['averageAmount'] &&
                  project['averageAmount'] != '' &&
                  project['averageAmount'] != null
                ) {
                  averageAmount = project['averageAmount']
                }

                var roi;
                var irr;
                let parsePayoutDetail = project['projectData']['payoutDetail']
                // console.log(parsePayoutDetail[0]['roiPercentage'] || 0, "==========")
                // console.log(parsePayoutDetail[0]['irrAmount'], "==========")

                if (project['projectData']['payout'] == "FIXED_AMOUNT") {
                  if (parsePayoutDetail.length != 0) {
                    roi = parsePayoutDetail.length != 0 ? '₹ ' + 0 || parsePayoutDetail[0]['roiAmount'] : '₹ 0'
                    irr = parsePayoutDetail.length != 0 ? '₹ ' + 0 || parsePayoutDetail[0]['irrAmount'] : '₹ 0'
                  } else {
                    roi = project['projectData']['irrPercentage'] ? project['projectData']['irrPercentage'] + '%' : '0%'
                    irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
                  }
                } else if (project['projectData']['payout'] == "PERCENTAGE") {
                  if (parsePayoutDetail.length != 0) {
                    roi = parsePayoutDetail.length != 0 ? 0 || parsePayoutDetail[0]['roiPercentage'] + '%' : '0%'
                    irr = parsePayoutDetail.length != 0 ? 0 || parsePayoutDetail[0]['irrPercentage'] + '%' : '0%'
                  } else {
                    roi = project['projectData']['irrPercentage'] ? project['projectData']['irrPercentage'] + '%' : '0%'
                    irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
                  }
                } else if (project['projectData']['payout'] == "VARIABLE") {
                  if (parsePayoutDetail.length != 0) {

                    for (let i = 0; i < parsePayoutDetail.length; i++) {
                      roi = 0;
                      irr = 0;
                      for (let i = 0; i < parsePayoutDetail.length; i++) {
                        roi += parseFloat(project['projectData']['payoutDetail'][i]['roiPercentage']);
                        irr += parseFloat(project['projectData']['payoutDetail'][i]['irrPercentage']);
                      }
                      roi = roi + '%';
                      irr = irr + '%';
                    }
                  } else {
                    roi = project['projectData']['irrPercentage'] ? project['projectData']['irrPercentage'] + '%' : '0%'
                    irr = project['roiPercentage'] ? project['roiPercentage'] + '%' : '0%'
                  }
                }

                return (

                  <>
                    {
                      project['projectData'].length != 0 && (
                        <div className='col-lg-6 col-xl-4 mb-5'>
                          {

                            project['projectData']['category'] == "665d4c3e25c3014efceae144" ? (
                              <Card6Billingcategory
                                icon={"https://inalts.com/api/inalts/image/company/" + project['projectData']['profile']}
                                statusColor='primary'
                                title={project['projectData']['name']}
                                projectCode={"#" + project['projectData']['projectCode']}
                                investmentOppurtunitySize={project['projectData']['investmentOppurtunitySize']}
                                businessCategory={businessCategory}
                                investorInterested={investorInterested + ' Person'}
                                averageAmount={project['amount']}
                                riskRating={project['projectData']['riskRating']}
                                // irr={irr || ""}
                                pending={(parseFloat(project['projectData']['investmentOppurtunitySize']) - parseFloat(project['amount'])).toString()}
                                grossYield={project['projectData']['grossYield']}
                                tds={project['projectData']['tdsDeduct'] ? 'YES' : 'NO'}
                                exitDuration={project['projectData']['exitPeriodType']}
                                exitPeriod={project['exitPeriod']}
                                progress={(parseFloat(project['amount']) / parseFloat(project['projectData']['investmentOppurtunitySize'])) * 100}
                                // progress={100}
                                projectId={project['projectData']['_id']}
                                isCheckBoxshortedOrNot={project['isSortList']}
                                paymentStatus={project['status']}
                                paymentMethodType={project['transactionType']}
                              />
                            ) : (
                              <Card6
                                icon={"https://inalts.com/api/inalts/image/company/" + project['projectData']['profile']}
                                statusColor='primary'
                                title={project['projectData']['name']}
                                projectCode={"#" + project['projectData']['projectCode']}
                                investmentOppurtunitySize={project['projectData']['investmentOppurtunitySize']}
                                businessCategory={businessCategory}
                                investorInterested={investorInterested + ' Person'}
                                averageAmount={project['projectData']['minAmount']}
                                riskRating={project['projectData']['riskRating']}
                                irr={irr || ""}
                                roi={roi || ""}
                                exitDuration={project['projectData']['exitPeriodType']}
                                exitPeriod={project['projectData']['exitPeriod']}
                                progress={(project['projectData']['totalProjectAmount'] / project['projectData']['investmentOppurtunitySize']) * 100}
                                projectId={project['projectData']['_id']}
                              />
                            )
                          }
                        </div>
                        )
                      }
                      
                  </>
                )
              })}
            </div>
          </div>

        </div>

        <div className='d-flex flex-stack flex-wrap pt-10'>
          {/* </div> */}
          <div className='row'>
            <select
              className='form-select form-select-solid fw-bolder w-50'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => {
                setlimit(parseInt(e.target.value));
                onpagecahnge(page, projectDetailsList, false, parseInt(e.target.value));
              }}

            >
              <option value='10'>10</option>
              <option value='30'>30</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
            <div className='fs-6 fw-bold text-gray-700'>
              Showing {from} to {to} of {totalData} entries
            </div>

          </div>

          <ul className='pagination'>
            <li className={`page-item previous ${page > 1 ? '' : 'disabled'}`}>
              <span
                className={`page-link ${page > 1 ? 'cursor-pointer' : 'disabled'}`}
                onClick={() => onpagecahnge(page - 1)}
              >
                <i className='previous'></i>
              </span>
            </li>
            {pageNumbersList.map((item: any) => {
              return (
                <li className={`page-item cursor-pointer ${page == item ? 'active' : ''}`}>
                  <span className='page-link' onClick={() => onpagecahnge(item)}>
                    {item}
                  </span>
                </li>
              )
            })}



            <li className={`page-item next ${page + 1 <= totalPage ? '' : 'disabled'}`}>
              <span
                className={`page-link ${page + 1 <= totalPage ? 'cursor-pointer' : 'disabled'}`}
                onClick={() => (page + 1 <= totalPage ? onpagecahnge(page + 1) : '')}
              >
                <i className='next'></i>
              </span>
            </li>

          </ul>
        </div>
      </div>
    </>
  )
}
