
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// const CryptoJS = require('crypto-js');
// import CryptoJS from 'crypto-js';
// const CryptoJS = require("crypto-js");
// const crypto from 'crypto'
const SECRET_KEY = '';
const algorithm = "";
const initVector = '';
const Securitykey = '';

export const FONTS =
{
    bold: 'Poppins-Bold',
    black: 'Poppins-Black',
    regular: 'Poppins-Regular',
    medium: 'Poppins-Medium',
    semiBold: 'Poppins-SemiBold',
    semiItalic: 'Poppins-SemiBoldItalic',
    mediumItalic: 'Poppins-MediumItalic',
    italic: 'Poppins-Italic'
}

export const KEYS =
{
    google_key: '',
    PayPal_Client_ID: '',
    PayPal_Secret_key1: ''

}

export const SIZE =
{
    font_11: 11,
    font_13: 13,
    font_15: 15,
    font_17: 17,
    font_20: 20,
}

export const COLORS =
{
    darkGray: '#9A9999',
    borderClr: '#E8E6EA',
    pink: '#CB6CE6',
    lightGray: '#F5F5F5'
}



export const URL = {
  // uatgogagnerurl:'http://localhost:5056/inalts/investor/',
  // productionurl: 'http://web.xpertlab.com:8082/uztrip_uat/public/',
//   uatgogagnerurl: 'https://api.gogagner.com/inalts/investor/',
  uatgogagnerurl: 'https://api.inalts.com/inalts/investor/',
}

export const fetchFormData = async (url: string, raw: any) => {

    var myHeaders = new Headers();
    myHeaders.append("apitoken", "b361dc54ca90a4dd02efaf412ed54f9a");

    var requestOptions :any=
    {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response = await fetch(URL.uatgogagnerurl + url, requestOptions);
    let json = await response.json();

    return json;
}

export const fetchData = async (url: string, raw: any) => {

    var myHeaders:any = new Headers();
    myHeaders.append("apitoken", "b361dc54ca90a4dd02efaf412ed54f9a");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    

    // console.log(URL.uatgogagnerurl + url);
    
    var requestOptions =
    {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw)
    };
    // console.log(requestOptions)

    let response = await fetch(URL.uatgogagnerurl + url, requestOptions);
    
    
    let json = await response.json();

    return json;
}

export function toasterrormsg(message: any) {
    toast.error(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
    });
}

export function toastsuccessmsg(message: any) {
    toast.success(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
    });
}


export const dateFormat = (date:any,formateType = "YYYY-MM-DD") => {
    const inputDate = new Date(date);
    
    if(date)
        {
      
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth()+1) > 9 ? (inputDate.getMonth()+1) : '0'+ (inputDate.getMonth()+1) ;
        const day = inputDate.getDate() > 9 ? inputDate.getDate() : '0' + inputDate.getDate() ;
        // console.log(`${year}-${month}-${day}`);
        if(formateType == "DD-MM-YYYY"){

            return  `${day}-${month}-${year}`; 
        }else{
            
            return  `${year}-${month}-${day}`; 
        }
    }
    else
    {
        return ''
    }
 //    console.log("FormateDate::::::::",formatedate);
    
 }

 export const autopassword=()=>{
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
    
    return password;
 }


// Encrypt Data 
// export const encryptData = (data: any) => {
//     const encryptedData = CryptoJS.AES.encrypt(data, CryptoJS.enc.Hex.parse(Securitykey), {
//         iv: CryptoJS.enc.Hex.parse(initVector),
//         mode: CryptoJS.mode.CFB,
//     });

//     return encryptedData.ciphertext.toString(CryptoJS.enc.Hex);
// };


// Decrypt Data
// export const decryptData = (dataToDecrypt: any) => {
//     const isObject = typeof dataToDecrypt === 'object' && !Array.isArray(dataToDecrypt);

//     if (isObject) {
//         // Decrypt an object
//         const decryptedObject: any = {};

//         for (const [key, value] of Object.entries(dataToDecrypt)) {
//             const decryptedValue = CryptoJS.AES.decrypt(
//                 { ciphertext: CryptoJS.enc.Hex.parse(value) },
//                 CryptoJS.enc.Hex.parse(Securitykey),
//                 {
//                     iv: CryptoJS.enc.Hex.parse(initVector),
//                     mode: CryptoJS.mode.CFB,
//                 }
//             );

//             decryptedObject[key] = decryptedValue.toString(CryptoJS.enc.Utf8);
//         }

//         return decryptedObject;
//     } else {
//         // Decrypt a single value
//         const decryptedValue = CryptoJS.AES.decrypt(
//             { ciphertext: CryptoJS.enc.Hex.parse(dataToDecrypt) },
//             CryptoJS.enc.Hex.parse(Securitykey),
//             {
//                 iv: CryptoJS.enc.Hex.parse(initVector),
//                 mode: CryptoJS.mode.CFB,
//             }
//         );

//         return decryptedValue.toString(CryptoJS.enc.Utf8);
//     }
// };




