/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IconUserModel } from '../../../../app/modules/profile/ProfileModels'
import { UsersList } from '../../../../app/modules/profile/components/UsersList'
import { toAbsoluteUrl } from '../../../helpers'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  icon: string
  statusColor: string
  title: string
  businessCategory: string
  investorInterested: string
  averageAmount: string
  riskRating: string
  grossYield: string
  tds: string
  exitDuration: string
  exitPeriod: string
  investmentOppurtunitySize: string
  progress: number
  projectId: string
  projectCode: string
  onAccept?: (event: any, _id: any) => void
  onRemove?: (event: any, _id: any) => void
  pending?: string
  isCheckBoxshortedOrNot?: string
  paymentStatus?: string
  paymentMethodType?: string
}

const Card6Billingcategory: FC<Props> = ({
  icon,
  statusColor,
  title,
  businessCategory,
  investorInterested,
  averageAmount,
  riskRating,
  grossYield,
  exitDuration,
  exitPeriod,
  tds,
  investmentOppurtunitySize,
  progress,
  projectId,
  projectCode,
  onAccept,
  onRemove,
  pending,
  isCheckBoxshortedOrNot,
  paymentStatus,
  paymentMethodType

}) => {

  var vendorId = "";
  const vendorData = sessionStorage.getItem("vendor");
  if (vendorData) {
    var vendor = JSON.parse(vendorData);
    vendorId = vendor._id;

  } else {
    console.log("No vendor data found");
  }

  const location = useLocation()
  console.log(location.pathname);

  return (
    <>
      {/* <Link
        to='/crafted/pages/profile/overview'
        className='card border border-2 border-gray-300 border-hover'
      > */}
      <div className='card shadow-sm p-0 rounded ' style={{
        // backgroundPosition: 'center center',
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')})`,
      }}>
        <div className='p-5'>
          <div className='d-flex justify-content-between'>
            <div className='fs-3 fw-bolder text-dark'>{title}</div>
          {
            vendorId && (
              <>
              {
              location.pathname.includes('investmentlist') || location.pathname.includes('shortlistproject')
                ? null
                : (
                  <div>
                    {isCheckBoxshortedOrNot == "0" ? (
                      <>
                        ShortList<input
                          className="mx-2 form-check-input border border-dark"
                          type="checkbox"
                          onClick={(e: any) => onAccept?.(e, projectId)}
                        />
                      </>
                    ) : ( <span className='text-success fs-7' style={{cursor:'pointer'}} onClick={(e: any) => onRemove?.(e, projectId)}>Remove Form Shortlist</span>)}
  
                    <div />
                  </div>
                )}
  
              {location.pathname.includes('shortlistproject') && (
                <>
                <span className='text-success fs-7' style={{cursor:'pointer'}} onClick={(e: any) => onAccept?.(e, projectId)}>Remove Form Shortlist</span>
              </>
              )
                
              
              }
              
              </>
            )
          }
            

            {/* <input className='form-check-input' type='checkbox' value='1' onClick={() => onAccept(projectId)} /> */}
          </div>
          <div className='fs-3 fw-bolder text-gray-400'>{projectCode}</div>
        </div>
        <div className='separator'></div>
        <div className='card-header border-0 pt-3'>
          <div className='card-title m-0'>
            <div className='bg-light'>
              <img
                src={icon}
                alt='Card6'
                className='p-2 img-fluid'
                style={{ height: '150px', width: '150px' }}
              />
            </div>
          </div>
          <div>
            <div className='my-3 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'>Category: </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{ marginLeft: '5px' }}>
                {businessCategory}
              </p>
            </div>
            {/* <div className='my-3 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'>
                Investor Interested:{' '}
              </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{marginLeft: '5px'}}>
                {' '}
                {investorInterested}
              </p>
            </div> */}
            <div className='my-3 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'>{location.pathname.includes('investmentlist') ? "My Investment:" : "Minimum Investment:"}   </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{ marginLeft: '5px' }}>
                {' '}
                ₹{averageAmount}
              </p>
            </div>
            {location.pathname.includes('investmentlist') &&
            <>
                <div className='my-3 d-flex'>
                  <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'> Payment Status : </p>
                  <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{ marginLeft: '5px' }}>
                  {paymentStatus == '0' ? (
                     <span className=' fs-5 text-center badge badge-light-primary'>
                     PENDING
                   </span>
                  ) : (
                    <span className=' fs-5 text-center badge badge-light-success'>
                    SUCCESS
                    </span>
                  )

                  }
                 
                    
                  </p>
                </div>
              <div className='my-3 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'> Payment Method : </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{ marginLeft: '5px' }}>
               {paymentMethodType}
              </p>
            </div>
            </>
            }
          </div>
          {/* <div className='card-toolbar'>
              <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}>
                {status}
              </span>
            </div> */}
        </div>

        <div className='card-body p-9'>
          {/* <div className='fs-3 fw-bolder text-dark'>{title}</div> */}

          {/* <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{description}</p> */}

          <div className='d-flex flex-wrap mb-5'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='fw-bold text-gray-400'>Risk Rating</div>
              <div className='fs-6 text-gray-800 fw-bolder'>{riskRating}</div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='fw-bold text-gray-400'>Interest Rate</div>
              <div className='fs-6 text-gray-800 fw-bolder'>{grossYield} %</div>
            </div>

          </div>

          <div className='d-flex flex-wrap mb-5'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='fw-bold text-gray-400'>TDS</div>
              <div className='fs-6 text-gray-800 fw-bolder'>{tds}</div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
              <div className='fw-bold text-gray-400'>Exit Duration</div>
              <div className='fs-6 text-gray-800 fw-bolder'>{exitPeriod} {exitDuration}</div>
            </div>
          </div>

          <div
            className='h-4px w-100 bg-light mb-10'
            data-bs-toggle='tooltip'
            // style={{position:"relative"}}
            title='This project completed'
          >
            <div
              className={`bg-${statusColor} rounded h-4px`}
              role='progressbar'
              style={{ width: `${progress}%` }}
            ></div>
            <div className='mt-2 d-flex'>
              <p className='text-gray-400 fw-bold fs-6 mb-0 mt-1 text-left'>
                ₹{parseInt(investmentOppurtunitySize).toLocaleString('en-IN')}{' '}
              </p>
              <p className='fw-bold fs-6 mt-1 text-left text-dark' style={{ marginLeft: '5px' }}>
                {' '}
                {pending ? "(₹" + pending + " Pending)" : "(0 Pending)"}
              </p>
            </div>
          </div>
          <div className='pt-5 d-flex justify-content-center'>
            <Link
              to='../projectdetails/'
              state={{ projectId: projectId, isShort:isCheckBoxshortedOrNot}}
              className='fw-bold text-primary'
            >
              View Details
            </Link>
          </div>
          {/* <UsersList users={users} /> */}
        </div>
      </div>
      {/* </Link> */}
    </>
  )
}

export { Card6Billingcategory }
