import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'
import { ToastContainer } from 'react-toastify'

export function ForgotPassword() {

  useEffect(() => {
    document.title = 'Investor | Forgot Password';
  }, []);
  const navigate=useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState("")
  const [countryCodeList,setCountryCodeList]=useState([])
  const [countryCode,setCountryCode]=useState(Object)
  const [email,setEmail]=useState("")
  const [mobileNumber,setMobileNumber]=useState("")
  const [isSubmited,setIsSubmited]=useState(false)
  
  const validateEmail=async()=>{
    
    if(email.trim()==="" || email.trim()===null || email.trim()===undefined)
    {
        setHasErrors("Email Address Is Required")
    }
    else if(!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i) ) {
        setHasErrors("Enter Valid Email Address")
    }
    else
    {
      
      // try {
      //   await auth.signInWithPhoneNumber(mobileNumber, new firebase.auth.RecaptchaVerifier('recaptcha-container'));
      //   // Handle success, navigate to OTP verification page
      // } catch (error) {
      //   console.error("Error sending OTP:", error);
      //   // Handle error
      // }
      forgotpasswordapicall()
      // navigate("../reset-password")
    }
  }

  const forgotpasswordapicall=async()=>{
    var param:any={
      email:email
    }
    var response:any=await fetchData("forgetPassword",param)
    
    if(response.status=="200")
    {
      sessionStorage.setItem('auther',response.data[0]['vendorId']);
      sessionStorage.setItem('autherEmail',email);
      console.log(email);
      
      localStorage.setItem("vendorId",response.data[0]['vendorId'])
      console.log(response.data);
      
      toastsuccessmsg("OTP Send Successfully")

      setTimeout(() => {
        navigate("../otpverification");
      }, 1500);
      
    }
    else
    {
      // setSubmitting(false)
      setLoading(false)
      toasterrormsg(response.message)
    }
  }

  return (
    <>
      <ToastContainer/>
      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        id='kt_login_password_reset_form'
      >
        <div className='text-start my-15'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-15'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder='Email'
            autoComplete='off'
            value={email}
            onChange={(e:any)=>{setEmail(e.target.value);}}
            className={'form-control bg-transparent'}
          />
          <span className='text-danger mt-3'>{hasErrors}</span>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button type='button' id='kt_password_reset_submit' className='btn btn-primary me-4' onClick={()=> validateEmail()}>
                <span className='indicator-label'>Get OTP</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {' '}
            </div>
        {/* end::Form group */}
      </div>
    </>
  )
}
