import React, { useState } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";

const NumberCounter = ({ endValue = 0, duration = 2 }) => {
  const [startCounting, setStartCounting] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures the counter starts only once when it comes into view
    threshold: 0.5, // 50% of the element should be in view before starting the counter
    onChange: (inView:any) => {
      if (inView) {
        setStartCounting(true);
      }
    },
  });

  const variants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i:any) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };
  return (
      <motion.span
        initial="hidden"
        animate="visible"
        variants={variants}
      ref={ref}>
        {startCounting && (
          <CountUp
            start={0}
            end={endValue}
            duration={duration}
            separator=","
            delay={0}
          />
        )}
      </motion.span>
  );
};

export default NumberCounter;
