/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTCard } from '../../../_metronic/helpers'
import {Card6} from '../../../_metronic/partials/content/cards/Card6'
import { UsersListHeader } from '../../modules/apps/user-management/users-list/components/header/UsersListHeader'
import { ListViewProvider, useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import { QueryRequestProvider } from '../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import { QueryResponseProvider } from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import { UsersTable } from '../../modules/apps/user-management/users-list/table/UsersTable'
import { UserEditModal } from '../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal'
import {IconUserModel} from './ProfileModels'

export function InvestorList() {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Investors
        </h3>
      </div>
      <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>

        <KTCard>
          <UsersListHeader />
          <UsersTable />
        </KTCard>
        </ListViewProvider>
      </QueryResponseProvider>
      </QueryRequestProvider>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

