/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { fetchData, fetchFormData, toasterrormsg, toastsuccessmsg } from '../../../reusable'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { getLayout, ILayout, LayoutSetup, useLayout } from '../../../_metronic/layout/core'
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faEye, faEyeSlash, faFile, faFileCode, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'


const ProfilePage: React.FC = () => {
  useEffect(() => {
    document.title = 'Investor | Edit Profile'
  }, [])
  const { setLayout } = useLayout()
  const [tab, setTab] = useState('EditProfile') // EditProfile, ChangePassword, KYCDocument
  const [subTab, setSubTab] = useState('mobile') // mobile, opt, password
  const [kycTab, setKycTab] = useState('BusinessDocument') // BusinessDocument, OwnerDocument
  const [configLoading, setConfigLoading] = useState<boolean>(false)

  // edit profile state start
  const [imageUrl, setImageUrl] = useState('')
  const [profilePicture, setProfilePicture] = useState()
  const [fullName, setFullName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [gender, setGender] = useState('MALE')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [communicationAddress, setCommunicationAddress] = useState('')
  const [profession, setProfession] = useState('')
  // edit profile state end

  // kyc document state start
  const [businessCertificate, setBusinessCertificate] = useState() as any
  const [gstCertificate, setGstCertificate] = useState() as any
  const [frontPanCard, setFrontPanCard] = useState() as any
  const [frontAadharCard, setFrontAadharCard] = useState() as any
  const [backAadharCard, setBackAadharCard] = useState() as any
  const [businessCerti, setBusinessCerti] = useState('')
  const [gstCerti, setGstCerti] = useState('')
  const [frontPan, setFrontPan] = useState('')
  const [frontAadhar, setFrontAadhar] = useState('')
  const [backAadhar, setBackAadhar] = useState('')

  const [frontPanExt, setPanCardExtension] = useState('')
  const [frontAadharExt, setFrontAdharExtension] = useState('')
  const [backAadharExt, setBackAadharExtension] = useState('')

  const [passwordDisplay, setPasswordDisplay] = useState(false)
  const [cnfpasswordDisplay, setcnfPasswordDisplay] = useState(false)
  // kyc document state end

  // Bank Detail ----------
  const [bankList, setbankList] = useState([])
  const [selectBank, setselectBank] = useState("")
  const [accountNumber, setaccountNumber] = useState("")
  const [ifscCode, setifscCode] = useState("")
  const [investorBankDetailId, setinvestorBankDetailId] = useState("")

  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState("")

  var vendor: any = sessionStorage.getItem('vendor')
  vendor = JSON.parse(vendor)
  // console.log(vendor);

  var vendorId = vendor._id

  // change password state start
  // const [changePassword_Email, setChangePassword_Email] = useState('')
  // const [newPassword, setNewPassword] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')

  // const [timer, setTimer] = useState(120)
  const [code_1, setCode_1] = useState('')
  const [code_2, setCode_2] = useState('')
  const [code_3, setCode_3] = useState('')
  const [code_4, setCode_4] = useState('')
  const otp = `${code_1}${code_2}${code_3}${code_4}`
  // change password state end

  // edit profile error state
  const [editProfileError, setEditProfileError] = useState([
    {
      profilePicture: '',
      // fullName:'',
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      addressLine1: '',
      addressLine2: '',
      gender: '',
      dateOfBirth: '',
      communicationAddress: '',
      profession: '',
    },
  ])

  // remove edit profile validation function
  const removeEditProfileError = (err: any) => {
    var tmp = [...editProfileError] as any
    tmp[0][err] = ''
    setEditProfileError(tmp)
  }

  // business docuemnt error state
  const [businessDocumentError, setBusinessDocumentError] = useState([
    {
      businessCertificate: '',
      gstCertificate: '',
    },
  ])

  // remove business Document validation function
  const removebusinessDocument = (err: any) => {
    var tmp = [...businessDocumentError] as any
    tmp[0][err] = ''
    setBusinessDocumentError(tmp)
  }

  // owner docuemnt error state
  const [ownerDocumentError, setOwnerDocumentError] = useState([
    {
      frontPanCard: '',
      frontAadharCard: '',
      backAadharCard: '',
    },
  ])

  // remove owner Document validation function
  const removeOwnerDocuemnt = (err: any) => {
    var tmp = [...ownerDocumentError] as any
    tmp[0][err] = ''
    setOwnerDocumentError(tmp)
  }

  const fileTypes = ['JPEG', 'JPG', 'PNG', 'PDF', 'DOCS']
  // const handleBusinessCertificateChange = (file:any) => {
  //   setBusinessCertificate(file);
  //   removebusinessDocument("businessCertificate");
  // };

  // Function to handle the input change for each code input
  const handleInputChange = (e: any, setterFunction: any) => {
    const value = e.target.value
    const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward'

    // Ensure the value is a single digit and update the state
    if (/^\d*$/.test(value) && value.length <= 1) {
      setterFunction(value)
      // Focus on the next input after setting the value
      if (isBackspace && value === '') {
        const currentInput = e.target
        if (currentInput.previousSibling) {
          currentInput.previousSibling.focus()
        }
      } else {
        // Focus on the next input after setting the value
        const currentInput = e.target
        if (currentInput.nextSibling && value !== '') {
          currentInput.nextSibling.focus()
        }
      }
    }
  }

  const handleKeyPress = (e: any) => {
    const keyCode = e.which || e.keyCode

    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault()
    }
  }

  const handleDivClick = (inputId: string) => {
    const inputElement = document.getElementById(inputId)
    if (inputElement) {
      inputElement.click()
    }
  }
  // const handleDivClick = (inputName: string) => {
  //     const inputElement = document.querySelector(`input[name="${inputName}"]`) as HTMLInputElement;
  //     if (inputElement) {
  //         inputElement.click();
  //     }
  // };

  const handleInputKeyDown = (event: any) => {
    // Prevent any key press actions
    event.preventDefault()
  }

  // edit profile validation functions
  const editProfilevalidate = () => {
    var isValid = true
    var errorTmp = [...editProfileError]
    if (imageUrl == '') {
      if (profilePicture == '' || profilePicture == null || profilePicture == undefined) {
        errorTmp[0].profilePicture = 'Profile picture is required'
        isValid = false
      }
    }
    if (firstName == '' || firstName == null || firstName == undefined) {
      errorTmp[0].firstName = 'First Name is required'
      isValid = false
    }
    if (lastName == '' || lastName == null || lastName == undefined) {
      errorTmp[0].lastName = 'Last Name is required'
      isValid = false
    }
    if (mobileNumber == '' || mobileNumber == undefined || mobileNumber == null) {
      errorTmp[0].mobileNumber = 'Mobile Number is required'
      isValid = false
    } else if (!Number(mobileNumber)) {
      errorTmp[0].mobileNumber = 'Only Numbers is allowed.'
      isValid = false
    } else if (
      ((mobileNumber != '' && mobileNumber != undefined) || mobileNumber != null) &&
      mobileNumber.length < 8
    ) {
      errorTmp[0].mobileNumber = 'Minimum 8 Digit Required'
      isValid = false
    }
    if (email == '' || email == undefined || email == null) {
      errorTmp[0].email = 'Email is required'
      isValid = false
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i.test(email)) {
      errorTmp[0].email = 'Please enter valid email.'
      isValid = false
    }
    if (addressLine1 == '' || addressLine1 == null || addressLine1 == undefined) {
      errorTmp[0].addressLine1 = 'Address is required'
      isValid = false
    }
    if (profession == '' || profession == null || profession == undefined) {
      errorTmp[0].profession = 'Profession is required'
      isValid = false
    }
    if (gender == '' || gender == null || gender == undefined) {
      errorTmp[0].gender = 'Gender is required'
      isValid = false
    }
    if (dateOfBirth == '' || dateOfBirth == null || dateOfBirth == undefined) {
      errorTmp[0].dateOfBirth = 'Date Of birth is required'
      isValid = false
    }
    if (
      communicationAddress == '' ||
      communicationAddress == null ||
      communicationAddress == undefined
    ) {
      errorTmp[0].communicationAddress = 'Communication Address is required'
      isValid = false
    }

    setEditProfileError(errorTmp)
    return isValid
  }

  // profile details api call
  const profieDetailsApiCall = async () => {
    var tmp: any = sessionStorage.getItem('vendor')
    tmp = JSON.parse(tmp)

    var param: any = {
      investorId: vendorId,
    }
    var response: any = await fetchData('investerdetail', param)
    // console.log("============",response.data['investorBankDetail']);
    if (response.status == '200') {
      var data = response.data
      
      setFirstName(data['firstName'])
      setLastName(data['lastName'])
      setMobileNumber(data['mobileNumber'])
      setEmail(data['email'])
      setAddressLine1(data['addressLine1'])
      setAddressLine2(data['addressLine2'])
      setProfession(data['profession'])
      setGender(data['gender'])
      setDateOfBirth(data['dateOfBirth'])
      setCommunicationAddress(data['communicationAddress'])
      setImageUrl(data['profilePicture'])
      // setBusinessCerti(data['businessCertificate'])
      // setGstCerti(data['gstCertificate'])
      setFrontPan(data['panCard'])
      setPanCardExtension(data['panCard'].split('.').pop().toLowerCase())
      setFrontAadhar(data['frontAadharCard'])
      setFrontAdharExtension(data['frontAadharCard'].split('.').pop().toLowerCase())
      setBackAadhar(data['backAadharCard'])
      setBackAadharExtension(data['backAadharCard'].split('.').pop().toLowerCase())
      setSelectedCountry(data['nationality'])

      setselectBank(data['investorBankDetail'][0]['bankId'])
      setaccountNumber(data['investorBankDetail'][0]['accountNumber'])
      setifscCode(data['investorBankDetail'][0]['ifscCode'])
      setinvestorBankDetailId(data['investorBankDetail'][0]['_id'])

      sessionStorage.setItem('vendor', JSON.stringify(data))
    } else {
      toasterrormsg(response.message)
    }
  }

  // edit profile api call
  const editProfileApiCall = async () => {
    if (editProfilevalidate()) {
      // var tmp:any=sessionStorage.getItem("vendor")
      // tmp=JSON.parse(tmp)

      let param = new FormData()
      param.append('investorId', vendorId)
      param.append('firstName', firstName)
      param.append('lastName', lastName)
      param.append('email', email)
      param.append('mobileNumber', mobileNumber)
      param.append('addressLine1', addressLine1)
      param.append('addressLine2', addressLine2)
      param.append('gender', gender)
      param.append('dateOfBirth', dateOfBirth)
      param.append('communicationAddress', communicationAddress)
      param.append('profession', profession)
      param.append('nationality', selectedCountry)
      if (profilePicture) {
        param.append('profilePicture', profilePicture)
      }

      var response: any = await fetchFormData('addinvestor', param)

      if (response.status == '200') {
        console.log(response.data[0])
        var data = response.data[0]

        toastsuccessmsg('Profile Changed Successfully!')
        profieDetailsApiCall()
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        toasterrormsg(response.message)
      }
    }
  }

  const handleBusinessCertificateChange = (file: any) => {
    if (file) {
      if (!file.type.startsWith('image/')) {
        // Invalid file type
        // Handle error or display a message
        toasterrormsg('Invalid file type. Please upload only image files.')
        setBusinessCertificate(undefined)
        console.log('wrong file')

        return
      }
      // Handle business certificate file change
      // For example:
      setBusinessCertificate(file)
      removebusinessDocument('businessCertificate')
    } else {
      // Handle no file selected case
      console.error('No file selected.')
    }
  }
  const handleGstCertificateChange = (file: any) => {
    if (file) {
      if (!file.type.startsWith('image/')) {
        // Invalid file type
        // Handle error or display a message
        toasterrormsg('Invalid file type. Please upload only image files.')
        setGstCertificate(undefined)
        return
      }
      // Handle business certificate file change
      // For example:
      setGstCertificate(file)
      removebusinessDocument('gstCertificate')
    } else {
      // Handle no file selected case
      console.error('No file selected.')
    }
  }
  // const handleBusinessCertificateChange = (e:any) => {
  //   if(e.target.files[0]!=null && e.target.files[0]!=undefined){

  //     // if (e.target.files[0].type !== 'application/pdf' && !e.target.files[0].type.startsWith('image/')) {
  //     if (!e.target.files[0].type.startsWith('image/')) {
  //       // Invalid file type
  //       // Handle error or display a message
  //       toasterrormsg('Invalid file type. Please upload only image files.')
  //       setBusinessCertificate(undefined);
  //       return;
  //     }
  //     // Handle business certificate file change
  //     // For example:
  //     setBusinessCertificate(e.target.files[0]);
  //     removebusinessDocument('businessCertificate');
  //   } else {
  //     // Handle no file selected case
  //     console.error('No file selected.');
  //   }
  // };
  // const handleGstCertificateChange = (e:any) => {
  //   if(e.target.files[0]!=null && e.target.files[0]!=undefined){

  //     // if (e.target.files[0].type !== 'application/pdf' && !e.target.files[0].type.startsWith('image/')) {
  //     if (!e.target.files[0].type.startsWith('image/')) {
  //       // Invalid file type
  //       // Handle error or display a message
  //       toasterrormsg('Invalid file type. Please upload only image files.')
  //       setGstCertificate(undefined);
  //       return;
  //     }
  //     // Handle gst certificate file change
  //     // For example:
  //     setGstCertificate(e.target.files[0]);
  //     removebusinessDocument('gstCertificate');
  //   } else {
  //     // Handle no file selected case
  //     console.error('No file selected.');
  //   }
  // };

  //  business Docuemnt validation functions
  const businessDocumentvalidate = () => {
    var isValid = true
    var errorTmp = [...businessDocumentError]

    if (!businessCerti) {
      if (
        businessCertificate == undefined ||
        businessCertificate == null ||
        businessCertificate == undefined
      ) {
        errorTmp[0].businessCertificate = 'Business Certificate is required'
        isValid = false
      }
    }
    if (!gstCerti) {
      if (gstCertificate == undefined || gstCertificate == null || gstCertificate == undefined) {
        errorTmp[0].gstCertificate = 'GST Certificate is required'
        isValid = false
      }
    }
    setBusinessDocumentError(errorTmp)
    return isValid
  }

  // business document api call
  const businessDocumentApi = async () => {
    if (businessDocumentvalidate()) {
      let param = new FormData()

      param.append('id', vendorId)
      if (businessCertificate) {
        param.append('businessCertificate', businessCertificate)
      }
      if (gstCertificate) {
        param.append('gstCertificate', gstCertificate)
      }
      var res = await fetchFormData('updateBusinessDocument', param)
      if (res.status == '200') {
        toastsuccessmsg('Business Document Saved Successfully!')
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        toasterrormsg(res.message)
      }
    }
  }

  const handleFrontPanCardChange = (file: any) => {
    if (file) {
      // if (!file.type.startsWith('image/')) {
      //   // Invalid file type
      //   // Handle error or display a message
      //   toasterrormsg('Invalid file type. Please upload only image files.')
      //   setFrontPanCard(undefined)
      //   return;
      // }
      // Handle business certificate file change
      // For example:
      setFrontPanCard(file)
      // console.log("=================",file.type.startsWith('image/'))

      // setpanCardExtension(file.type.startsWith('image/'))
      removeOwnerDocuemnt('frontPanCard')
    } else {
      // Handle no file selected case
      console.error('No file selected.')
    }
  }
  const handleFrontAdharCardChange = (file: any) => {
    if (file) {
      // if (!file.type.startsWith('image/')) {
      //   // Invalid file type
      //   // Handle error or display a message
      //   toasterrormsg('Invalid file type. Please upload only image files.')
      //   setFrontAadharCard(undefined)
      //   return;
      // }
      // Handle business certificate file change
      // For example:
      setFrontAadharCard(file)
      removeOwnerDocuemnt('frontAdharCard')
    } else {
      // Handle no file selected case
      console.error('No file selected.')
    }
  }
  const handleBackAdharCardChange = (file: any) => {
    if (file) {
      // if (!file.type.startsWith('image/')) {
      //   // Invalid file type
      //   // Handle error or display a message
      //   toasterrormsg('Invalid file type. Please upload only image files.')
      //   setBackAadharCard(undefined)
      //   return;
      // }
      // Handle business certificate file change
      // For example:
      setBackAadharCard(file)
      removeOwnerDocuemnt('backAdharCard')
    } else {
      // Handle no file selected case
      console.error('No file selected.')
    }
  }
  // const handleFrontPanCardChange = (e:any) => {
  //   if(e.target.files[0]!=null && e.target.files[0]!=undefined){

  //     // if (e.target.files[0].type !== 'application/pdf' && !e.target.files[0].type.startsWith('image/')) {
  //     if (!e.target.files[0].type.startsWith('image/')) {
  //       // Invalid file type
  //       // Handle error or display a message
  //       toasterrormsg('Invalid file type. Please upload only image files.')
  //       setFrontPanCard(undefined);
  //       return;
  //     }
  //     // Handle gst certificate file change
  //     // For example:
  //     setFrontPanCard(e.target.files[0]);
  //     removeOwnerDocuemnt('frontPanCard');
  //   } else {
  //     // Handle no file selected case
  //     console.error('No file selected.');
  //   }
  // };
  // const handleFrontAdharCardChange = (e:any) => {
  //   if(e.target.files[0]!=null && e.target.files[0]!=undefined){

  //     // if (e.target.files[0].type !== 'application/pdf' && !e.target.files[0].type.startsWith('image/')) {
  //       if (!e.target.files[0].type.startsWith('image/')) {
  //       // Invalid file type
  //       // Handle error or display a message
  //       toasterrormsg('Invalid file type. Please upload only image files.')
  //       setFrontAadharCard(undefined);
  //       return;
  //     }
  //     // Handle gst certificate file change
  //     // For example:
  //     setFrontAadharCard(e.target.files[0]);
  //     removeOwnerDocuemnt('frontAdharCard');
  //   } else {
  //     // Handle no file selected case
  //     console.error('No file selected.');
  //   }
  // };
  // const handleBackAdharCardChange = (e:any) => {
  //   if(e.target.files[0]!=null && e.target.files[0]!=undefined){

  //     // if (e.target.files[0].type !== 'application/pdf' && !e.target.files[0].type.startsWith('image/')) {
  //     if (!e.target.files[0].type.startsWith('image/')) {
  //       // Invalid file type
  //       // Handle error or display a message
  //       toasterrormsg('Invalid file type. Please upload only image files.')
  //       setBackAadharCard(undefined);
  //       return;
  //     }
  //     // Handle gst certificate file change
  //     // For example:
  //     setBackAadharCard(e.target.files[0]);
  //     removeOwnerDocuemnt('backAdharCard');
  //   } else {
  //     // Handle no file selected case
  //     console.error('No file selected.');
  //   }
  // };
  //  owner Docuemnt validation functions
  const ownerDocuemntvalidate = () => {
    var isValid = true
    var errorTmp = [...ownerDocumentError]

    if (!frontPan) {
      if (frontPanCard == undefined || frontPanCard == null || frontPanCard == undefined) {
        errorTmp[0].frontPanCard = 'Front Pan Card is required'
        isValid = false
      }
    }
    if (!frontAadhar) {
      if (frontAadharCard == undefined || frontAadharCard == null || frontAadharCard == undefined) {
        errorTmp[0].frontAadharCard = 'Front Aadhar Card is required'
        isValid = false
      }
    }
    if (!backAadhar) {
      if (backAadharCard == undefined || backAadharCard == null || backAadharCard == undefined) {
        errorTmp[0].backAadharCard = 'Back Aadhar Card is required'
        isValid = false
      }
    }
    setOwnerDocumentError(errorTmp)
    return isValid
  }

  // business document api call
  const ownerDocumentApi = async () => {
    if (ownerDocuemntvalidate()) {
      let param = new FormData()

      param.append('investorId', vendorId)
      if (frontPanCard) {
        param.append('panCard', frontPanCard)
      }
      if (frontAadharCard) {
        param.append('frontAadharCard', frontAadharCard)
      }
      if (backAadharCard) {
        param.append('backAadharCard', backAadharCard)
      }
      var res = await fetchFormData('kycupload', param)
      if (res.status == '200') {
        toastsuccessmsg('Kyc Document Saved Successfully!')
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        toasterrormsg(res.message)
      }
    }
  }

  // Function to get the current date in the format YYYY-MM-DD
  const getCurrentDate = () => {
    const date = new Date()
    const year = date.getFullYear()
    let month = String(date.getMonth() + 1)
    let day = String(date.getDate())

    // Add leading zeros if necessary
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return `${year}-${month}-${day}`
  }
  const [timer, setTimer] = useState(120); // Initial timer value in seconds
  const [changePassword_Email, setChangePassword_Email] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // change password error state
  const [changePasswordError, setChangePasswordError] = useState([{
    changePassword_Email: '',
    otp: '',
    newPassword: '',
    confirmPassword: ''
  }])

  // remove edit profile validation function
  const removeChangePasswordError = (err: any) => {
    var tmp = [...changePasswordError] as any;
    tmp[0][err] = "";
    setChangePasswordError(tmp);
  }
  // change password validation function
  const changepasswordValidate = () => {

    var isValid = true;
    var errorTmp = [...changePasswordError];
    if (subTab == "email") {
      if (changePassword_Email == "" || changePassword_Email == undefined || changePassword_Email == null) {
        errorTmp[0].changePassword_Email = 'Email is required';
        isValid = false;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i.test(changePassword_Email)) {
        errorTmp[0].changePassword_Email = 'Please enter valid Email.';
        isValid = false;
      }
    } else if (subTab == 'otp') {
      if (otp == "" || otp == undefined || otp == null) {
        errorTmp[0].otp = 'Otp is required';
        isValid = false;
      }
    } else if (subTab == "password") {
      if (newPassword == "" || newPassword == undefined || newPassword == null) {
        errorTmp[0].newPassword = 'New Password is required';
        isValid = false;
      } else if (newPassword.length < 6) {
        errorTmp[0].newPassword = 'Enter At Least 6 Digit Password';
        isValid = false;
      }
      if (confirmPassword == "" || confirmPassword == undefined || confirmPassword == null) {
        errorTmp[0].confirmPassword = 'Confirm Password is required';
        isValid = false;
      } else if (newPassword != confirmPassword) {
        errorTmp[0].confirmPassword = 'Password And Confirm Password Are Not Match';
        isValid = false;
      }
    }
    setChangePasswordError(errorTmp);
    return isValid;
  }
  // forgot password pai call
  const forgotpasswordapicall = async () => {
    if (changepasswordValidate()) {

      var param: any = {
        email: changePassword_Email
      }
      var response: any = await fetchData("investoremailforgotpassword", param)
      
      
      if (response.status == "200") {
        sessionStorage.setItem('auther', response.data[0]['vendorId']);
        sessionStorage.setItem('autherEmail', changePassword_Email);
        console.log(response.data);
        settemotp(response.data[0]["otp"])
        localStorage.setItem("vendorId", response.data[0]['vendorId'])
        setSubTab("otp");
        toastsuccessmsg("OTP Send Successfully")
        const countdown = setInterval(() => {
          setTimer(prevTimer => {
            if (prevTimer === 0) {
              clearInterval(countdown);
              // Handle timeout logic here (e.g., show option to resend OTP)
              return 0; // Return 0 to stop the timer
            } else {
              return prevTimer - 1;
            }
          });
        }, 1000);

        // Cleanup function to clear the interval when component unmounts or timer reaches 0
        return () => clearInterval(countdown);

      }
      else {
        // setSubmitting(false)
        toasterrormsg(response.message)
      }
    }
  }

  var autherEmail = sessionStorage.getItem('autherEmail') || "";

  const [tempotp, settemotp] = useState("");
  // resend otp api call
  const resendOTPApiCall = async () => {
    console.log(email);

    var param: any = {
      mobileNumber: mobileNumber ? mobileNumber : autherEmail
    }
    var response: any = await fetchData("investorforgotpassword", param)

    if (response.status == "200") {
      sessionStorage.setItem('auther', response.data[0]['vendorId']);
      localStorage.setItem("vendorId", response.data[0]['vendorId']);
      sessionStorage.setItem('autherEmail', email);
      setTimer(120)
      const countdown = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === 0) {
            clearInterval(countdown);
            // Handle timeout logic here (e.g., show option to resend OTP)
            return 0; // Return 0 to stop the timer
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);

      // Cleanup function to clear the interval when component unmounts or timer reaches 0
      console.log(response.data);

      toastsuccessmsg("OTP Send Successfully")
      return () => clearInterval(countdown);

    }
    else {
      // setLoading(false)
      toasterrormsg(response.message)
    }
  }
  // otp verification api call
  const otpverificaitonapicall = async () => {

    if (changepasswordValidate()) {

      var param: any = {
        otp: otp,
        investorId: vendorId
      }
      var response: any = await fetchData("investorcheckotp", param)

      if (response.status == "200") {

        localStorage.setItem("vendorId", response.data[0]['_id'])
        console.log(response.data);
        setSubTab("password");
        toastsuccessmsg("OTP Verify Successfully")

      }
      else {
        toasterrormsg(response.message)
      }
    }
  }
  // reset password api call
  const resetpasswordapicall = async () => {
    if (changepasswordValidate()) {

      var param: any = {
        investorId: vendorId,
        password: newPassword,
        // confirmPassword:confirmPassword
      }
      var response: any = await fetchData("investorresetpassword", param)

      if (response.status == "200") {
        localStorage.removeItem("resetEmail")
        toastsuccessmsg("Password Change Successfully")
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
      else {
        toasterrormsg(response.message)
      }
    }
  }

  
  //Contry List API
  const getCountry = async () => {

    var res: any = await fetchData('countryList', JSON)

    if (res.status == 200) {
      setCountryList(res.data)
    } else {
      toasterrormsg(res.message)
    }
  }

  //Contry List API
  const getBankList = async () => {

    var res: any = await fetchData('banklist', JSON)

    if (res.status == 200) {
      setbankList(res.data)
    } else {
      toasterrormsg(res.message)
    }
  }

  // change password error state
  const [bankdetailerr, setbankdetailerr] = useState([{
    selectBank: '',
    accountNumber: '',
    ifscCode: '',

  }])

  // remove edit profile validation function
  const removeBankDetailError = (err: any) => {
    var tmp = [...bankdetailerr] as any;
    tmp[0][err] = "";
    setbankdetailerr(tmp);
  }

   // change password validation function
   const bankDetailValidate = () => {

    var isValid = true;
    var errorTmp = [...bankdetailerr];
   
      if (accountNumber == "" || accountNumber == undefined || accountNumber == null || accountNumber.trim() == '') {
        errorTmp[0].accountNumber = 'Account Number is required';
        isValid = false;
      } else if (!/^\d+$/.test(accountNumber)) {
        errorTmp[0].accountNumber = 'Please enter valid Account Number.';
        isValid = false;
      }
   
      if (ifscCode == "" || ifscCode == undefined || ifscCode == null || ifscCode.trim() == '') {
        errorTmp[0].ifscCode = 'IFSC Code is required';
        isValid = false;
      }

      if (selectBank == "" || selectBank == undefined || selectBank == null) {
        errorTmp[0].selectBank = 'Please Select Bank';
        isValid = false;
      }
    
      setbankdetailerr(errorTmp);
    return isValid;
  }

  // forgot password pai call
  const updatebankDetailAPI = async () => {
    if (bankDetailValidate()) {

      var param: any = {
        investorId: vendorId,
        bankId: selectBank,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
      }

      if(investorBankDetailId){
        param.investorBankDetailId = investorBankDetailId
      }
      var response: any = await fetchData("addEditInvestorBankDetail", param)

      if (response.status == "200") {
        
        toastsuccessmsg("Bank Detail Update Successfully")
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
      else {
        // setSubmitting(false)
        toasterrormsg(response.message)
      }
    }
  }

  useEffect(() => {
    profieDetailsApiCall()
    getCountry()
    getBankList()
  }, [])

  return (
    <>
      <ToastContainer />
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav w-100 nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap justify-content-around'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'EditProfile' })}
                onClick={() => setTab('EditProfile')}
                role='tab'
              >
                Edit Profile
              </a>
            </li>

            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'ChangePassword' })}
                onClick={() => setTab('ChangePassword')}
                role='tab'
              >
                Change Password
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'KYCDocument' })}
                onClick={() => setTab('KYCDocument')}
                role='tab'
              >
                KYC Document
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'BankDetail' })}
                onClick={() => setTab('BankDetail')}
                role='tab'
              >
                Bank Detail
              </a>
            </li>
          </ul>
        </div>
        {/* end::Header */}

        {/* begin::Form */}
        <div className='form'>
          {/* begin::Body */}
          <div className='card-body'>
            <div className='tab-content pt-3'>
              <div className={clsx('tab-pane', { active: tab === 'EditProfile' })}>
                <div className='row'>
                  <div className='col-lg'></div>
                  <div className='col-lg-8'>
                    <div className='row mb-10'>
                      {/* <!--begin::Col--> */}
                      <div className='col-lg-12'>
                        {/* <!--begin::Image input--> */}
                        <div
                          className='image-input image-input-outline '
                          data-kt-image-input='true'
                        >
                          {/* <!--begin::Preview existing avatar--> */}

                          <img
                            id='previewImage'
                            className='image-input-wrapper w-125px h-125px changeimg pointer'
                            src={
                              profilePicture
                                ? URL.createObjectURL(profilePicture)
                                : imageUrl === '' || imageUrl === null
                                  ? toAbsoluteUrl('/media/avatars/300-1.jpg')
                                  : imageUrl
                            }
                          />

                          {/* <!--end::Preview existing avatar--> */}
                          {/* <!--begin::Label--> */}
                          <label
                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='change'
                            data-bs-toggle='tooltip'
                            title=''
                            data-bs-original-title='Change avatar'
                          >
                            <i className='fa fa-edit'></i>
                            {/* <!--begin::Inputs--> */}
                            <input
                              type='file'
                              name='image'
                              id='profileImage'
                              accept='.png, .jpg, .jpeg'
                              style={{ display: 'none' }}
                              onChange={(e: any) => {
                                if (e.target.files[0] != null && e.target.files[0] != undefined) {
                                  setProfilePicture(e.target.files[0])
                                  removeEditProfileError('profilePicture')
                                }
                              }}
                            />
                            <input type='hidden' name='avatar_remove' />
                            {/* <!--end::Inputs--> */}
                          </label>
                          {/* <!--end::Label--> */}
                        </div>
                        {/* <!--end::Image input--> */}
                        {/* <!--begin::Hint--> */}
                        <div className='form-text' id='validimage'>
                          Allowed file types: png, jpg, jpeg.
                        </div>
                        {/* <!--end::Hint--> */}
                        <div className='text-danger fw-bold'>
                          {editProfileError[0].profilePicture}
                        </div>
                      </div>
                      {/* <!--end::Col--> */}
                    </div>
                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                        First Name
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='First Name'
                          name='firstName'
                          value={firstName}
                          onChange={(e: any) => {
                            setFirstName(e.target.value)
                            removeEditProfileError('firstName')
                          }}
                        />
                        <div className='text-danger fw-bold'>{editProfileError[0].firstName}</div>
                      </div>
                    </div>
                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                        Last Name
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Last Name'
                          name='lastName'
                          value={lastName}
                          onChange={(e: any) => {
                            setLastName(e.target.value)
                            removeEditProfileError('lastName')
                          }}
                        />
                        <div className='text-danger fw-bold'>{editProfileError[0].lastName}</div>
                      </div>
                    </div>
                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                        Mobile Number
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Mobile Number'
                          name='mobileNumber'
                          value={mobileNumber}
                          onChange={(e: any) => {
                            setMobileNumber(e.target.value)
                            removeEditProfileError('mobileNumber')
                          }}
                        />
                        <div className='text-danger fw-bold'>
                          {editProfileError[0].mobileNumber}
                        </div>
                      </div>
                    </div>
                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                        Email
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Email'
                          name='email'
                          value={email}
                          onChange={(e: any) => {
                            setEmail(e.target.value)
                            removeEditProfileError('email')
                          }}
                        />
                        <div className='text-danger fw-bold'>{editProfileError[0].email}</div>
                      </div>
                    </div>
                    <div className='fv-row mb-8'>
                      <label className='form-label fw-bolder text-dark fs-6'>I am Citizen of</label>
                      <select

                        // name='country'
                        value={selectedCountry}
                        onChange={(e: any) => {
                          setSelectedCountry(e.target.value)

                        }}
                        className='form-control form-control-lg col-6 border border-gray-400 form-control-solid'
                      >
                        <option value=''>Select Country</option>
                        {countryList.map((val: any, index: any) => {
                          return <option value={val['_id']}>{val.name}</option>
                        })}
                      </select>

                      {/* <select
        name="country"
        value={formik.values.country}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={clsx(
          'form-control bg-transparent',
          {'is-invalid': formik.touched.country && formik.errors.country},
          {
            'is-valid': formik.touched.country && !formik.errors.country,
          }
        )}
      >
        <option value="">Select Country</option>
        {countryList.map((val: any) => (
          <option key={val._id} value={val._id}>
            {val.name}
          </option>
        ))}
      </select> */}
                      {/* {formik.touched.country && formik.errors.country && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.country}</span>
            </div>
          </div>
        )} */}

                    </div>

                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                        Address Line 1
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Address Line 1'
                          name='addressLine1'
                          value={addressLine1}
                          onChange={(e: any) => {
                            setAddressLine1(e.target.value)
                            removeEditProfileError('addressLine1')
                          }}
                        />
                        <div className='text-danger fw-bold'>
                          {editProfileError[0].addressLine1}
                        </div>
                      </div>
                    </div>
                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label fw-bold fs-6'>
                        Address Line 2
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Address Line 2'
                          name='addressLine2'
                          value={addressLine2}
                          onChange={(e: any) => {
                            setAddressLine2(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                        Profession
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Profession'
                          name='profession'
                          value={profession}
                          onChange={(e: any) => {
                            setProfession(e.target.value)
                            removeEditProfileError('profession')
                          }}
                        />
                        <div className='text-danger fw-bold'>{editProfileError[0].profession}</div>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-5'>
                        Gender
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <div className='row'>
                          <div className='col-lg-4 col-3 my-2'>
                            <label className='d-flex text-start' data-kt-button='true'>
                              {/* <!--begin::Radio--> */}
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='gender'
                                  value='MALE'
                                  checked={gender === 'MALE'}
                                  onChange={(e: any) => {
                                    setGender(e.target.value)
                                  }}
                                />
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className='ms-5'>
                                <span className='fs-4 fw-bold text-gray-800 d-block'>Male</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                          <div className='col-lg-4 col-3 my-2'>
                            <label className='d-flex text-start' data-kt-button='true'>
                              {/* <!--begin::Radio--> */}
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='gender'
                                  value='FEMALE'
                                  checked={gender === 'FEMALE'}
                                  onChange={(e: any) => {
                                    setGender(e.target.value)
                                  }}
                                />
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className='ms-5'>
                                <span className='fs-4 fw-bold text-gray-800 d-block'>Female</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                          <div className='col-lg-4 col-3 my-2'>
                            <label className='d-flex text-start' data-kt-button='true'>
                              {/* <!--begin::Radio--> */}
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='gender'
                                  value='OTHER'
                                  checked={gender === 'OTHER'}
                                  onChange={(e: any) => {
                                    setGender(e.target.value)
                                  }}
                                />
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className='ms-5'>
                                <span className='fs-4 fw-bold text-gray-800 d-block'>Other</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                        Date Of Birth
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='date'
                          onKeyDown={handleInputKeyDown}
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          max={getCurrentDate()}
                          name='dateOfBirth'
                          value={dateOfBirth}
                          onChange={(e: any) => {
                            setDateOfBirth(e.target.value)
                            removeEditProfileError('dateOfBirth')
                          }}
                        />
                        <div className='text-danger fw-bold'>{editProfileError[0].dateOfBirth}</div>
                      </div>
                    </div>
                    <div className='row mb-10'>
                      <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                        Communication Address
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <textarea
                          name='communicationAddress'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Communication Address'
                          cols={30}
                          rows={4}
                          onChange={(e: any) => {
                            setCommunicationAddress(e.target.value)
                            removeEditProfileError('communicationAddress')
                          }}
                          value={communicationAddress}
                        ></textarea>
                        <div className='text-danger fw-bold'>
                          {editProfileError[0].communicationAddress}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg'></div>
                      <div className='col-lg'>
                        <button
                          type='button'
                          className='btn btn-primary me-2 w-100'
                          onClick={editProfileApiCall}
                        >
                          {!configLoading && <span className='indicator-label'>Save Changes</span>}
                          {configLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className='col-lg'></div>
                    </div>
                  </div>
                  <div className='col-lg'></div>
                </div>
              </div>

              {/* <div className={clsx('tab-pane', { active: tab === 'ChangePassword' })}>
                {
                  subTab == 'mobile' && (
                    <div className="row">
                      <div className="col-lg"></div>
                      <div className="col-lg-8">
                        <div className='row mb-50'>
                          <label className="col-lg-12 col-form-label required fw-bold fs-6">Mobile Number</label>
                          <div className="col-lg-12 fv-row h-250px">
                            <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Mobile Number" name="email" value={changePassword_Email} onChange={(e) => { setChangePassword_Email(e.target.value); removeChangePasswordError('changePassword_Email') }} />
                            <div className='text-danger fw-bold'>{changePasswordError[0].changePassword_Email}</div>
                            <label className="fw-bold text-muted fs-6 mt-5">Verify Mobile Number to change password</label>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-lg"></div>
                          <div className='col-lg'>
                            <button type='button' className='btn btn-primary me-2 w-100' onClick={forgotpasswordapicall}>
                              {!configLoading && <span className='indicator-label'>Get OTP</span>}
                              {configLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="col-lg"></div>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  )
                }
                {
                  subTab == 'otp' && (
                    <div className="row">
                      <div className="col-lg"></div>
                      <div className="col-lg-8">
                        <div className='row mb-50'>
                          <label className="col-lg-12 col-form-label required fw-bold fs-6">Enter OTP</label>
                          <label className="col-lg-12 col-form-label fw-bold fs-6">OTP - {tempotp}</label>
                          <div className="col-lg-12 fv-row">
                            
                            <div className="d-flex flex-wrap flex-stack justify-content-around">
                              <input type="text" name="code_1" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_1} onChange={(e) => { setCode_1(e.target.value); handleInputChange(e, setCode_1); removeChangePasswordError('otp'); }} onKeyPress={handleKeyPress} />
                              <input type="text" name="code_2" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_2} onChange={(e) => { setCode_2(e.target.value); handleInputChange(e, setCode_2); removeChangePasswordError('otp'); }} onKeyPress={handleKeyPress} />
                              <input type="text" name="code_3" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_3} onChange={(e) => { setCode_3(e.target.value); handleInputChange(e, setCode_3); removeChangePasswordError('otp'); }} onKeyPress={handleKeyPress} />
                              <input type="text" name="code_4" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_4} onChange={(e) => { setCode_4(e.target.value); handleInputChange(e, setCode_4); removeChangePasswordError('otp'); }} onKeyPress={handleKeyPress} />
                            </div>
                            <div className='text-danger fw-bold'>{changePasswordError[0].otp}</div>
                         
                          </div>
                          <div className="col-lg-12 h-250px">
                            <div className='mt-5 text-dark fw-bold'>
                              {timer === 0 ? (
                                <>
                                  Didn’t get OTP?<Link to='#' onClick={resendOTPApiCall}> Resend OTP</Link>
                                </>
                              ) : (
                                // <p>Resend OTP in {timer} seconds</p>
                                <p>Resend OTP in {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60} minutes</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-lg"></div>
                          <div className='col-lg'>
                            <button type='button' className='btn btn-primary me-2 w-100' onClick={otpverificaitonapicall}>
                              {!configLoading && <span className='indicator-label'>Verify OTP</span>}
                              {configLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="col-lg"></div>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  )
                }
                {
                  subTab == 'password' && (
                    <div className="row">
                      <div className="col-lg"></div>
                      <div className="col-lg-8">
                        <div className='row mb-10'>
                          <label className="col-lg-12 col-form-label required fw-bold fs-6">New Password</label>
                          <div className="col-lg-12 fv-row" style={{ position: "relative" }}>
                            <input type={passwordDisplay == true ? "text" : "password"} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="New Password" name="newPassword" value={newPassword} onChange={(e) => { setNewPassword(e.target.value); removeChangePasswordError("newPassword") }} />
                            <span className="cursor-pointer" style={{ position: "absolute", top: "10%", right: "20px" }} title={passwordDisplay == true ? "Hide" : "Show"} onClick={() => setPasswordDisplay(!passwordDisplay)}><FontAwesomeIcon icon={passwordDisplay == true ? faEyeSlash : faEye} /></span>
                            <div className='text-danger fw-bold'>{changePasswordError[0].newPassword}</div>
                          </div>
                        </div>
                        <div className='row mb-10' style={{ position: "relative" }}>
                          <label className="col-lg-12 col-form-label required fw-bold fs-6">Confirm Password</label>
                          <div className="col-lg-12 fv-row h-150px" style={{ position: "relative" }}>
                            <input type={cnfpasswordDisplay == true ? "text" : "password"} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-danger" placeholder="Confirm Password" name="confirmPassword" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); removeChangePasswordError("confirmPassword") }} />
                            <span className="cursor-pointer" style={{ position: "absolute", top: "10%", right: "20px" }} title={cnfpasswordDisplay == true ? "Hide" : "Show"} onClick={() => setcnfPasswordDisplay(!cnfpasswordDisplay)}><FontAwesomeIcon icon={cnfpasswordDisplay == true ? faEyeSlash : faEye} /></span>
                            <div className='text-danger fw-bold'>{changePasswordError[0].confirmPassword}</div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-lg"></div>
                          <div className='col-lg'>
                            <button type='button' className='btn btn-primary me-2 w-100' onClick={resetpasswordapicall}>
                              {!configLoading && <span className='indicator-label'>Save Changes</span>}
                              {configLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="col-lg"></div>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  )
                }

              </div> */}
              <div className={clsx('tab-pane', { active: tab === 'ChangePassword' })}>
                {
                  subTab == 'mobile' && (
                    <div className="row">
                      <div className="col-lg"></div>
                      <div className="col-lg-8">
                        <div className='row mb-50'>
                          <label className="col-lg-12 col-form-label required fw-bold fs-6">Email</label>
                          <div className="col-lg-12 fv-row h-250px">
                            <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="email" name="email" value={changePassword_Email} onChange={(e) => { setChangePassword_Email(e.target.value); removeChangePasswordError('changePassword_Email') }} />
                            <div className='text-danger fw-bold'>{changePasswordError[0].changePassword_Email}</div>
                            <label className="fw-bold text-muted fs-6 mt-5">Verify Email to change password</label>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-lg"></div>
                          <div className='col-lg'>
                            <button type='button' className='btn btn-primary me-2 w-100' onClick={forgotpasswordapicall}>
                              {!configLoading && <span className='indicator-label'>Get OTP</span>}
                              {configLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="col-lg"></div>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  )
                }
                {
                  subTab == 'otp' && (
                    <div className="row">
                      <div className="col-lg"></div>
                      <div className="col-lg-8">
                        <div className='row mb-50'>
                          <label className="col-lg-12 col-form-label required fw-bold fs-6">Enter OTP</label>
                          {/* <label className="col-lg-12 col-form-label fw-bold fs-6">OTP - {tempotp}</label> */}
                          <div className="col-lg-12 fv-row">
                            {/* <!--begin::Input group--> */}
                            <div className="d-flex flex-wrap flex-stack justify-content-around">
                              <input type="text" name="code_1" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_1} onChange={(e) => { setCode_1(e.target.value); handleInputChange(e, setCode_1); removeChangePasswordError('otp'); }} onKeyPress={handleKeyPress} />
                              <input type="text" name="code_2" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_2} onChange={(e) => { setCode_2(e.target.value); handleInputChange(e, setCode_2); removeChangePasswordError('otp'); }} onKeyPress={handleKeyPress} />
                              <input type="text" name="code_3" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_3} onChange={(e) => { setCode_3(e.target.value); handleInputChange(e, setCode_3); removeChangePasswordError('otp'); }} onKeyPress={handleKeyPress} />
                              <input type="text" name="code_4" maxLength={1} className="form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2" value={code_4} onChange={(e) => { setCode_4(e.target.value); handleInputChange(e, setCode_4); removeChangePasswordError('otp'); }} onKeyPress={handleKeyPress} />
                            </div>
                            <div className='text-danger fw-bold'>{changePasswordError[0].otp}</div>
                            {/* <!--begin::Input group--> */}
                          </div>
                          <div className="col-lg-12 h-250px">
                            <div className='mt-5 text-dark fw-bold'>
                              {timer === 0 ? (
                                <>
                                  Didn’t get OTP?<Link to='#' onClick={resendOTPApiCall}> Resend OTP</Link>
                                </>
                              ) : (
                                // <p>Resend OTP in {timer} seconds</p>
                                <p>Resend OTP in {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60} minutes</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-lg"></div>
                          <div className='col-lg'>
                            <button type='button' className='btn btn-primary me-2 w-100' onClick={otpverificaitonapicall}>
                              {!configLoading && <span className='indicator-label'>Verify OTP</span>}
                              {configLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="col-lg"></div>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  )
                }
                {
                  subTab == 'password' && (
                    <div className="row">
                      <div className="col-lg"></div>
                      <div className="col-lg-8">
                        <div className='row mb-10'>
                          <label className="col-lg-12 col-form-label required fw-bold fs-6">New Password</label>
                          <div className="col-lg-12 fv-row" style={{ position: "relative" }}>
                            <input type={passwordDisplay == true ? "text" : "password"} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="New Password" name="newPassword" value={newPassword} onChange={(e) => { setNewPassword(e.target.value); removeChangePasswordError("newPassword") }} />
                            <span className="cursor-pointer" style={{ position: "absolute", top: "10%", right: "20px" }} title={passwordDisplay == true ? "Hide" : "Show"} onClick={() => setPasswordDisplay(!passwordDisplay)}><FontAwesomeIcon icon={passwordDisplay == true ? faEyeSlash : faEye} /></span>
                            <div className='text-danger fw-bold'>{changePasswordError[0].newPassword}</div>
                          </div>
                        </div>
                        <div className='row mb-10' style={{ position: "relative" }}>
                          <label className="col-lg-12 col-form-label required fw-bold fs-6">Confirm Password</label>
                          <div className="col-lg-12 fv-row h-150px" style={{ position: "relative" }}>
                            <input type={cnfpasswordDisplay == true ? "text" : "password"} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-danger" placeholder="Confirm Password" name="confirmPassword" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); removeChangePasswordError("confirmPassword") }} />
                            <span className="cursor-pointer" style={{ position: "absolute", top: "10%", right: "20px" }} title={cnfpasswordDisplay == true ? "Hide" : "Show"} onClick={() => setcnfPasswordDisplay(!cnfpasswordDisplay)}><FontAwesomeIcon icon={cnfpasswordDisplay == true ? faEyeSlash : faEye} /></span>
                            <div className='text-danger fw-bold'>{changePasswordError[0].confirmPassword}</div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-lg"></div>
                          <div className='col-lg'>
                            <button type='button' className='btn btn-primary me-2 w-100' onClick={resetpasswordapicall}>
                              {!configLoading && <span className='indicator-label'>Save Changes</span>}
                              {configLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="col-lg"></div>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  )
                }

              </div>

              <div className={clsx('tab-pane', { active: tab === 'KYCDocument' })}>
                <div className='row mb-10'>
                  {/* <div className="col-lg-2">
                    <div className='overflow-auto'>
                    <ul
                      className='nav w-100 nav-stretch nav-line-tabs fw-bold border-transparent'
                      role='tablist'
                    >
                      <li className='nav-item my-3'>
                        <a
                          className={clsx(`nav-link cursor-pointer`, {active: kycTab === 'BusinessDocument'})}
                          onClick={() => setKycTab('BusinessDocument')}
                          role='tab'
                        >
                          Business Document
                        </a>
                      </li>
                      <li className='nav-item my-3'>
                        <a
                          className={clsx(`nav-link cursor-pointer`, {active: kycTab === 'OwnerDocument'})}
                          onClick={() => setKycTab('OwnerDocument')}
                          role='tab'
                        >
                          Owner Document
                        </a>
                      </li>
                    </ul>
                    </div>
                  </div> */}
                  <div>
                    {vendor.isVerified == '0' ? (
                      <div className='text-center badge badge-light-primary fs-5'>
                        KYC Document Pending
                      </div>
                    ) : vendor.isVerified == '1' ? (
                      <div className='text-center badge badge-light-success fs-5'>
                        KYC Document Approved
                      </div>
                    ) : (
                      <>
                        <div className='text-center badge badge-light-danger fs-5'>
                          KYC Document Rejected
                        </div>
                        <div className='mt-5 '>
                          <div
                            className='dropzone dz-clickable'
                            style={{ border: '1px dashed  red' }}
                            id='kt_ecommerce_add_frontPanCard'
                          >
                            {/* <!--begin::Message--> */}
                            <div className='dz-message needsclick'>
                              {/* <!--begin::Icon--> */}
                              {/* <i className='ki-outline ki-file-up text-primary fs-3x'></i> */}

                              <FontAwesomeIcon
                                className='ki-outline ki-file-up text-danger fs-3x'
                                icon={faCircleInfo}
                              />
                              {/* <!--end::Icon--> */}
                              {/* <!--begin::Info--> */}
                              <div className='ms-4'>
                                <h3 className='fs-5 fw-bold text-gray-900 mb-1'>Reject Reason</h3>
                                <span className='fs-7 fw-semibold '>{vendor.reason}</span>
                              </div>
                              {/* <!--end::Info--> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className='col-lg-10'>
                    <>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='mt-5'>
                            <FileUploader
                              multiple={false}
                              handleChange={handleFrontPanCardChange}
                              name='frontPanCard'
                              types={fileTypes}
                              classes='w-100'
                            >
                              <div
                                className='dropzone dz-clickable'
                                id='kt_ecommerce_add_frontPanCard'
                                onClick={() => handleDivClick('frontPanCard')}
                              >
                                {/* <input type="file" name='frontPanCard' id='frontPanCard' style={{ display: 'none' }} onChange={(e:any)=>{if(e.target.files[0]!=null && e.target.files[0]!=undefined){setFrontPanCard(e.target.files[0]);removeOwnerDocuemnt("frontPanCard");}}}/> */}
                                {/* <input type="file" name='frontPanCard' id='frontPanCard' style={{ display: 'none' }} onChange={handleFrontPanCardChange}/> */}
                                {/* <!--begin::Message--> */}
                                <div className='dz-message needsclick'>
                                  {/* <!--begin::Icon--> */}
                                  <i className='ki-outline ki-file-up text-primary fs-3x'></i>
                                  {/* <!--end::Icon--> */}
                                  {/* <!--begin::Info--> */}
                                  <div className='ms-4'>
                                    <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                      Upload Front PAN Card
                                    </h3>
                                    <span className='fs-7 fw-semibold text-gray-500'>
                                      Drag & Drop or choose files from computer
                                    </span>
                                  </div>
                                  {/* <!--end::Info--> */}
                                </div>
                              </div>
                            </FileUploader>
                          </div>
                          <div className='text-danger fw-bold'>
                            {ownerDocumentError[0].frontPanCard}
                          </div>
                          <div className='row mt-3'>
                            {frontPanCard ? (
                              <div className='mb-5'>
                                {frontPanCard.type === 'application/pdf' ? (
                                  <div className=''>
                                    <p className='fs-4'>
                                      {' '}
                                      <FontAwesomeIcon icon={faFile} className='mx-2' />
                                      {frontPanCard.name}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      maxWidth: 'fit-content',
                                      width: '200px',
                                      height: '200px',
                                    }}
                                  >
                                    {frontPanCard.type.startsWith('image/')}
                                    <img
                                      src={URL.createObjectURL(frontPanCard)}
                                      width='100%'
                                      style={{ width: '200px', height: '200px' }}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <>
                                {frontPan ? (
                                  <div className='mb-5'>
                                    {frontPanExt == 'pdf' ? (
                                      <a href={frontPan} target='_blank'>
                                        <p className='fs-3'>
                                          {' '}
                                          <FontAwesomeIcon icon={faFile} className='mx-2' />
                                          {'PanCard Document'}
                                        </p>
                                      </a>
                                    ) : (
                                      <div className='col-lg-4 col-3 d-flex flex-column mb-5'>
                                        <div
                                          style={{
                                            maxWidth: 'fit-content',
                                            width: '200px',
                                            height: '200px',
                                          }}
                                        >
                                          <img
                                            src={frontPan}
                                            width='100%'
                                            style={{ width: '200px', height: '200px' }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='mt-5'>
                            <FileUploader
                              multiple={false}
                              handleChange={handleFrontAdharCardChange}
                              name='frontAdharCard'
                              types={fileTypes}
                              classes='w-100'
                            >
                              <div
                                className='dropzone dz-clickable'
                                id='kt_ecommerce_add_frontAadharCard'
                                onClick={() => handleDivClick('frontAadharCard')}
                              >
                                {/* <input type="file" name='frontAadharCard' id='frontAadharCard' style={{ display: 'none' }} onChange={(e:any)=>{if(e.target.files[0]!=null && e.target.files[0]!=undefined){setFrontAadharCard(e.target.files[0]);removeOwnerDocuemnt("frontAadharCard");}}}/> */}
                                {/* <input type="file" name='frontAadharCard' id='frontAadharCard' style={{ display: 'none' }} onChange={handleFrontAdharCardChange}/> */}
                                {/* <!--begin::Message--> */}
                                <div className='dz-message needsclick'>
                                  {/* <!--begin::Icon--> */}
                                  <i className='ki-outline ki-file-up text-primary fs-3x'></i>
                                  {/* <!--end::Icon--> */}
                                  {/* <!--begin::Info--> */}
                                  <div className='ms-4'>
                                    <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                      Upload Front Aadhar Card
                                    </h3>
                                    <span className='fs-7 fw-semibold text-gray-500'>
                                      Drag & Drop or choose files from computer
                                    </span>
                                  </div>
                                  {/* <!--end::Info--> */}
                                </div>
                              </div>
                            </FileUploader>
                          </div>
                          <div className='text-danger fw-bold'>
                            {ownerDocumentError[0].frontAadharCard}
                          </div>
                          <div className='row mt-3'>
                            {frontAadharCard ? (
                              <div className='mb-5'>
                                {frontAadharCard.type.startsWith('application/') ? (
                                  <div className=''>
                                    <p className='fs-4'>
                                      {' '}
                                      <FontAwesomeIcon icon={faFile} className='mx-2' />
                                      {frontAadharCard.name}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      maxWidth: 'fit-content',
                                      width: '200px',
                                      height: '200px',
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(frontAadharCard)}
                                      width='100%'
                                      style={{ width: '200px', height: '200px' }}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <>
                                {frontAadhar ? (
                                  <div className='mb-5'>
                                    {frontAadharExt == 'pdf' ? (
                                      <a href={frontAadhar} target='_blank'>
                                        <p className='fs-3'>
                                          {' '}
                                          <FontAwesomeIcon icon={faFile} className='mx-2' />
                                          {'Front AadharCard Document'}
                                        </p>
                                      </a>
                                    ) : (
                                      <div className='col-lg-4 col-3 d-flex flex-column mb-5'>
                                        <div
                                          style={{
                                            maxWidth: 'fit-content',
                                            width: '200px',
                                            height: '200px',
                                          }}
                                        >
                                          <img
                                            src={frontAadhar}
                                            width='100%'
                                            style={{ width: '200px', height: '200px' }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='mt-5'>
                            <FileUploader
                              multiple={false}
                              handleChange={handleBackAdharCardChange}
                              name='backAdharCard'
                              types={fileTypes}
                              classes='w-100'
                            >
                              <div
                                className='dropzone dz-clickable'
                                id='kt_ecommerce_add_backAadharCard'
                                onClick={() => handleDivClick('backAadharCard')}
                              >
                                {/* <input type="file" name='backAadharCard' id='backAadharCard' style={{ display: 'none' }} onChange={(e:any)=>{if(e.target.files[0]!=null && e.target.files[0]!=undefined){setBackAadharCard(e.target.files[0]);removeOwnerDocuemnt("backAadharCard");}}}/> */}
                                {/* <input type="file" name='backAadharCard' id='backAadharCard' style={{ display: 'none' }} onChange={handleBackAdharCardChange}/> */}
                                {/* <!--begin::Message--> */}
                                <div className='dz-message needsclick'>
                                  {/* <!--begin::Icon--> */}
                                  <i className='ki-outline ki-file-up text-primary fs-3x'></i>
                                  {/* <!--end::Icon--> */}
                                  {/* <!--begin::Info--> */}
                                  <div className='ms-4'>
                                    <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                      Upload Back Aadhar Card
                                    </h3>
                                    <span className='fs-7 fw-semibold text-gray-500'>
                                      Drag & Drop or choose files from computer
                                    </span>
                                  </div>
                                  {/* <!--end::Info--> */}
                                </div>
                              </div>
                            </FileUploader>
                          </div>
                          <div className='text-danger fw-bold'>
                            {ownerDocumentError[0].backAadharCard}
                          </div>
                          <div className='row mt-3'>
                            {backAadharCard ? (
                              <div className='mb-5'>
                                {backAadharCard.type === 'application/pdf' ? (
                                  <div className=''>
                                    <p className='fs-4'>
                                      {' '}
                                      <FontAwesomeIcon icon={faFile} className='mx-2' />
                                      {backAadharCard.name}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      maxWidth: 'fit-content',
                                      width: '200px',
                                      height: '200px',
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(backAadharCard)}
                                      width='100%'
                                      style={{ width: '200px', height: '200px' }}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <>
                                {backAadhar ? (
                                  <div className='mb-5'>
                                    {backAadharExt == 'pdf' ? (
                                      <a href={backAadhar} target='_blank'>
                                        <p className='fs-3'>
                                          {' '}
                                          <FontAwesomeIcon icon={faFile} className='mx-2' />
                                          {'Back AadharCard Document'}
                                        </p>
                                      </a>
                                    ) : (
                                      <div className='col-lg-4 col-3 d-flex flex-column mb-5'>
                                        <div
                                          style={{
                                            maxWidth: 'fit-content',
                                            width: '200px',
                                            height: '200px',
                                          }}
                                        >
                                          <img
                                            src={backAadhar}
                                            width='100%'
                                            style={{ width: '200px', height: '200px' }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='row mt-5'>
                        <div className='col-lg'>
                          <button
                            type='button'
                            className='btn btn-primary me-2 w-100'
                            onClick={ownerDocumentApi}
                          >
                            {!configLoading && (
                              <span className='indicator-label'>Save Changes</span>
                            )}
                            {configLoading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                        <div className='col-lg-8'></div>
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <div className={clsx('tab-pane', { active: tab === 'BankDetail' })}>
                {

                  <div className="row">
                    <div className="col-lg"></div>
                    <div className="col-lg-8">
                      <div className='row mb-5'>
                        <label className="col-lg-12 col-form-label required fw-bold fs-6">Bank Name</label>
                        <div className="col-lg-12 fv-row">
                          <select
                            value={selectBank}
                            onChange={(e: any) => {
                              setselectBank(e.target.value)
                              removeChangePasswordError('selectBank')
                            }}
                            className='form-control form-control-lg col-6 border border-gray-400 form-control-solid'
                          >
                            <option value=''>Select Bank</option>
                            {bankList.map((val: any, index: any) => {
                              return <option  key={index} value={val['_id']}>{val.bank}</option>
                            })}
                          </select><div className='text-danger fw-bold'>{bankdetailerr[0].selectBank}</div>

                        </div>
                      </div>

                      <div className='row mb-5'>
                        <label className="col-lg-12 col-form-label required fw-bold fs-6">Account Number</label>
                        <div className="col-lg-12 fv-row">
                          <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Account Number" name="Account Number" value={accountNumber} onChange={(e) => { setaccountNumber(e.target.value); removeBankDetailError('accountNumber') }} />
                          <div className='text-danger fw-bold'>{bankdetailerr[0].accountNumber}</div>
                        </div>
                      </div>

                      <div className='row mb-5'>
                        <label className="col-lg-12 col-form-label required fw-bold fs-6">IFSC Code</label>
                        <div className="col-lg-12 fv-row">
                          <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="IFSC Code" name="email" value={ifscCode} onChange={(e) => { setifscCode(e.target.value); removeBankDetailError('ifscCode') }} />
                          <div className='text-danger fw-bold'>{bankdetailerr[0].ifscCode}</div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-lg"></div>
                        <div className='col-lg'>
                          <button type='button' className='btn btn-primary me-2 w-100' onClick={updatebankDetailAPI}>
                            {!configLoading && <span className='indicator-label'>Save Changes</span>}
                            {configLoading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                        <div className="col-lg"></div>
                      </div>
                    </div>
                    <div className="col-lg"></div>
                  </div>

                }

              </div>

            </div>
          </div>
          {/* end::Body */}

          {/* begin::Footer */}
          {/* <div className='card-footer py-6'>
            <div className='row'>
              <div className='col-lg-3'></div>
              <div className='col-lg-9'>
                <button type='button' onClick={updateConfig} className='btn btn-primary me-2'>
                  {!configLoading && <span className='indicator-label'>Preview</span>}
                  {configLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>

                <button
                  type='button'
                  id='kt_layout_builder_reset'
                  className='btn btn-active-light btn-color-muted'
                  onClick={reset}
                >
                  {!resetLoading && <span className='indicator-label'>Reset</span>}
                  {resetLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div> */}
          {/* end::Footer */}
        </div>
        {/* end::Form */}
      </div>
    </>
  )
}

export { ProfilePage }
