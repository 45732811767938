/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Card7 } from '../../../_metronic/partials/content/cards/Card7'
import { FeedsWidget7 } from '../../../_metronic/partials/widgets'
import { EditorState, convertToRaw  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Select from 'react-select';
import { fetchData, fetchFormData, toasterrormsg, toastsuccessmsg } from '../../../reusable'
import { ToastContainer } from 'react-toastify'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function AddProject() {

  useEffect(() => {
    document.title = 'Financer | Add Project';
  }, []);
  // state start
  var vendorId = "";
  const vendorData = sessionStorage.getItem("vendor");
  if (vendorData) {
      var vendor = JSON.parse(vendorData);
      vendorId = vendor.vendorId;
  } else {
      console.log("No vendor data found");
  }
  const inputfileRef=useRef(null);
  const navigate=useNavigate()
  const [imageUrl,setImageUrl]=useState("");
  const [profilePicture, setProfilePicture] = useState();
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  
  const [productName,setProductName]=useState("");
  const [totalSizeOfInvestmentOpportunity,setTotalSizeOfInvestmentOpportunity]=useState("");
  const [payout,setPayout]=useState("FIXED_AMOUNT");   // FIXED_AMOUNT,PERCENTAGE,VARIABLE
  const [roiPercentage,setRoiPercentage]=useState("");
  const [irrPercentage,setIrrPercentage]=useState("");
  const [exitPeriod,setExitPeriod]=useState("0");
  const [exitPeriodType,setExitPeriodType]=useState("MONTH");
  const [lockInPeriod,setLockInPeriod]=useState("0");
  const [lockInPeriodType,setLockInPeriodType]=useState("MONTH");
  const [tenure,setTenure]=useState("0");
  const [tenureType,setTenureType]=useState("MONTH");
  const [isProductRenewable,setIsProductRenewable]=useState("true");
  const [tdsDeduct,setTdsDeduct]=useState("true");
  const [tdsPercentage,setTdsPercentage]=useState("");
  const [maxAmount,setMaxAmount]=useState("");
  const [minAmount,setMinAmount]=useState("");
  const [xscore,setXscore]=useState("");
  const [productDescription, setProductDescription] = useState(() => EditorState.createEmpty()); // Set initial state with an empty EditorState
  const [blankImage,setBlabkImage]=useState([]);
  const [inputImage,setInputImage]=useState([]) as any;
  const [inputVideo,setInputVideo]=useState([]) as any;
  const [inputDocument,setInputDocument]=useState([]) as any;
  
  // const getContentAsText = () => {
  //   const contentState = productDescription.getCurrentContent();
  //   const rawContentState = convertToRaw(contentState);
  //   const text = rawContentState.blocks.map(block => block.text).join('\n');
  //   return text;
  // };

  const getContentAsHtml = () => {
    const contentState = productDescription.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    const contentText = contentRaw.blocks.map(block => block.text).join('').trim(); // Get the text content and trim any whitespace
    if (contentText) { // Check if content is not empty
      return draftToHtml(contentRaw); // Convert EditorState to HTML
    }
    return null; // Return null if content is empty
  };
  var projectDescription = getContentAsHtml() || "";

  // business category list state start
  const [businessCategoryList,setBusinessCategoryList] = useState([]);
  const [businessCategorySelectedOptions,setBusinessCategorySelectedOptions] = useState<any>([]);
  var businessCategory = businessCategorySelectedOptions.value;
  // business category list state end
  
  // risk rating list state start
  const [riskRatingList,setRiskRatingListt] = useState([
    { value: 'HIGH', label: 'HIGH' },
    { value: 'MEDIUM', label: 'MEDIUM' },
    { value: 'LOW', label: 'LOW' },
  ]);
  const [riskRatingSelectedOptions,setRiskRatingSelectedOptions] = useState<any>([]);
  var riskRating = riskRatingSelectedOptions.value;
  // risk rating list state end
  // state end

  // upload images and docs,videos
  const handleDivClick = (inputId: string) => {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
        inputElement.click();
    }
  }

  // set error state
  const [error,setError]=useState([{
    profilePicture:'',
    productName:'',
    businessCategory:'',
    totalSizeOfInvestmentOpportunity:'',
    roiPercentage:'',
    irrPercentage:'',
    minAmount:'',
    maxAmount:'',
    riskRating:'',
    exitPeriod:'',
    exitPeriodType:'',
    lockInPeriod:'',
    lockInPeriodType:'',
    tenure:'',
    tenureType:'',
    tdsPercentage:'',
    productDescription:'',
    inputImage:'',
    xscore:'',
  }])

  // remove validation function
  const removeError = (err:any) =>{
    var tmp = [...error] as any;
    tmp[0][err] = "";
    setError(tmp);
  }

  // // store selected image in state
  // const handleImageChange = (e: any) => {
  //   removeError("inputImage");
  //   const selectedFiles = Array.from(e.target.files);
  //   const filePaths = selectedFiles.map((file) => file); // Store the selected file names as paths
  //   setInputImage((prevImages:any) => [...prevImages, ...filePaths]); // Append new images to the existing array of images
  // };
  // store selected image in state
  const handleImageChange = (e: any) => {
    removeError("inputImage");
    const selectedFiles = Array.from(e.target.files);
    
    // Check if adding selected files will exceed the limit
    if (inputImage.length + selectedFiles.length > 4) {
      // If it exceeds, only select files up to the limit
      const remainingCapacity = 4 - inputImage.length;
      const limitedFiles = selectedFiles.slice(0, remainingCapacity);
      
      // Display a message or perform an action to notify the user about the limit
      // console.log("Maximum 4 images can be uploaded.");
      toasterrormsg("Maximum 4 images can be uploaded.")

      // Store the selected file names as paths
      const filePaths = limitedFiles.map((file) => file);

      // Append new images to the existing array of images
      setInputImage((prevImages: any) => [...prevImages, ...filePaths]);
    } else {
      // If it doesn't exceed, proceed as before
      const filePaths = selectedFiles.map((file) => file);
      setInputImage((prevImages: any) => [...prevImages, ...filePaths]);
    }
  };

  // // store selected video in state
  // const handleVideoChange = (e:any) => {
  //     // removeError("inputVideo");
  //     const selectedFiles = Array.from(e.target.files);
  //     const filePaths = selectedFiles.map((file) => file); // Store the selected file names as paths
  //     setInputVideo((prevImages:any) => [...prevImages, ...filePaths]); // Store the array of video paths
  // };
  // store selected video in state
  const handleVideoChange = (e:any) => {
      // removeError("inputVideo");
      const selectedFiles = Array.from(e.target.files);
    
      // Check if adding selected files will exceed the limit
      if (inputVideo.length + selectedFiles.length > 4) {
        // If it exceeds, only select files up to the limit
        const remainingCapacity = 4 - inputVideo.length;
        const limitedFiles = selectedFiles.slice(0, remainingCapacity);
        
        // Display a message or perform an action to notify the user about the limit
        // console.log("Maximum 4 videos can be uploaded.");
        toasterrormsg("Maximum 4 videos can be uploaded.")

        // Store the selected file names as paths
        const filePaths = limitedFiles.map((file) => file);

        // Append new videos to the existing array of videos
        setInputVideo((prevVideos:any) => [...prevVideos, ...filePaths]);
      } else {
        // If it doesn't exceed, proceed as before
        const filePaths = selectedFiles.map((file) => file);
        setInputVideo((prevVideos:any) => [...prevVideos, ...filePaths]);
      }
  };

  
  // // store selected document in state
  // const handleDocumentChange = (e:any) => {
  //     // removeError("inputDocument");
  //     const selectedFiles = Array.from(e.target.files);
  //     const filePaths = selectedFiles.map((file) => file); // Store the selected file names as paths
  //     setInputDocument((prevImages:any) => [...prevImages, ...filePaths]); // Store the array of document paths
  // };
  // store selected document in state
  const handleDocumentChange = (e:any) => {
      // removeError("inputDocument");
      const selectedFiles = Array.from(e.target.files);
    
      // Check if adding selected files will exceed the limit
      if (inputDocument.length + selectedFiles.length > 4) {
        // If it exceeds, only select files up to the limit
        const remainingCapacity = 4 - inputDocument.length;
        const limitedFiles = selectedFiles.slice(0, remainingCapacity);
        
        // Display a message or perform an action to notify the user about the limit
        // console.log("Maximum 4 documents can be uploaded.");
        toasterrormsg("Maximum 4 documents can be uploaded.")

        // Store the selected file names as paths
        const filePaths = limitedFiles.map((file) => file);

        // Append new documents to the existing array of documents
        setInputDocument((prevDocuments:any) => [...prevDocuments, ...filePaths]);
      } else {
        // If it doesn't exceed, proceed as before
        const filePaths = selectedFiles.map((file) => file);
        setInputDocument((prevDocuments:any) => [...prevDocuments, ...filePaths]);
      }
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = (numPages:any) => {
    setNumPages(numPages);
  };
  // function for remove selected images
  const handleRemove1 = async (index1:any) => {
      let tmparrimg = inputImage.filter((item:any,index:any) => index != index1)
      setInputImage(tmparrimg);
  };
  // function for remove selected videos
  const handleRemove2 = async (index1:any) => {
      let tmparrimg = inputVideo.filter((item:any,index:any) => index != index1)
      setInputVideo(tmparrimg);
  };
  // function for remove selected documents
  const handleRemove3 = async (index1:any) => {
      let tmparrimg = inputDocument.filter((item:any,index:any) => index != index1)
      setInputDocument(tmparrimg);
  };

  //  business category list api call
  const categoryListAPIcall=async()=>{
        
      var res=await fetchData("categoryList",[])
      
      if(res.status=="200")
      {
          var opt:any=[]
          res.data.map((val:any)=>{
              opt.push({value:val._id,label:val.name})
          })
          setBusinessCategoryList(opt)
      }
      else
      {
          toasterrormsg(res.message)
      }
  }
  
  
  const validate = ()=>{

    var isValid = true;
    var errorTmp = [...error];

    if(profilePicture=="" || profilePicture==null || profilePicture == undefined)
    {
      errorTmp[0].profilePicture = 'Profile Picture is required';
      isValid = false;
    }
    if(inputImage=="" || inputImage==null || inputImage == undefined)
    {
      errorTmp[0].inputImage = 'Image is required';
      isValid = false;
    }
    if(productName=="" || productName==null || productName == undefined)
    {
      errorTmp[0].productName = 'Product Name is required';
      isValid = false;
    }
    if(businessCategory=="" || businessCategory==null || businessCategory == undefined)
    {
      errorTmp[0].businessCategory = 'Business Category is required';
      isValid = false;
    }
    if(totalSizeOfInvestmentOpportunity != "" && totalSizeOfInvestmentOpportunity <= '0'){
      errorTmp[0].totalSizeOfInvestmentOpportunity = 'Size Must be greater than 0';
      isValid = false;
    }
    if(roiPercentage=="" || roiPercentage==null || roiPercentage == undefined)
    {
      errorTmp[0].roiPercentage = 'ROI is required';
      isValid = false;
    }else if(roiPercentage <= '0'){
      errorTmp[0].roiPercentage = 'ROI Must be greater than 0';
      isValid = false;
    }
    if(minAmount=="" || minAmount==null || minAmount == undefined)
    {
      errorTmp[0].minAmount = 'Minimum Investment Amount is required';
      isValid = false;
    }else if(minAmount <= '0'){
      errorTmp[0].minAmount = 'Minimum Investment Amount Must be greater than 0';
      isValid = false;
    }
    if(maxAmount === "" || maxAmount === null || maxAmount === undefined) {
      errorTmp[0].maxAmount = 'Maximum Investment Amount is required';
      isValid = false;
    } else if(Number(maxAmount) <= 0) {
        errorTmp[0].maxAmount = 'Maximum Investment Amount Must be greater than 0';
        isValid = false;
    } else if(Number(maxAmount) <= Number(minAmount)) {
        errorTmp[0].maxAmount = 'Maximum Amount Must be greater than Minimum Amount';
        isValid = false;
    }
    if(irrPercentage=="" || irrPercentage==null || irrPercentage == undefined)
    {
      errorTmp[0].irrPercentage = 'IRR is required';
      isValid = false;
    }else if(irrPercentage <= '0'){
      errorTmp[0].irrPercentage = 'IRR Must be greater than 0';
      isValid = false;
    }
    if(xscore=="" || xscore==null || xscore == undefined)
    {
      errorTmp[0].xscore = 'Xscore is required';
      isValid = false;
    }else if(xscore <= '0'){
      errorTmp[0].xscore = 'Xscore Must be greater than 0';
      isValid = false;
    }
    if(riskRating=="" || riskRating==null || riskRating == undefined)
    {
      errorTmp[0].riskRating = 'Risk Rating is required';
      isValid = false;
    }
    if(exitPeriod=="" || exitPeriod==null || exitPeriod == undefined)
    {
      errorTmp[0].exitPeriod = 'Exit Period is required';
      isValid = false;
    }
    if(exitPeriodType=="" || exitPeriodType==null || exitPeriodType == undefined)
    {
      errorTmp[0].exitPeriodType = 'Exit Period Type is required';
      isValid = false;
    }
    if(lockInPeriod=="" || lockInPeriod==null || lockInPeriod == undefined)
    {
      errorTmp[0].lockInPeriod = 'Lock-In Period is required';
      isValid = false;
    }
    if(lockInPeriodType=="" || lockInPeriodType==null || lockInPeriodType == undefined)
    {
      errorTmp[0].lockInPeriodType = 'Lock-In Period Type is required';
      isValid = false;
    }
    if(tenure=="" || tenure==null || tenure == undefined)
    {
      errorTmp[0].tenure = 'Tenure is required';
      isValid = false;
    }
    if(tenureType=="" || tenureType==null || tenureType == undefined)
    {
      errorTmp[0].tenureType = 'Tenure Type is required';
      isValid = false;
    }
    if(tdsDeduct == "true"){

      if(tdsPercentage=="" || tdsPercentage==null || tdsPercentage == undefined)
      {
        errorTmp[0].tdsPercentage = 'Tds Percentage is required';
        isValid = false;
      }else if(tdsPercentage <= '0'){
        errorTmp[0].tdsPercentage = 'Tds Percentage Must be greater than 0';
        isValid = false;
      }
    }
    if(projectDescription=="" || projectDescription==null || projectDescription == undefined)
    {
      errorTmp[0].productDescription = 'Product Description is required';
      isValid = false;
    }

    setError(errorTmp);
    return isValid;
  }
  
  // add Project api call
  const addProjectApi = async () =>{
        
    if(validate()){
        let param = new FormData()
        param.append('id', vendorId)
        if (profilePicture) {
          param.append('profile', profilePicture)
        }
        param.append('name', productName)
        param.append('category', businessCategory)
        param.append('investmentOpportunitySize', totalSizeOfInvestmentOpportunity)
        param.append('payout', payout)
        param.append('riskRating', riskRating)
        param.append('exitPeriod', exitPeriod)
        param.append('exitPeriodType', exitPeriodType)
        param.append('lockInPeriod', lockInPeriod)
        param.append('lockInPeriodType', lockInPeriodType)
        param.append('isProductRenewable', isProductRenewable)
        param.append('tdsDeduct', tdsDeduct)
        param.append('tdsPercentage', tdsPercentage)
        param.append('roiPercentage', roiPercentage)
        param.append('irrPercentage', irrPercentage)
        param.append('tenure', tenure)
        param.append('tenureType', tenureType)
        param.append('maxAmount', maxAmount)
        param.append('minAmount', minAmount)
        param.append('projectDescription', projectDescription)
        param.append('xscore', xscore)

        if (inputImage.length != 0) {
            inputImage.forEach((file:any) => {
                param.append('images', file);
            });
        }
        if (inputVideo.length != 0) {
            inputVideo.forEach((file:any) => {
                param.append('videos', file);
            });
        }
        if (inputDocument.length != 0) {
            inputDocument.forEach((file:any) => {
                param.append('documents', file);
            });
        }
        var res=await fetchFormData("addProject",param)
        if(res.status=="200")
        {
            toastsuccessmsg("Project Added Successfully!")
            setTimeout(() => {
              navigate("../projectlist/");
            }, 1500);
        }
        else
        {
            toasterrormsg(res.message)
            console.log(res);
            
        }
    }   
  }
  
  useEffect(()=>{
    categoryListAPIcall();
  },[])
  return (
    <>
    <ToastContainer/>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          <KTIcon iconName='category' className='fs-2 text-primary' />
          &nbsp;&nbsp;Add Project
        </h3>
      </div>
      <div className='row g-5 g-xxl-12'>
        <div className='col-xl-12'>
          <div className='card mb-5 mb-xxl-8'>
            {/* begin::Body */}
            <div className='card-body'>
                <div className="row">
                  <div className="col-lg-6">
                    {/* <!--begin::Input group--> */}
                    <div className="row mb-8">
                        <label className="col-lg-12 col-form-label fw-bold fs-5">Upload Image</label>
                        {/* <!--begin::Col--> */}
                        <div className="col-lg-12">

                            {/* <!--begin::Image input--> */}
                            <div className="image-input image-input-outline " data-kt-image-input="true">

                                {/* <!--begin::Preview existing avatar--> */}
                                
                                <img id="previewImage" className="image-input-wrapper w-100px h-100px changeimg pointer" src={profilePicture?URL.createObjectURL(profilePicture):imageUrl==="" || imageUrl===null?toAbsoluteUrl("/media/logos/inalts-logo2.png"):imageUrl} /> 
                                
                                {/* <!--end::Preview existing avatar--> */}
                                {/* <!--begin::Label--> */}
                                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                                    <i className="fa fa-edit"></i>
                                    {/* <!--begin::Inputs--> */}
                                    <input type="file" name="image" id="profileImage" accept=".png, .jpg, .jpeg" style={{"display": "none"}} onChange={(e:any)=>{if(e.target.files[0]!=null && e.target.files[0]!=undefined){setProfilePicture(e.target.files[0]);removeError("profilePicture");}}} />
                                    <input type="hidden" name="avatar_remove" />
                                    {/* <!--end::Inputs--> */}
                                </label>
                                {/* <!--end::Label--> */}
                            </div>
                            {/* <!--end::Image input--> */}
                            {/* <!--begin::Hint--> */}
                            {/* <div className="form-text" id="validimage">Allowed file types: png, jpg, jpeg.</div> */}
                            {/* <!--end::Hint--> */}
                            <div className='text-danger fw-bold'>{error[0].profilePicture}</div>
                        </div>
                        {/* <!--end::Col--> */}
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-12 col-form-label required fw-bold fs-5">Investment Product Name</label>
                      <div className="col-lg-12 fv-row">
                        <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Investment Product Name" name="productName" value={productName} onChange={(e:any) => {setProductName(e.target.value);removeError("productName");}}/>
                        <div className='text-danger fw-bold'>{error[0].productName}</div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-12 col-form-label required fw-bold fs-5">Select Business Category</label>
                      <div className="col-lg-12 fv-row">
                        <Select 
                            name='businessCategory[]'
                            id='businessCategory'
                            placeholder='Select Business Category'
                            isSearchable={true}
                            options={businessCategoryList}
                            value={businessCategorySelectedOptions}
                            onChange={(e:any) =>{ setBusinessCategorySelectedOptions(e);removeError("businessCategory");}}
                        />
                        <div className='text-danger fw-bold'>{error[0].businessCategory}</div>

                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-12 col-form-label fw-bold fs-5">Total Size of Investment Opportunity</label>
                      <div className="col-lg-12 fv-row">
                        <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Total Size of Investment Opportunity" name="totalSizeOfInvestmentOpportunity" value={totalSizeOfInvestmentOpportunity} onChange={(e:any) => {setTotalSizeOfInvestmentOpportunity(e.target.value);removeError("totalSizeOfInvestmentOpportunity")}}/>
                        <div className='text-danger fw-bold'>{error[0].totalSizeOfInvestmentOpportunity}</div>

                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-12 col-form-label required fw-bold fs-5">Payout</label>
                      <div className="col-lg-12 fv-row">
                        <div className="row">
                          <div className="col-lg-4 my-2">
                            <label className="d-flex text-start" data-kt-button="true">
                              {/* <!--begin::Radio--> */}
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" name="payout" value="FIXED_AMOUNT" checked={payout == "FIXED_AMOUNT"} onChange={(e:any) => {setPayout(e.target.value);}} />
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">Fixed Amount</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                          <div className="col-lg-4 my-2">
                            <label className="d-flex text-start" data-kt-button="true">
                              {/* <!--begin::Radio--> */}
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" name="payout" value="PERCENTAGE" checked={payout == "PERCENTAGE"} onChange={(e:any) => {setPayout(e.target.value);}}/>
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">Percentage</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                          <div className="col-lg-4 my-2">
                            <label className="d-flex text-start" data-kt-button="true">
                              {/* <!--begin::Radio--> */}
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" name="payout" value="VARIABLE" checked={payout == "VARIABLE"} onChange={(e:any) => {setPayout(e.target.value);}}/>
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">Variable</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-6 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">ROI (Percentage)</label>
                        <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="ROI" name="roi" value={roiPercentage} onChange={(e:any) => {setRoiPercentage(e.target.value);removeError("roiPercentage");}}/>
                        <div className='text-danger fw-bold'>{error[0].roiPercentage}</div>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">IRR (Percentage)</label>
                        <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="IRR" name="irr" value={irrPercentage} onChange={(e:any) => {setIrrPercentage(e.target.value);removeError("irrPercentage");}}/>
                        <div className='text-danger fw-bold'>{error[0].irrPercentage}</div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-12 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">Risk Rating</label>
                        <Select 
                            name='riskRating[]'
                            id='riskRating'
                            placeholder='Select Risk Rating'
                            isSearchable={true}
                            options={riskRatingList}
                            value={riskRatingSelectedOptions}
                            onChange={(e:any) =>{ setRiskRatingSelectedOptions(e);removeError("riskRating");}}
                        />
                        <div className='text-danger fw-bold'>{error[0].riskRating}</div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-6 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">Minimum Investment Amount</label>
                        <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Minimum Investment Amount" name="minAmount" value={minAmount} onChange={(e:any) => {setMinAmount(e.target.value);removeError("minAmount");}}/>
                        <div className='text-danger fw-bold'>{error[0].minAmount}</div>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">Maximum Investment Amount</label>
                        <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Maximum Investment Amount" name="maxAmount" value={maxAmount} onChange={(e:any) => {setMaxAmount(e.target.value);removeError("maxAmount");}}/>
                        <div className='text-danger fw-bold'>{error[0].maxAmount}</div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-lg-6 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">Exit Period</label>
                        <div className="d-flex">
                          <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 w-50" placeholder="Exit Period" name="exitPeriod" value={exitPeriod} onChange={(e:any) => {setExitPeriod(e.target.value);removeError("exitPeriod");}}/>
                          <select name="exitPeriodType" className='form-select form-select-lg form-select-solid mb-3 mb-lg-0' value={exitPeriodType} onChange={(e:any) => {setExitPeriodType(e.target.value);removeError("exitPeriodType");}}>
                            <option value="DAY">Day</option>
                            <option value="MONTH">Month</option>
                            <option value="YEAR">Year</option>
                          </select>
                        </div>
                        <div className='text-danger fw-bold'>{error[0].exitPeriod}</div>
                        <div className='text-danger fw-bold'>{error[0].exitPeriodType}</div>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">Lock-in Period</label>
                        <div className="d-flex">
                          <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 w-50" placeholder="Lock-in Period" name="lockInPeriod" value={lockInPeriod} onChange={(e:any) => {setLockInPeriod(e.target.value);removeError("lockInPeriod");}}/>
                          <select name="lockinPeriodType" className='form-select form-select-lg form-select-solid mb-3 mb-lg-0' value={lockInPeriodType} onChange={(e:any) => {setLockInPeriodType(e.target.value);removeError("lockInPeriodType");}}>
                            <option value="DAY">Day</option>
                            <option value="MONTH">Month</option>
                            <option value="YEAR">Year</option>
                          </select>
                        </div>
                        <div className='text-danger fw-bold'>{error[0].lockInPeriod}</div>
                        <div className='text-danger fw-bold'>{error[0].lockInPeriodType}</div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-lg-6 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">Tenure</label>
                        <div className="d-flex">
                          <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 w-50" placeholder="Tenure" name="tenure" value={tenure} onChange={(e:any) => {setTenure(e.target.value);removeError("tenure");}}/>
                          <select name="tenureType" className='form-select form-select-lg form-select-solid mb-3 mb-lg-0' value={tenureType} onChange={(e:any) => {setTenureType(e.target.value);removeError("tenureType");}}>
                            <option value="DAY">Day</option>
                            <option value="MONTH">Month</option>
                            <option value="YEAR">Year</option>
                          </select>
                        </div>
                        <div className='text-danger fw-bold'>{error[0].exitPeriod}</div>
                        <div className='text-danger fw-bold'>{error[0].exitPeriodType}</div>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <label className="col-form-label fw-bold fs-5 required">Xscore (Percentage)</label>
                        <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Xscore" name="xscore" value={xscore} onChange={(e:any) => {setXscore(e.target.value);removeError("xscore");}}/>
                        <div className='text-danger fw-bold'>{error[0].xscore}</div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-12 col-form-label required fw-bold fs-5">Is This Product Renewable?</label>
                      <div className="col-lg-12 fv-row">
                        <div className="row">
                          <div className="col-lg-2 col-4 my-2">
                            <label className="d-flex text-start" data-kt-button="true">
                              {/* <!--begin::Radio--> */}
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" name="isProductRenewable" value="true" checked={isProductRenewable == "true"} onChange={(e:any) => {setIsProductRenewable(e.target.value)}} />
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">Yes</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                          <div className="col-lg-2 col-4 my-2">
                            <label className="d-flex text-start" data-kt-button="true">
                              {/* <!--begin::Radio--> */}
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" name="isProductRenewable" value="false" checked={isProductRenewable == "false"} onChange={(e:any) => {setIsProductRenewable(e.target.value)}}/>
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">No</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-12 col-form-label required fw-bold fs-5">Do You Deduct TDS? (Percentage)</label>
                      <div className="col-lg-12 fv-row">
                        <div className="row">
                          <div className="col-lg-2 col-4 my-2">
                            <label className="d-flex text-start" data-kt-button="true">
                              {/* <!--begin::Radio--> */}
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" name="tdsDeduct" value="true" checked={tdsDeduct == "true"} onChange={(e:any) => {setTdsDeduct(e.target.value)}}/>
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">Yes</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                          <div className="col-lg-2 col-4 my-2">
                            <label className="d-flex text-start" data-kt-button="true">
                              {/* <!--begin::Radio--> */}
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" name="tdsDeduct" value="false" checked={tdsDeduct == "false"} onChange={(e:any) => {setTdsDeduct(e.target.value)}}/>
                              </span>
                              {/* <!--end::Radio--> */}

                              {/* <!--begin::Info--> */}
                              <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">No</span>
                              </span>
                              {/* <!--end::Info--> */}
                            </label>
                          </div>
                          <div className="col-lg-8 my-2">
                            <input type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Percentage" name="tdsPercentage" value={tdsPercentage} onChange={(e:any) => {setTdsPercentage(e.target.value);removeError("tdsPercentage");}}/>
                            <div className='text-danger fw-bold'>{error[0].tdsPercentage}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--end::Input group--> */}
                  </div>
                  <div className="col-lg-6">
                    <label className="col-form-label required fw-bold fs-5">Enter Product Description</label>
                    <Editor
                        editorState={productDescription}
                        toolbarClassName="form-control form-control-lg form-control-solid"
                        wrapperClassName="wrapperClassName"
                        editorClassName="form-control form-control-lg form-control-solid mh-300px"
                        onEditorStateChange={(e:any) => {setProductDescription(e);removeError("productDescription");}}
                      />
                      {/* <button onClick={() => console.log(getContentAsText())}>Get Content</button> */}
                    <div className='text-danger fw-bold'>{error[0].productDescription}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="dropzone dz-clickable my-5" id="kt_ecommerce_add_business_certificate" onClick={() => handleDivClick('uploadImage')}>
                        <input type="file" id='uploadImage' ref={inputfileRef} value={blankImage} style={{ display: 'none' }} onChange={handleImageChange} accept='image/*' multiple/>
                        {/* <!--begin::Message--> */}
                        <div className="dz-message needsclick">
                            {/* <!--begin::Icon--> */}
                            <i className="ki-outline ki-file-up text-primary fs-3x"></i>
                            {/* <!--end::Icon--> */}
                            {/* <!--begin::Info--> */}
                            <div className="ms-4">
                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">Upload Image</h3>
                                <span className="fs-7 fw-semibold text-gray-500">(max 15 mb size upload)</span>
                            </div>
                            {/* <!--end::Info--> */}
                        </div>
                    </div>
                    <div className='text-danger fw-bold'>{error[0].inputImage}</div>
                    <div className="row mt-3">
                    { inputImage ? ( 
                        inputImage.map((imageList:any, index:any) => (
                          <div className="col-lg-4 col-3 d-flex flex-column mb-5" key={index} >
                              <button
                                  className="btn btn-primary p-1 px-2 rounded-circle"
                                  style={{
                                      fontSize: '12px',
                                      alignSelf:'end',
                                      position:'relative',
                                      top:'7%',
                                      right:'-3%'
                                  }}
                                  onClick={() => handleRemove1(index)}
                              >
                                  X
                              </button>
                              <div style={{ maxWidth: "fit-content" }}>
                                  <img src={URL.createObjectURL(imageList)} width="100%"/>
                              </div>
                          </div>
                        ))
                    ) : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="dropzone dz-clickable my-5" id="kt_ecommerce_add_business_certificate" onClick={() => handleDivClick('uploadVideos')}>
                        <input type="file" id='uploadVideos' ref={inputfileRef} value={blankImage} style={{ display: 'none' }} onChange={handleVideoChange} accept='video/*' multiple />
                        {/* <!--begin::Message--> */}
                        <div className="dz-message needsclick">
                            {/* <!--begin::Icon--> */}
                            <i className="ki-outline ki-file-up text-primary fs-3x"></i>
                            {/* <!--end::Icon--> */}
                            {/* <!--begin::Info--> */}
                            <div className="ms-4">
                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">Upload Videos</h3>
                                <span className="fs-7 fw-semibold text-gray-500">(max 15 mb size upload)</span>
                            </div>
                            {/* <!--end::Info--> */}
                        </div>
                    </div>
                    <div className="row mt-3">
                    {inputVideo ? (
                      inputVideo.map((videoList:any, index:any) => {
                          return (
                              <div className="col-lg-4 col-3 d-flex flex-column mb-5" key={index}>
                                  <button
                                      className="btn btn-primary p-1 px-2 rounded-circle"
                                      style={{
                                          fontSize: '12px',
                                          alignSelf: 'end',
                                          position: 'relative',
                                          top: '7%',
                                          right: '-3%'
                                      }}
                                      onClick={() => handleRemove2(index)}
                                  >
                                      X
                                  </button>
                                  <div style={{ maxWidth: "fit-content" }}>
                                      <video autoPlay muted loop style={{ minHeight: "25px", height: "70px", objectFit: "cover", objectPosition: "50% 50%" }}>
                                          <source src={URL.createObjectURL(videoList)} type="video/mp4" />
                                          Your browser does not support the video tag.
                                      </video>
                                  </div>
                              </div>
                          )
                      })
                  ) : ""}

                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="dropzone dz-clickable my-5" id="kt_ecommerce_add_business_certificate" onClick={() => handleDivClick('uploadDocument')}>
                      <input type="file" id='uploadDocument' ref={inputfileRef} style={{ display: 'none' }} onChange={handleDocumentChange} multiple accept=".pdf"/>
                      <div className="dz-message needsclick">
                        <i className="ki-outline ki-file-up text-primary fs-3x"></i>
                        <div className="ms-4">
                          <h3 className="fs-5 fw-bold text-gray-900 mb-1">Upload Document</h3>
                          <span className="fs-7 fw-semibold text-gray-500">(max 15 mb size upload)</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      { inputDocument ? ( 
                        inputDocument.map((documentList:any, index:any) => (
                          <div className="col-lg-4 col-3 d-flex flex-column mb-5" key={index} >
                            <button
                              className="btn btn-primary p-1 px-2 rounded-circle"
                              style={{
                                fontSize: '12px',
                                alignSelf:'end',
                                position:'relative',
                                top:'7%',
                                right:'-3%'
                              }}
                              onClick={() => handleRemove3(index)}
                            >
                              X
                            </button>
                            <div style={{ maxWidth: "fit-content" }} className="bg-light-primary rounded p-5">
                              <p>{documentList.name}</p>
                            </div>
                          </div>
                        ))
                      ) : ""}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-4'></div>
                  <div className='col-lg-4 text-center'>
                    <button type='button' className='btn btn-primary me-2' onClick={addProjectApi}>
                      {!configLoading && <span className='indicator-label'>Add Project</span>}
                      {configLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-lg-4'></div>
                </div>
            </div>
          </div>
          {/* end::Body */}
        </div>
      </div>
    </>
  )
}
