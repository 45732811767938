/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertToRaw  } from 'draft-js';
import { Link } from 'react-router-dom';

type Props = {
  className: string
  title?: string
  data?: any
}



const TablesWidgetDemoNews: React.FC<Props> = ({className,title,data}) => {
  const convertHtmlToEditorState = (html:any) => {
    const blocksFromHTML = stateFromHTML(html);
    
    return EditorState.createWithContent(blocksFromHTML);
  };

  
  const editorStateToPlainText = (editorState:any) => {
    const contentState = editorState.getCurrentContent();
    return contentState.getPlainText();
  };
  
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
         
        </h3>
        <div className='card-toolbar'>
         
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px fw-semibold'></th>
                    <th className='p-0 min-w-150px fw-semibold'></th>
                    <th className='p-0 min-w-110px text-center fw-semibold'></th>
                   
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {data.map((project: any, index: any) => {
                  if(index < 5){
                    
                    const description = project.description;
                                                  
                    let displayText;
                    const editorState = convertHtmlToEditorState(description);
                    displayText = editorStateToPlainText(editorState);

                    if (displayText.length >= 50) {
                      // Convert the truncated HTML to EditorState and then to plain text
                      displayText = displayText.slice(0, 50) + '...';
                    }
                  return(<tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={project['file']}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <Link to={'/newsFeedDetail'} state={{newsFeedId:project._id}} className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {project['title']}
                        </Link>
                        
                      </td>
                      <td className='text-end text-muted fw-semibold text-center'>{displayText}</td>
                     
                    </tr>)

                  }
                })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
          
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidgetDemoNews}
