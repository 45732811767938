/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import { OtpVerification } from './components/OtpVerification'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { ResetPassword } from './components/ResestPassword'
import { KycUpload } from './components/KycUpload'
import { WaittingPage } from './components/WaittingPage'
import { RejectPage } from './components/RejectPage'


const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div
    className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    style={{
      backgroundColor: `#050e24`,
    }}
  >
    <div className="container-fluid">
      <div className="row">
        {/* begin::Content */}
        <div className='col-lg-6 p-10 pb-lg-20'>
          {/* begin::Wrapper */}
          <div className='bg-body rounded shadow-sm p-10 p-lg-15'>
          {/* begin::Logo */}
          <a href='#' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/inaltsIcon/inaltsLogo.png')}
              className='theme-dark-show h-100px h-lg-150px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/inaltsIcon/inaltsLogo.png')}
              className='theme-light-show h-100px h-lg-150px'
            ></img>
          </a>
          {/* end::Logo */}
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <img src={toAbsoluteUrl('/media/inaltsIcon/inaltsLogo.png')} alt="inalts-logo" width="60%"/>
        </div>
      </div>
    </div>
  </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='kycUpload' element={<KycUpload />} />
      <Route path='waitpage' element={<WaittingPage />} />
      <Route path='reject' element={<RejectPage />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='otpverification' element={<OtpVerification />} />
      <Route path='reset-password' element={<ResetPassword />} />
      
      {/* <Route path='refund' element={<Refund />} />
      <Route path='privacyPolicy' element={<ResetPassword />} />
      <Route path='termsAndCondition' element={<ResetPassword />} /> */}
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
