/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import { Link } from 'react-router-dom'

type Props = {
  className: string
  title?: string
  data?: any
}



const TablesWidgetDemo: React.FC<Props> = ({className,title,data}) => {

  
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
         
        </h3>
        <div className='card-toolbar'>
         
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px fw-semibold'>Image</th>
                    <th className='p-0 min-w-150px fw-semibold'>Project Name</th>
                    <th className='p-0 min-w-110px text-center fw-semibold'>Investment Oppurtunity Size</th>
                    <th className='p-0 min-w-50px text-center fw-semibold'>My Investment Amount</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {data.map((project: any, index: any) => {
                  if(project['projectData'].length != 0 && index < 5){
                   
                  return(<tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={project['projectData']['profilePicture']}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <Link to={'../projectdetails/'} state={{ projectId: project['projectData']['_id']}}  className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {project['projectData']['name']}
                        </Link>
                        
                      </td>
                      <td className='text-end text-muted fw-semibold text-center'>₹ {project['projectData']['investmentOppurtunitySize']}</td>
                      <td className='text-end text-muted fw-semibold text-center'>₹ {project['amount']}</td>
                    </tr>)

                  }
                })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
          
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidgetDemo}
