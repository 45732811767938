import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'
import { ToastContainer } from 'react-toastify'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export function RejectPage() {

  useEffect(() => {
    document.title = 'Investor | Please Wait';
  }, []);
  const navigate=useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState("")
  const [countryCodeList,setCountryCodeList]=useState([])
  const [countryCode,setCountryCode]=useState(Object)
  const [email,setEmail]=useState("")
  const [mobileNumber,setMobileNumber]=useState("")
  const [isSubmited,setIsSubmited]=useState(false)
  
  const validateEmail=async()=>{
    
    if(email.trim()==="" || email.trim()===null || email.trim()===undefined)
    {
        setHasErrors("Email Address Is Required")
    }
    else if(!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i) ) {
        setHasErrors("Enter Valid Email Address")
    }
    else
    {
      
      // try {
      //   await auth.signInWithPhoneNumber(mobileNumber, new firebase.auth.RecaptchaVerifier('recaptcha-container'));
      //   // Handle success, navigate to OTP verification page
      // } catch (error) {
      //   console.error("Error sending OTP:", error);
      //   // Handle error
      // }
      forgotpasswordapicall()
      // navigate("../reset-password")
    }
  }

  const forgotpasswordapicall=async()=>{
    var param:any={
      email:email
    }
    var response:any=await fetchData("forgetPassword",param)
    
    if(response.status=="200")
    {
      sessionStorage.setItem('auther',response.data[0]['vendorId']);
      sessionStorage.setItem('autherEmail',email);
      console.log(email);
      
      localStorage.setItem("vendorId",response.data[0]['vendorId'])
      console.log(response.data);
      
      toastsuccessmsg("OTP Send Successfully")

      setTimeout(() => {
        navigate("../otpverification");
      }, 1500);
      
    }
    else
    {
      // setSubmitting(false)
      setLoading(false)
      toasterrormsg(response.message)
    }
  }

  return (
    <>
      <ToastContainer />
      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        id='kt_login_password_reset_form'
      >
        <div className='text-start my-5'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Reject Document</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>Reason :</div>
          {/* end::Link */}
        </div>
        {/* <div className='my-10'> */}
        <img src={toAbsoluteUrl('/media/auth/rejectlogo.png')} alt='' className='w-50' />
        {/* </div> */}
        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <Link
            to='/auth/kycUpload'
            // id='kt_password_reset_submit'
            className='btn btn-primary me-4'
            // onClick={() => validateEmail()}
          >
            <span className='indicator-label'>Please try Again</span>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </div>
    </>
  )
}
