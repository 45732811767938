import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//  ------- Flamingo Auth----------
// const firebaseConfig = {
//   apiKey: "AIzaSyC8Efu5JaTQAR4xsN289_fRqoFaPSWa0x4",
//   authDomain: "react-app-test-6f86a.firebaseapp.com",
//   projectId: "react-app-test-6f86a",
//   storageBucket: "react-app-test-6f86a.appspot.com",
//   messagingSenderId: "250556796330",
//   appId: "1:250556796330:web:fe3cf5bb41c30c1dd06fd3",
//   measurementId: "G-BWY6P9VCBD"
// };

//  ------- Inalts Firebase Auth ---------
const firebaseConfig = {
    apiKey: "AIzaSyApCpQGaJTBo-qfdhia4NYK0oUSxAtF930",
    authDomain: "inalts-3b99d.firebaseapp.com",
    projectId: "inalts-3b99d",
    storageBucket: "inalts-3b99d.appspot.com",
    messagingSenderId: "661664560345",
    appId: "1:661664560345:web:da3e1dfc76d8a3c7efbac8",
    measurementId: "G-C66WXQNNEK"
  };


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export { firebase};