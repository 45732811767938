/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import { fetchData, fetchFormData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'
import { ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select/dist/declarations/src/Select'
import { log } from 'console'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  mobileNumber: '',
  password: '',
  changepassword: '',
  country:''
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+$/, 'First name must contain only alphabetic characters')
    .required('First name is required'),
  email: Yup.string().required("Email is required")
  .matches(
    /^(?!.*\.\.)[A-Za-z0-9]+(\.[A-Za-z0-9]+)*@[A-Za-z0-9]+\.[A-Za-z0-9]+$/,
    "Please Enter valid Email"
  ),
  lastname: Yup.string()
    .trim() // Trim leading and trailing whitespace
    .matches(/^[A-Za-z]+$/, 'First name must contain only alphabetic characters')
    .required('Last name is required'),
  mobileNumber: Yup.string()
  
    .trim() // Trim leading and trailing whitespace
    .matches(/^[0-9]+$/, 'Mobile number must contain only digits') // Only digits allowed
    .min(10, 'Mobile number must be at least 10 digits') // Minimum length
    .max(15, 'Mobile number can be at most 15 digits') // Maximum length
    .required('Mobile Number is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!%#*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  country: Yup.string().required('Nationality is required'),
  // acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  
  useEffect(() => {
    document.title = 'Investor | Sign Up';
  }, []);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const [passwordDisplay, setPasswordDisplay] = useState(false)
  const [cnfpasswordDisplay, setCnfPasswordDisplay] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState("")

  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        //  console.log('=============', values)
        let param = new FormData()
        param.append('firstName', values.firstname)
        param.append('lastName', values.lastname)
        param.append('email', values.email)
        param.append('mobileNumber', values.mobileNumber)
        param.append('password', values.password)
        // param.append('nationality', selectedCountry)
        param.append('nationality', values.country)
        // param.append('profilePicture', '')
        console.log(param);
        
        var response: any = await fetchFormData('addinvestor',param)

        if (response.status == '200') {
          sessionStorage.setItem('author', response.data[0]["_id"])
          toastsuccessmsg('Registor Successfully')

          setTimeout(() => {
           navigate('../kycUpload')
          }, 1500)
      
        } else {
          setLoading(false)
          toasterrormsg(response.message)
        }
        
       
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })


  //Contry List API
   const getCountry = async () => {

     var res = await fetchData('countryList',JSON)

     console.log('response data', res.data)
     if (res.status == 200) {
       setCountryList(res.data)
     } else {
       toasterrormsg(res.message)
     }
   }

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    getCountry()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <ToastContainer />
      {/* begin::Heading */}
      <div className='text-left my-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* end::Heading */}

      {/* end::Login options */}

      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>First name</label>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Last name / SurName</label>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Mobile Number</label>
        <input
          placeholder='Mobile Number'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('mobileNumber')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.mobileNumber && formik.errors.mobileNumber},
            {
              'is-valid': formik.touched.mobileNumber && !formik.errors.mobileNumber,
            }
          )}
        />
        {formik.touched.mobileNumber && formik.errors.mobileNumber && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.mobileNumber}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>I am Citizen of</label>
        {/* <select
         
          // name='country'
          defaultValue={selectedCountry}
          onChange={(e: any) => {
            setSelectedCountry(e.target.value)
            
          }}
          className='form-control form-control-lg col-6 border border-gray-400 form-control-solid'
        >
          <option value=''>Select Country</option>
          {countryList.map((val: any, index: any) => {
            return <option value={val['_id']}>{val.name}</option>
          })}
        </select> */}

<select
        name="country"
        value={formik.values.country}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={clsx(
          'form-control bg-transparent',
          {'is-invalid': formik.touched.country && formik.errors.country},
          {
            'is-valid': formik.touched.country && !formik.errors.country,
          }
        )}
      >
        <option value="">Select Country</option>
        {countryList.map((val: any) => (
          <option key={val._id} value={val._id}>
            {val.name}
          </option>
        ))}
      </select>
      {formik.touched.country && formik.errors.country && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.country}</span>
            </div>
          </div>
        )}

      </div>

      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true' style={{position: 'relative'}}>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3' style={{position: 'relative'}}>
            <input
              type={passwordDisplay == true ? 'text' : 'password'}
              placeholder='Password'
              autoComplete='off'
              style={{backgroundImage: `url()`}}
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <span
              className='cursor-pointer'
              style={{position: 'absolute', top: '25%', right: '20px'}}
              title={passwordDisplay == true ? 'Hide' : 'Show'}
              onClick={() => setPasswordDisplay(!passwordDisplay)}
            >
              <FontAwesomeIcon icon={passwordDisplay == true ? faEyeSlash : faEye} />
            </span>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          {/* <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div> */}
          {/* end::Meter */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5' style={{position: 'relative'}}>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <div className='position-relative mb-3' style={{position: 'relative'}}>
          <input
            type={cnfpasswordDisplay == true ? 'text' : 'password'}
            placeholder='Password confirmation'
            autoComplete='off'
            style={{backgroundImage: `url()`}}
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          <span
            className='cursor-pointer'
            style={{position: 'absolute', top: '25%', right: '20px'}}
            title={cnfpasswordDisplay == true ? 'Hide' : 'Show'}
            onClick={() => setCnfPasswordDisplay(!cnfpasswordDisplay)}
          >
            <FontAwesomeIcon icon={cnfpasswordDisplay == true ? faEyeSlash : faEye} />
          </span>
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='text-center'>
        <Link to='/auth/login' className='link-primary py-5'>
          Already User Exists ? Sign In
        </Link>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5 mt-2'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
