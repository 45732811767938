/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'
import { ToastContainer } from 'react-toastify'
import {  firebase} from '../../../../firebaseconfig'


const initialValues = {
  mobileNumber: '987654321',
  password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

  useEffect(() => {
    document.title = 'Investor | Sign In';
  }, []);
  const [loading, setLoading] = useState(false)
  const [mobileNumber,setMobileNumber]=useState();
  const [password,setPassword]=useState()
  const [error,setError]=useState({"mobileNumber":"","password":""})
  const [datapost,setDatapost]=useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate=useNavigate()
  const [passwordDisplay,setPasswordDisplay]=useState(false)

  // validation method

  const validate=(submited:any=false)=>{
    
    setDatapost(true)
    var tmpErr=error
    if(mobileNumber=="" || mobileNumber==null)
    {
      tmpErr["mobileNumber"]="Mobile Number Required"
    }
    else
    {
      tmpErr["mobileNumber"]=""
    }
    if(password=="" || password==null)
    {
      tmpErr["password"]="password Required"
    }
    else
    {
      tmpErr["password"]=""
    }

    if(tmpErr["mobileNumber"]=="" && tmpErr["password"]=="" && submited==true)
    {
      
      loginapicall()
      // otpSentMobile()
    }
  }
  
  const loginapicall=async()=>{
    var param:any={
      mobileNumber:mobileNumber,
      password:password
    }
    
    var response:any=await fetchData("login",param)
    console.log(response);
    
    if(response.status=="200")
    {
      console.log(response.data.isVerified);
      var data = response.data;
      
      sessionStorage.setItem('auther',data._id);
      sessionStorage.setItem('vendor', JSON.stringify(data))
      toastsuccessmsg("Login Successfully")

      // console.log(
      //   response.data.panCard,
      //   '=========',
      //   response.data.panCard == '' ,
      //     response.data.panCard == undefined ,
      //     response.data.panCard == null
      // )
      
      if ((response.data.panCard == "" || response.data.panCard == undefined || response.data.panCard == null) && (response.data.frontAadharCard == "" || response.data.frontAadharCard == undefined || response.data.frontAadharCard == null) && (response.data.backAadharCard == "" || response.data.backAadharCard == undefined || response.data.backAadharCard == null)) {
         navigate('../kycUpload')
      }else{
      
          setTimeout(() => {
            // window.location.reload();
            navigate('/dashboard')
            // window.location.href = '../dashboard'
          }, 1500);
      
      
      }


      
    }
    else
    {
      // setSubmitting(false)
      setLoading(false)
      toasterrormsg(response.message)
    }
  }


  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const recaptchaRef = useRef(null)

  let recaptchaVerifier: firebase.auth.RecaptchaVerifier | null = null;

  const otpSentMobile = async () => {
  
    console.log("---------",recaptchaVerifier);
    
    if (recaptchaVerifier) {
      recaptchaVerifier.clear();
    }
  
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: "invisible",
    });
  
    await recaptchaVerifier.render();
  
    try {
      const response = await firebase.auth().signInWithPhoneNumber("+916355939105", recaptchaVerifier);
      console.log("======", response);
    } catch (error) {
      console.error("Error during OTP sending:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className='form w-100' id='kt_login_signin_form'>
        <div className='text-left my-10'>
          {/* begin::Title */}
          <div id="recaptcha-container"></div>
          <h1 className='text-dark fw-bolder mb-3'>Welcome Back To, Sign In</h1>
         
          {/* end::Title */}
        </div>
        {/* <div className='my-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            <h2>Welcome Back To, Login</h2>
          </div>
        </div> */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Mobile Number</label>
          <input
            placeholder='Mobile Number'
            className='form-control bg-transparent'
            type='text'
            name='mobileNumber'
            autoComplete='off'
            onChange={(e: any) => {
              setMobileNumber(e.target.value)
              if (datapost == true) {
                validate()
              }
            }}
            onKeyDown={(e: any) => {
              if (
                (e.key === 'Enter' || e.keyCode === 13) &&
                mobileNumber != '' &&
                mobileNumber != null &&
                password != '' &&
                password != null
              ) {
                validate(true)
              }
            }}
          />
          {error['mobileNumber'] != '' && (
            <span className='text-danger'>{error['mobileNumber']}</span>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3' style={{position: 'relative'}}>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <div style={{position: 'relative'}}>
            <input
              type={passwordDisplay == true ? 'text' : 'password'}
              autoComplete='off'
              placeholder='Password'
              className='form-control bg-transparent'
              onChange={(e: any) => {
                setPassword(e.target.value)
                if (datapost == true) {
                  validate()
                }
              }}
              onKeyDown={(e: any) => {
                if (
                  (e.key === 'Enter' || e.keyCode === 13) &&
                  mobileNumber != '' &&
                  mobileNumber != null &&
                  password != '' &&
                  password != null
                ) {
                  validate(true)
                }
              }}
            />
            <span
              className='cursor-pointer'
              style={{position: 'absolute', top: '25%', right: '20px'}}
              title={passwordDisplay == true ? 'Hide' : 'Show'}
              onClick={() => setPasswordDisplay(!passwordDisplay)}
            >
              <FontAwesomeIcon icon={passwordDisplay == true ? faEyeSlash : faEye} />
            </span>
            <div className='fv-plugins-message-container invalid-feedback'></div>
          </div>
          {error['password'] != '' && <span className='text-danger'>{error['password']}</span>}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          <Link to='/auth/registration' className='link-primary'>
            New User ? Sign Up Now
          </Link>
         
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-5'>
          <button
            type='button'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={
              mobileNumber != '' && mobileNumber != null && password != '' && password != null
                ? false
                : true
            }
            onClick={() => validate(true)}
          >
            {!loading && <span className='indicator-label'>Sign In</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
          
      
        {/* end::Action */}

        {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
          Not a Member yet?{' '}
          <Link to='/auth/registration' className='link-primary'>
            Sign up
          </Link>
        </div> */}
      </div>
    </>
  )
}
