/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from './core/_requests'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useAuth } from './core/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faArrowRightLong, faEye, faEyeSlash, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../reusable'
import { ToastContainer } from 'react-toastify'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import '../../../_metronic/assets/style/style.css'
import { url } from 'inspector'

export function LayOut(contentdata: any) {

  useEffect(() => {
    document.title = 'Inalts Investor';
    loginapicall()
  }, []);
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState();
  const [password, setPassword] = useState()
  const [error, setError] = useState({ "email": "", "password": "" })
  const [datapost, setDatapost] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const [passwordDisplay, setPasswordDisplay] = useState(false)
  const [projects, setprojects] = useState([])

  //  Refreance variable -------------
  const abouref = useRef(null)
  const projectsref = useRef(null)
  const whoweareref = useRef(null)

  // Crousel Responcive
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef: any = useRef(null);
  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
      carouselRef.current.goToSlide(currentSlide - 1);
    }
  };

  const handleNextSlide = () => {
    if (currentSlide < projects.length - 1) {
      setCurrentSlide(currentSlide + 1);
      carouselRef.current.goToSlide(currentSlide + 1);
    }
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }


  const scrollAboutUs = () => {
    // Find the first element with the class 'about-section'
    const element = document.querySelector('.about-section');

    if (element) {
      // Calculate the element's position relative to the top of the page
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;

      // Set a custom offset (e.g., 100px)
      const offset = 100;

      // Scroll to the element with the offset, ensuring it's not hidden behind the top
      window.scrollTo({
        top: elementPosition - offset, // Apply the offset
        behavior: 'smooth', // Smooth scroll behavior
      });
    } else {
      console.log('Element with class "about-section" not found.');
    }
  }

  function scrollWhoWeare() {
    // Find the first element with the class 'content-section'
    const element = document.querySelector('.whoWeAre-section');

    if (element) {
      // Calculate the element's position relative to the top of the page
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;

      // Set a custom offset (e.g., 100px)
      const offset = 100;

      // Scroll to the element with the offset, ensuring it's not hidden behind the top
      window.scrollTo({
        top: elementPosition - offset, // Apply the offset
        behavior: 'smooth', // Smooth scroll behavior
      });
    } else {
      console.log('Element with class "about-section" not found.');
    }
  }

  function scrollprojectsref() {
    navigate('../projectlist')

  }


  const loginapicall = async () => {
    var param: any = {
      // email:email,
      // password:password
    }

    var response: any = await fetchData('viewproject', param)

    if (response.status == "200") {
      setprojects(response.data)
      console.log(response.data)

    }
    else {
      console.log(response)
    }

  }

  function redirectContact() {

    navigate('../contactUs')
    document.body.removeAttribute('style');

    // window.location.href = '../contactUs'

  }

  function redirectHome() {

    navigate('/')
    document.body.removeAttribute('style');

    // window.location.href = '/'

  }

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };


  }, []);


  return (
    <>
      
      
          {/* Mobile Screen Header Start -------*/}

          <div className='d-sm-none col-12 mb-15 '>
            <div id='' className='h-100px pt-4 header-content-home-mobile'>
              <div className='app-container p-0 container-xxl d-flex align-items-stretch justify-content-around' id=''>
                <div className='d-flex align-items-center d-sm-none ms-n2 mt-3 mx-5' title='Show sidebar menu'>
                  <div
                    className='btn btn-icon btn-active-color-primary w-35px h-35px mr-auto'
                    id='kt_app_header_menu_toggle'
                    data-bs-toggle='offcanvas'
                    data-bs-target='#offcanvasExample'
                    aria-controls='offcanvasExample'
                  >
                    <i className='ki-outline ki-abstract-14' style={{ fontSize: 'x-large' }}></i>
                  </div>
                </div>

                {/* mobile Screen Sidebar */}
                <div className='d-sm-none'>
                  <div
                    className='offcanvas offcanvas-start flex-grow-1 flex-lg-grow-0 me-lg-18'
                    style={{ width: '50%', backgroundColor: "#F3F3F3", color: '#8D8D8D' }}
                    tabIndex={-1}
                    id='offcanvasExample'
                    aria-labelledby='offcanvasExampleLabel'
                  >
                    <div
                      data-kt-menu-placement='bottom-start'
                      className='menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2'
                    >
                      <div className='d-flex justify-content-evenly p-4 pb-0'>
                        <img
                          alt='Logo'
                          src={toAbsoluteUrl('/media/homeImg/homePageHeader.png')}
                          className='h-75px '
                        />
                        <button
                          type='button'
                          className='btn-close text-reset ms-7'
                          data-bs-dismiss='offcanvas'
                          aria-label='Close'
                        ></button>
                      </div>
                      <div className='offcanvas-header pt-0'>
                        {/* {/ <!--begin:Menu link--> /} */}
                        <div className='app-sidebar-navs flex-column-fluid py-6' id='kt_app_sidebar_navs'>
                          <div
                            id='kt_app_sidebar_navs_wrappers'
                            className='app-sidebar-wrapper hover-scroll-y my-2'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='true'
                            data-kt-scroll-height='auto'
                            data-kt-scroll-dependencies='#kt_app_sidebar_header'
                            data-kt-scroll-wrappers='#kt_app_sidebar_navs'
                            data-kt-scroll-offset='5px'
                          >
                            {/* <!--begin::Sidebar menu--> */}
                            <div
                              id='#kt_app_sidebar_menu'
                              data-kt-menu='true'
                              data-kt-menu-expand='false'
                              className='app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary'
                            >
                              <div className='menu-item mb-2'>
                                <div className='menu-heading text-uppercase fs-7 fw-bold'></div>
                                <div className='app-sidebar-separator separator'></div>
                              </div>

                              {/* ----------- Dashboard -------------- */}
                              <div
                                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                data-kt-menu-placement='bottom-start'
                                // data-kt-menu-offset='-200,0'
                                className='menu-item menu-lg-down-accordion me-0 me-lg-2'
                                onClick={() => redirectHome()}
                              >

                                <span className='menu-link'>
                                  <span className='menu-title-home-page'>Home</span>
                                </span>
                              </div>

                              <div
                                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                data-kt-menu-placement='bottom-start'
                                className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2'
                              >
                                <span className='menu-link'>
                                  <span className='menu-title-home-page' onClick={() => scrollAboutUs()}>
                                    About Us
                                  </span>
                                  {/* <span className='menu-arrow d-lg-none'></span> */}
                                </span>
                              </div>

                              <div
                                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                data-kt-menu-placement='bottom-start'
                                className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2'
                              >
                                <span className='menu-link'>
                                  <span className='menu-title-home-page' onClick={() => scrollWhoWeare()}>
                                    Our Approach
                                  </span>
                                  {/* <span className='menu-arrow d-lg-none'></span> */}
                                </span>
                              </div>

                              <div
                                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                data-kt-menu-placement='bottom-start'
                                className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2'
                              >
                                <span className='menu-link'>
                                  <span className='menu-title-home-page' onClick={() => scrollprojectsref()}>
                                    Projects
                                  </span>
                                  {/* <span className='menu-arrow d-lg-none'></span> */}
                                </span>
                              </div>
                              <div
                                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                data-kt-menu-placement='bottom-start'
                                className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2'
                              >
                                <span className='menu-link'>
                                  <span className='menu-title-home-page' onClick={() => redirectContact()}>Contact Us</span>
                                </span>


                              </div>
                            </div>
                            {/* <!--end::Sidebar menu--> */}
                          </div>
                        </div>
                        {/* {/ <!--end:Menu link--> /} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1 me-10 ' style={{ color: '#FBB408' }} id='kt_app_header_wrapper'>
                  <div style={{ border: "1px solid #D3D3D3 ", borderRadius: '28px' }} className='p-2 row'>
                    <div className='col my-auto text-center' >
                      <Link
                        to={'/auth/login'}
                        className='link-redirect'
                      >
                        Sign In
                      </Link>
                    </div>
                    <div className='col my-auto text-center py-1' style={{borderLeft: "1px solid #DBDBDB" }}>
                      <Link
                        to={'/auth/registration'}
                        className='link-redirect'
                      >
                        Sign Up
                      </Link>
                    </div>
                    <div className='col-sm my-2 text-center '>
                      <a
                        href={'https://inalts.com/vendor/dashboard'}
                        className='link-redirect'
                        target="_blank"
                        style={{color: "rgb(251, 180, 8)"}}
                      >
                        Are You Investment Company?
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Screen Header End --------*/}

      <div className='container-fluid bg-white'>
        <div className='row p-0 m-sm-0'>
          {/* --------------- Header Start ------------ */}

          {/* Dekstop Screen Header Start ---------*/}
          <div className='d-none d-md-block d-lg-block col-sm-10 p-0 mx-sm-auto mb-15'>
            {/* ---------- Header ---------- */}
            <div id='' className='h-100px pt-4 header-content-home'>
              <div className='app-container container-xxl d-flex align-items-stretch justify-content-space' id=''>

                {/* <div className='d-flex align-items-center d-sm-none ms-n2 mt-3 mx-5' title='Show sidebar menu'>
                  <div
                    className='btn btn-icon btn-active-color-primary w-35px h-35px mr-auto'
                    id='kt_app_header_menu_toggle'
                    data-bs-toggle='offcanvas'
                    data-bs-target='#offcanvasExample'
                    aria-controls='offcanvasExample'
                  >
                    <i className='ki-outline ki-abstract-14' style={{ fontSize: 'x-large' }}></i>
                  </div>
                </div> */}

                <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-3'>
                  <a href='#'>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/inaltsIcon/inaltsLogo.png')}
                      className='h-75px d-none d-sm-block'
                    />
                  </a>
                </div>

                <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1 mx-2' style={{ color: '#FBB408' }} id='kt_app_header_wrapper'>

                  {/* ------------------ Main Screen Menus Start --------------------------------------*/}
                  <div className='app-navbar headershirnk flex-shrink-0 d-flex  mt-8 col-md-7' style={{ justifyContent: "space-evenly" }}>
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement='bottom-start'
                      
                      className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention text-center'
                    >
                      <span className='menu-title-home-page' onClick={() => redirectHome()}>Home</span>

                    </div>
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement='bottom-start'
                     
                      className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention text-center'
                    >
                      <span className='menu-title-home-page' onClick={() => scrollAboutUs()}>
                        About Us
                      </span>

                    </div>
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement='bottom-start'
                     
                      className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention text-center'
                    >

                      <span className='menu-title-home-page' onClick={() => scrollWhoWeare()}>
                        Our Approach
                      </span>

                    </div>
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement='bottom-start'
                      
                      className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention text-center'
                    >

                      <span className='menu-title-home-page' onClick={() => scrollprojectsref()}>
                        Projects
                      </span>

                    </div>
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement='bottom-start'
                      
                      className='menu-item menu-lg-down-accordion menu-sub-lg-down-indention text-center'
                    >

                      <span className='menu-title-home-page' onClick={() => redirectContact()}>Contact Us</span>
                    </div>
                  </div>

                  <div style={{ border: "1px solid #D3D3D3 ", borderRadius: '28px' }} className='p-2 row'>
                    <div className='col-sm my-sm-auto text-center' >
                      <Link
                        to={'/auth/login'}
                        className='link-redirect'
                      >
                        Sign In
                      </Link>
                    </div>
                    <div className='col-sm my-sm-auto text-center py-1' style={{ borderRight: "1px solid #DBDBDB", borderLeft: "1px solid #DBDBDB" }}>
                      <Link
                        to={'/auth/registration'}
                        className='link-redirect'
                      >
                        Sign Up
                      </Link>
                    </div>
                    <div className='col-sm text-center '>
                      <a
                        href={'https://inalts.com/vendor/dashboard'}
                        className='link-redirect'
                        target="_blank"
                      >
                        Are You Investment Company?
                      </a>
                    </div>

                  </div>

                  {/* --------------------------- Main Screen Menus End ------------------------------*/}

                </div>
              </div>
            </div>
          </div>
          {/* Dekstop Screen Header End -------------- */}

          {/* --------------- Header End ------------ */}

          <div className='content-section'>{contentdata.data}</div>

          {/* ---------------------- Footer End --------------------- */}
          <div className='col-12'>
            <div style={{ backgroundColor: '#F3F3F3', borderRadius: '33px' }} className='px-10 py-5'>
              <div className='mx-2' style={{ zIndex: '-1' }}>
                <div className='text-center mt-7 '>

                  <div className='my-7'>
                    <img src={toAbsoluteUrl('/media/inaltsIcon/inaltsLogo.png')} className='w-150px' />
                  </div>

                  <div className='d-flex justify-content-center my-5 py-5' >
                    <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1 ' style={{ flexWrap: 'wrap' }}>
                      <li className='menu-title px-4 hovereffect footer-text-black' >
                        <Link  to={'/'} className='footer-text-black'>
                          Home
                        </Link>

                      </li>
                      <li className='menu-title px-4 hovereffect footer-text-black' onClick={scrollAboutUs}>

                        About

                      </li>

                      <li className='menu-title px-4 hovereffect footer-text-black' onClick={scrollprojectsref} >

                        Project

                      </li>

                      <li className='menu-title px-4 hovereffect footer-text-black' >
                        <Link to={"/termsAndCondition"} className='footer-text-black'>

                          Terms & Conditions
                        </Link>
                      </li>
                      <li className='menu-title px-4 hovereffect footer-text-black' >
                        <Link to={"/refund"} className='footer-text-black'>
                          Refund Policy
                        </Link>
                      </li>
                      <li className='menu-title px-4 hovereffect footer-text-black' >
                        <Link to={"/privacyPolicy"} className='footer-text-black'>
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className='card-body py-9'>
                    <a href='#' className='mx-4'>
                      {/* <img
                      src={toAbsoluteUrl('/media/homeImg/brand/facebook-4.svg')}
                      className='h-20px my-2'
                      alt=''
                    /> */}
                      <i className="fa-brands fa-facebook-f hovereffect fs-1"></i>
                    </a>

                    <a href='#' className='mx-4'>
                      {/* <img
                      src={toAbsoluteUrl('/media/homeImg/brand/instagram-2-1.svg')}
                      className='h-20px my-2'
                      alt=''
                    /> */}
                      <i className="fa-brands fa-instagram hovereffect fs-1"></i>
                    </a>

                    <a href='#' className='mx-4'>
                      {/* <img
                      src={toAbsoluteUrl('/media/homeImg/brand/twitter.svg')}
                      className='h-20px my-2'
                      alt=''
                    /> */}
                      <i className="fa-brands fa-twitter hovereffect fs-1"></i>
                    </a>
                  </div>
                </div>

                <div className='' style={{ border: '1px Solid #E1E1E1' }}></div>

                <div id='kt_app_footer' className='app-footer py-2 py-lg-4'>
                  <div className='text-dark order-2 order-md-1 text-center'>

                    <span className='text-muted fw-semibold me-1'>Copyright © <span className='text-dark'>  INALTS  </span>| Designed by</span>
                    <a
                      href='https://xpertlab.com/'
                      target='_blank'
                      className='text-gray-800 text-hover-primary text-decoration-none-underline'
                    >
                      XpertLab Technologies Private Limited
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---------------------- Footer Start -------------------------*/}


        </div>
      </div>
    </>
  )
}
