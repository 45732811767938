/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchData, toasterrormsg } from '../../../reusable';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { Card6 } from '../../../_metronic/partials/content/cards/Card6'
import { IconUserModel } from './ProfileModels'
import { useQueryResponse } from '../../modules/apps/user-management/users-list/core/QueryResponseProvider';
import { Card4 } from '../../../_metronic/partials/content/cards/Card4';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { Dropdown1 } from '../../../_metronic/partials';
import { Card4V2 } from '../../../_metronic/partials/content/cards/Card4V2';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertToRaw } from 'draft-js';
import { PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl';

export function LearnMore() {
  useEffect(() => {
    document.title = 'Investor | Learn More';
  }, []);
  var vendorId = "";
  const vendorData = sessionStorage.getItem("vendor");
  if (vendorData) {
    var vendor = JSON.parse(vendorData);
    vendorId = vendor.vendorId;

  } else {
    console.log("No vendor data found");
  }

  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  const [filter, setfilter] = useState(0)
  const handleSelectChange = (e: any) => {
    setfilter(e.target.value)
    profileDetailsApiCall(e.target.value)
  }

  const formatDate = (isoDate: any) => {
    const date = parseISO(isoDate);
    return format(date, "do MMMM yyyy");
  };

  const convertHtmlToEditorState = (html: any) => {
    const blocksFromHTML = stateFromHTML(html);

    return EditorState.createWithContent(blocksFromHTML);
  };

  const editorStateToPlainText = (editorState: any) => {
    const contentState = editorState.getCurrentContent();
    return contentState.getPlainText();
  };

  const [tab, setTab] = useState('Artical') // EditProfile, ChangePassword, KYCDocument
  const [offset, setoffset] = useState(0)
  const [limit, setlimit] = useState(9);
  const [page, setpage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [outdata, setoutdata] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [from, setfrom] = useState(1)
  const [to, setTo] = useState(1)
  const [pageNumbersList, setPageNumberList] = useState([1])

  const [projectDetails, setProjectDetails] = useState([]);
  const [projectDetailsList, setProjectDetailsList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  // page number generater
  function generatePageNumbers(currentPage: any, totalPages: any, maxPagesToShow: any) {
    const halfMaxPages = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPages);
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Adjust startPage and endPage if the total pages to show is less than maxPagesToShow
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    // Generate the list of page numbers
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }
  // on page change function
  const onpagecahngeold = (pageId: any) => {
    if (pageId != page && pageId != 0 && pageId <= totalPage) {
      // console.log(pageId);
      var tmp = (pageId - 1) * limit
      setpage(pageId)
      setoffset(tmp)
      setfrom(tmp + 1)
      // setto
      var mod = projectDetails.length % limit
      var tolimi: any = limit
      if (mod > 0) {
        if (mod <= limit) {
          tolimi = pageId * limit
          if (tolimi > projectDetails.length) {
            tolimi = projectDetails.length
          }
        }
        else {
          tolimi = pageId * limit + limit
          if (tolimi > projectDetails.length) {
            tolimi = projectDetails.length
          }
        }
      }
      else {
        tolimi = pageId * limit
      }
      setTo(tolimi)
      var tmpdata: any = projectDetails.slice(tmp, tolimi)
      setProjectDetailsList(tmpdata)
      // console.log("tmp offset",tmp);
    }

  }

  const onpagecahnge = (pageId: any, data: any = [], fromSearch: any = false, limit: any = 10) => {


    var newdata: any = []
    if (fromSearch == true) {
      newdata = [...data];
    }
    else {
      newdata = [...projectDetails]
      console.log("newdata.length", newdata.length);
    }
    console.log("data", data);

    setTotalData(newdata.length)
    if ((pageId != page && pageId != 0 && pageId <= totalPage) || data.length > 0) {
      // console.log(pageId);
      var tmp = (pageId - 1) * limit
      setpage(pageId)
      setoffset(tmp)
      setfrom(tmp + 1)
      // setto
      var mod = newdata.length % limit
      var tolimi: any = limit
      if (mod > 0) {
        if (mod <= limit) {
          tolimi = pageId * limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
        else {
          tolimi = pageId * limit + limit
          if (tolimi > newdata.length) {
            tolimi = newdata.length
          }
        }
      }
      else {
        tolimi = pageId * limit
      }
      setTo(tolimi)
      var tmpdata: any = newdata.slice(tmp, tolimi)
      setProjectDetailsList(tmpdata)
      // console.log("tmp offset",tmp);
    }
    else if (data.length == 0) {
      setTo(0)
      setfrom(0)

    }
    var totpag = (Math.ceil(newdata.length / limit))
    setTotalPage(totpag)
    // setProjectDetailsList(data)
    // onpagecahnge(1,data,true)
    setpage(pageId)
    var genpag = generatePageNumbers(1, totpag, 6)
    setPageNumberList(genpag)

  }

  const projectsearch = async (text: any) => {


    if (text == "" || text == null || text == undefined) {
      // setProjectDetailsList(projectDetails)
      console.log(projectDetails);
      var tmp: any = projectDetails.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(projectDetails.length / limit))
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = projectDetails.length % limit
        if (mod > 0) {
          setTo(mod)
        }
        else {
          setTo(tmp.length)
        }
      }
      else {
        var mod = projectDetails.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)

          }
          else {
            setTo(page * limit + limit)
          }
        }
        else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      var tmp: any = projectDetails.filter((item: any) => {
        return (item.name != null && item.name != "" && (item.name).toLowerCase().includes(text.toLowerCase())) ||
          (item.riskRating != null && item.riskRating != "" && (item.riskRating).toLowerCase().includes(text.toLowerCase()) ||
            (item.category != null && item.category != "" && (item.category.name).toLowerCase().includes(text.toLowerCase())));
      });

      // var tmp:any=projectDetails.filter((item:any)=>item.name!=null && item.name!="" && (item.name).toLowerCase() == text.toLowerCase())
      // var tmp:any=projectDetails.filter((item:any)=>item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase() == text.toLowerCase()) ||(item.fullName!=null && item.fullName!="" && (item.fullName).toLowerCase().includes(text.toLowerCase())) ||(item.email!=null && item.email!="" && (item.email).toLowerCase() == text.toLowerCase()) ||(item.email!=null && item.email!="" && (item.email).toLowerCase().includes(text.toLowerCase())) ||(item.location!=null && item.location!="" && (item.location).toLowerCase() == text.toLowerCase()) ||(item.location!=null && item.location!="" && (item.location).toLowerCase().includes(text.toLowerCase()))
      // var tmp:any=orguserList.filter((item:any)=>((item.fullName)).toLowerCase() == text.toLowerCase() 
      // || ((item.email)+' '+(item.email)).toLowerCase().includes(text.toLowerCase()))
      setProjectDetailsList(tmp)
    }
  }
  // profile Details api call
  const profileDetailsApiCall = async (apiName: any = "viewartical") => {

    var param: any = {
      // filter: filter,
    }
    var response: any = await fetchData(apiName, param)
    console.log(response)
    if (response.status == "200") {
      setProjectDetails(response.data);
      var res = response.data
      setTotalData(res.length)
      console.log(res);

      var tmp: any = res.slice(offset, offset + limit);

      setProjectDetailsList(tmp)

      var totpag = (Math.ceil(res.length / limit))
      setTotalPage(totpag)
      if (totpag == 1) {
        var mod = res.length % limit
        if (mod > 0) {
          setTo(mod)
        }
        else {
          setTo(tmp.length)
        }
      }
      else {
        var mod = res.length % limit

        if (mod > 0) {
          if (mod <= limit) {
            setTo(page * limit)

          }
          else {
            setTo(page * limit + limit)
          }
        }
        else {
          setTo(page * limit)
        }
      }

      var genpag = generatePageNumbers(page, totpag, 6)
      setPageNumberList(genpag)

    }
    else {
      toasterrormsg(response.message)
    }
  }



  useEffect(() => {
    profileDetailsApiCall("viewartical")
  }, [])

  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id :"Learn More"})}</PageTitle>
      <div className=''>

        <div className='card shadow-sm p-0 rounded'>
          <div className='card-header card-header-stretch overflow-auto mb-5'>
            <ul
              className='nav w-100 nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap justify-content-around'
              role='tablist'
            >
              <li className='nav-item'>

                <a
                  className={clsx(`nav-link cursor-pointer`, { active: tab === 'Artical' })}
                  onClick={() => {
                    setTab('Artical');
                    profileDetailsApiCall("viewartical")
                  }}
                  role='tab'
                >
                  Article
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, { active: tab === 'Resource' })}
                  onClick={() => {
                    setTab('Resource');
                    profileDetailsApiCall("viewresource")
                  }
                  }
                  role='tab'
                >
                  Resource
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, { active: tab === 'Video Tutorial' })}
                  onClick={() => { setTab('Video Tutorial'); profileDetailsApiCall("viewvideo") }}
                  role='tab'
                >
                  Video Tutorial
                </a>
              </li>
            </ul>
          </div>
          <div className='card-body'>
            <div className='tab-content pt-3'>
              {tab === 'Artical' && (
                <div className={clsx('row g-6 g-xl-9  tab-pane', { active: tab === 'Artical' })}>
                  {projectDetailsList.map((project: any, index: any) => {

                    const description = project.description;

                    let displayText;

                    // Convert the full HTML to EditorState and then to plain text
                    const editorState = convertHtmlToEditorState(description);
                    displayText = editorStateToPlainText(editorState);

                    return (

                      <div className='m-0' key={index}>

                        {/* <div>
                          <label className='fs-3'>{project.title}</label>
                        </div>
                        <div>
                          <label >{project.description}</label>
                        </div>
                        <div className='my-3 fs-5 fw-bold' >
                          <label >{project.date}</label>
                        </div>
                          */}
                        <div className="m-0">
                          <div className="d-flex align-items-center collapsible py-3 toggle mb-0" data-bs-toggle="collapse" data-bs-target={"#kt_job_9_1" + index}>

                            <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                              <i className="ki-duotone ki-minus-square toggle-on text-primary fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                              <i className="ki-duotone ki-plus-square toggle-off fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                              </i>
                            </div>
                            <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">{project.title}</h4>
                          </div>

                          <div id={"kt_job_9_1" + index} className="collapse  fs-6 ms-1">
                            <div className="mb-5 text-gray-600 fw-semibold fs-6 ps-10" style={{ textAlign: "justify" }}>{displayText}</div>
                          </div>

                          <div className="separator separator-dashed"></div>

                        </div>


                      </div>
                    )
                  })}
                </div>
              )
              }

              {tab === 'Resource' && (

                <div className={clsx('row g-6 g-xl-9  tab-pane', { active: tab === 'Resource' })}>
                  <div className='row'>
                    {projectDetailsList.map((project: any, index: any) => {

                      return (

                        <div className='col-lg-3' key={index}>

                          <Card4V2 icon='/media/svg/files/pdf.svg' title={"Document No. " + (index + 1)} links={project.file} description={formatDate(project.date)} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
              }

              {tab === 'Video Tutorial' && (
                <div className={clsx('row g-6 g-xl-9  tab-pane', { active: tab === 'Video Tutorial' })}>
                  <div className='row'>
                    {projectDetailsList.map((project: any, index: any) => {
                      const description = project.description;

                      let displayText;

                      // Convert the full HTML to EditorState and then to plain text
                      const editorState = convertHtmlToEditorState(description);
                      displayText = editorStateToPlainText(editorState);

                      if (displayText.length >= 150) {
                        // Convert the truncated HTML to EditorState and then to plain text
                        displayText = displayText.slice(0, 150) + '...';
                      }
                      return (


                        <div className={"col-lg-6"}>
                          <div className='card shadow-sm p-0 rounded my-5'>
                            {/* begin::Body */}
                            <div className='card-body pb-0'>
                              {/* begin::Header */}
                              <div className='d-flex align-items-center mb-5'>
                                {/* begin::User */}
                                <div className='d-flex align-items-center flex-grow-1'>
                                  <div className='d-flex flex-column'>
                                    <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                                      {project.title}
                                    </a>
                                    <span className='text-gray-400 fw-semibold'>{formatDate(project.date)}</span>
                                  </div>
                                  {/* end::Info */}
                                </div>

                              </div>
                              {/* end::Header */}

                              {/* begin::Post */}
                              <div className='mb-6'>
                                {/* begin::Text */}
                                <div className='text-gray-800 fs-6 fw-normal mb-5'>
                                  {displayText}
                                </div>
                                {/* end::Text */}

                                {/* begin::Video */}
                                <div className='mb-5'>
                                  <iframe
                                    title='widget11-video'
                                    className='embed-responsive-item rounded h-300px w-100'
                                    src={project.file}
                                    // src='https://www.youtube.com/embed/qIHXpnASPAA'
                                    allowFullScreen={true}
                                  />
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>

                      )
                    })}
                  </div>
                </div>
              )
              }

            </div>
          </div>
        </div>



        <div className='d-flex flex-stack flex-wrap pt-10'>
          {/* </div> */}
          <div className='row'>
            <select
              className='form-select form-select-solid fw-bolder w-50'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => {
                setlimit(parseInt(e.target.value));
                onpagecahnge(page, projectDetailsList, false, parseInt(e.target.value));
              }}

            >
              <option value='10'>10</option>
              <option value='30'>30</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
            <div className='fs-6 fw-bold text-gray-700'>
              Showing {from} to {to} of {totalData} entries
            </div>

          </div>

          <ul className='pagination'>
            <li className={`page-item previous ${page > 1 ? '' : 'disabled'}`}>
              <span
                className={`page-link ${page > 1 ? 'cursor-pointer' : 'disabled'}`}
                onClick={() => onpagecahnge(page - 1)}
              >
                <i className='previous'></i>
              </span>
            </li>
            {pageNumbersList.map((item: any) => {
              return (
                <li className={`page-item cursor-pointer ${page == item ? 'active' : ''}`}>
                  <span className='page-link' onClick={() => onpagecahnge(item)}>
                    {item}
                  </span>
                </li>
              )
            })}



            <li className={`page-item next ${page + 1 <= totalPage ? '' : 'disabled'}`}>
              <span
                className={`page-link ${page + 1 <= totalPage ? 'cursor-pointer' : 'disabled'}`}
                onClick={() => (page + 1 <= totalPage ? onpagecahnge(page + 1) : '')}
              >
                <i className='next'></i>
              </span>
            </li>

          </ul>
        </div>
      </div>
    </>
  )
}
