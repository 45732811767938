import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { ToastContainer } from 'react-toastify'
import { error } from 'console'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { fetchData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'

export function ResetPassword() {

  useEffect(() => {
    document.title = 'Investor | Reset Password';
  }, []);
  const navigate=useNavigate()
  const [loading, setLoading] = useState(false)
  const [indicatorStatus,setIndicatorStatus]=useState("0")
  const [password,setPassword]=useState("")
  const [confirmPassword,setConfirmPassword]=useState("")
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage,setErrorMessage]=useState({"otp":"","password":"","confirmPassword":""})
  const [submited,setSubmited]=useState(false)
  const [passwordDisplay,setPasswordDisplay]=useState(false)
  const [confirmPasswordDisplay,setconfirmPasswordDisplay]=useState(false)

  const checkindicator=(value:any)=>{
    var num=0;

    if(value.length>=6)
    {
      num+=1
    }

    if((/[a-z]/.test(value)))
    {
      num+=1
    }

    if((/[A-Z]/.test(value)))
    {
      num+=1
    }

    if((/[0-9]/.test(value)))
    {
      num+=1
    }

    if((/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)))
    {
      num+=1
    }

    setIndicatorStatus(String(num))
  }

  const validator=(pass:any,confirmPass:any,submi:any,finalSubmit:any=false)=>{
    var tmpvali:any=errorMessage
    if(submi==true)
    {
      if(pass==="" || pass===null || pass===undefined)
      {
        tmpvali['password']="Password Required"
      }
      else if(pass.length<6)
      {
        tmpvali['password']="Enter At Least 6 Digit Password"
      }
      else
      {
        tmpvali['password']=""
      }
      if(confirmPass==="" || confirmPass===null || confirmPass===undefined)
      {
        tmpvali['confirmPassword']="Confirm Password Required"
      }
      else if(pass!=confirmPass)
      {
        tmpvali['confirmPassword']="Password And Confirm Password Are Not Match"
      }
      else
      {
        tmpvali['confirmPassword']=""
      }

      if( tmpvali['otp']=="" &&  tmpvali['password']=="" &&  tmpvali['confirmPassword']=="" && finalSubmit==true)
      {
        resetpasswordapicall()
      }
    }

  }

  const resetpasswordapicall=async()=>{
    var vendorId = localStorage.getItem("vendorId");
    if(vendorId!=="" || vendorId!==null || vendorId!=undefined)
    {
      var param:any={
        id:vendorId,
        password:password,
        confirmPassword:confirmPassword
      }
      var response:any=await fetchData("changePassword",param)
      
      if(response.status=="200")
      {
        localStorage.removeItem("resetEmail")
        toastsuccessmsg("Password updated Successfully")

        setTimeout(() => {
          navigate("../");
        }, 1500);
        
      }
      else
      {
        // setSubmitting(false)
        toasterrormsg(response.message)
        setLoading(false)
      }
    }
  }

  return (
    <>
    
    <ToastContainer />

      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        // noValidate
        id='kt_login_password_reset_form'
        // onSubmit={formik.handleSubmit}
      >
        <div className='text-start my-13'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
          Have you already reset the password ? <Link to="../">Sign in</Link>
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
          <div style={{position:"relative"}}>

          <input
            type={passwordDisplay==true?"text":"password"}
            placeholder='Password'
            autoComplete='off'
            className={'form-control bg-transparent'}
            value={password}
            onChange={(e:any)=>{setPassword(e.target.value);checkindicator(e.target.value);validator(e.target.value,confirmPassword,submited)}}
          />
          <span className="cursor-pointer" style={{position:"absolute",top:"25%",right:"20px"}} title={passwordDisplay==true?"Hide":"Show"} onClick={()=>setPasswordDisplay(!passwordDisplay)}><FontAwesomeIcon icon={passwordDisplay==true?faEyeSlash:faEye}/></span>
                                                <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
        {
          errorMessage['password']!=="" && <span className='text-danger'>{errorMessage['password']}</span>
        }
        </div>
        {/* end::Form group */}
        {/* <!--begin::Meter--> */}
        <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
          <div className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${indicatorStatus>"1"?"active":""}`}></div>
          <div className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${indicatorStatus>"2"?"active":""}`}></div>
          <div className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${indicatorStatus>"3"?"active":""}`}></div>
          <div className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px ${indicatorStatus>"4"?"active":""}`}></div>
        </div>
        {/* <!--end::Meter--> */}

        {/* begin::Form group */}
        <div className='fv-row mb-15'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <div style={{position:"relative"}}>
            <input
              type={confirmPasswordDisplay==true?"text":"password"}
              placeholder='Confirm Password'
              autoComplete='off'
              className={'form-control bg-transparent'}
              value={confirmPassword}
              onChange={(e:any)=>{setConfirmPassword(e.target.value);validator(password,e.target.value,submited)}}
            />
            <span className="cursor-pointer" style={{position:"absolute",top:"25%",right:"20px"}} title={confirmPasswordDisplay==true?"Hide":"Show"} onClick={()=>setconfirmPasswordDisplay(!confirmPasswordDisplay)}><FontAwesomeIcon icon={confirmPasswordDisplay==true?faEyeSlash:faEye}/></span>
                                          <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
        {
          errorMessage['confirmPassword']!=="" && <span className='text-danger mt-1 mb-3'>{errorMessage['confirmPassword']}</span>
        }
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='button' id='kt_password_reset_submit' className='btn btn-primary me-4' onClick={()=>{setSubmited(true);validator(password,confirmPassword,true,true)}}>
            <span className='indicator-label'>Reset Password</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {' '}
        </div>
        {/* end::Form group */}
      </div>

    </>
  )
}
