/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Card7 } from '../../../../_metronic/partials/content/cards/Card7'
import { FeedsWidget7 } from '../../../../_metronic/partials/widgets'
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Select from 'react-select';
import { fetchData, fetchFormData, toasterrormsg, toastsuccessmsg } from '../../../../reusable'
import { ToastContainer, toast } from 'react-toastify'
import { PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { stateFromHTML } from 'draft-js-import-html'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function AddHelpAndSupport() {

  interface LocationState {
    data: string; // Adjust the type according to your data type
  }
  const location = useLocation();
  const admin: any = location.state ? (location.state as LocationState).data : "";


  useEffect(() => {
    document.title = 'Investor | Help And Support '
  }, []);
  // state start
  var vendorId = "";
  const vendorData = sessionStorage.getItem("vendor");
  if (vendorData) {
    var vendor = JSON.parse(vendorData);
    vendorId = vendor._id;
  } else {
    console.log("No vendor data found");
  }

  const navigate = useNavigate()

  const [financialName, setfinancialName] = useState("")
  const [productDescription, setProductDescription] = useState(() => EditorState.createEmpty());

  const [editId, seteditId] = useState(admin ? admin._id : '')


  const convertHtmlToEditorState = (html: any) => {
    const blocksFromHTML = stateFromHTML(html);

    return EditorState.createWithContent(blocksFromHTML);
  };

  const getContentAsHtml = () => {
    const contentState = productDescription.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    const contentText = contentRaw.blocks.map(block => block.text).join('').trim(); // Get the text content and trim any whitespace
    if (contentText) { // Check if content is not empty
      return draftToHtml(contentRaw); // Convert EditorState to HTML
    }
    return null; // Return null if content is empty
  };
  var projectDescription = getContentAsHtml() || "";

  const [error, setError] = useState([
    {

      financialName: '',
      productDescription: '',

    },
  ])

  const addvalidate = () => {
    var isValid = true
    var errorTmp = [...error]

    if (financialName == '' || financialName == null || financialName == undefined) {
      errorTmp[0].financialName = 'Subject is required'
      isValid = false
    }

    if (projectDescription == "" || projectDescription == null || projectDescription == undefined) {
      errorTmp[0].productDescription = 'Description is required';
      isValid = false;
    }

    setError(errorTmp)
    return isValid
  }


  const removeError = (err: any) => {
    var tmp = [...error] as any
    tmp[0][err] = ''
    setError(tmp)
  }

  const addCompanyAPI = async (id: any = '') => {

    if (addvalidate()) {

      let param = new FormData()
      param.append('investorId', vendorId)
      param.append('subject', financialName)
      param.append('description', projectDescription)

      // if (profilePicture) {
      //   param.append('profilePicture', profilePicture)
      // }

      var res = await fetchFormData("helpAndSupport",param );
      // console.log(res);

      if (res.status == '200') {
        toastsuccessmsg('Support Add Successfully!')
        setTimeout(() => {
          navigate('../support/')
        }, 1500)
      } else {
        toasterrormsg(res.message)
        console.log(res)
      }
    }

  }

  const intl = useIntl()

  return (
    <>
      <ToastContainer />
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: editId == '' ? 'Add Support' : "Edit Support" })}</PageTitle>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          {/* <KTIcon iconName='category' className='fs-2 text-primary' />
          &nbsp;&nbsp;{editId ? 'Edit' : "Add"} Role */}
        </h3>
      </div>
      <div className='mx-2'>
        <div className='row mb-6'>
          <label className='col-lg-12 col-form-label required fw-bold fs-5'>Subject</label>
          <div className='col-lg-12 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg border border-2 border-gray-300 form-control-solid mb-3 mb-lg-0'
              placeholder='Name'
              name='financialName'
              value={financialName}
              onChange={(e: any) => {
                setfinancialName(e.target.value)
                removeError('financialName')
              }}
            />
            <div className='text-danger fw-bold'>{error[0].financialName}</div>
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-form-label required fw-bold fs-5">Description</label>
          <Editor
            editorState={productDescription}
            toolbarClassName="form-control form-control-lg bg-light"
            wrapperClassName="wrapperClassName"
            editorClassName="form-control form-control-lg bg-light h-300px"
            onEditorStateChange={(e: any) => { setProductDescription(e); removeError("productDescription"); }}
          />
          {/* <button onClick={() => console.log(getContentAsText())}>Get Content</button> */}
          <div className='text-danger fw-bold'>{error[0].productDescription}</div>
        </div>

        <div className='col'>
          <button
            type='button'
            className='btn btn-primary mx-5'
            // data-dismiss="modal"
            onClick={() => {
              editId == '' ? addCompanyAPI() : addCompanyAPI(editId)
            }}
          >
            {editId == '' ? 'Add ' : 'Edit '}Support
          </button>
          <Link
            to={'/support'}
            type='button'
            className='btn btn-danger'

          >
            Cancel
          </Link>
        </div>
      </div>
    </>
  )
}
